import { CanvasTexture, ClampToEdgeWrapping, LinearFilter, Sprite, SpriteMaterial, } from "three";
export class Nametag {
    constructor(_scene) {
        this._scene = _scene;
        this.nametag = "";
    }
    createNametag(size = 8, baseWidth = 100, name = "John Doe") {
        const borderSize = 2;
        const fontSize = 12;
        const ctx = document.createElement("canvas").getContext("2d");
        if (!ctx)
            return;
        const font = `100 ${size}px Arial`;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;
        const doubleBorderSize = borderSize * 2;
        const width = baseWidth + doubleBorderSize;
        const height = size + doubleBorderSize;
        ctx.canvas.width = width;
        ctx.canvas.height = height;
        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
        ctx.fillRect(0, 0, width, height);
        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor, 1);
        ctx.fillStyle = "white";
        ctx.fillText(name, 0, 0);
        const canvasTexture = new CanvasTexture(ctx.canvas);
        canvasTexture.minFilter = LinearFilter;
        canvasTexture.wrapS = ClampToEdgeWrapping;
        canvasTexture.wrapT = ClampToEdgeWrapping;
        const nameMaterial = new SpriteMaterial({
            map: canvasTexture,
            transparent: true,
        });
        const labelBaseScale = 0.01;
        const label = new Sprite(nameMaterial);
        label.scale.x = ctx.canvas.width * labelBaseScale;
        label.scale.y = ctx.canvas.height * labelBaseScale;
        return label;
    }
}
