import { useState } from "react";
import { StaticValues } from 'utils/const/StaticValues';
export const useUser = () => {
    // get user token from localstorage
    const [userToken, setUserToken] = useState(localStorage.getItem(StaticValues.ConnectionTokenName));
    //if (userToken === undefined || userToken === '') return window.location.href = '/authentication/sign-in';
    const updateUserToken = (token) => {
        localStorage.setItem(StaticValues.ConnectionTokenName, token.AuthToken);
        setUserToken(token.AuthToken);
        //return window.history.back();
    };
    return { userToken, updateUserToken };
};
