import { jsx as _jsx } from "react/jsx-runtime";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
function DataTableBodyCell({ noBorder = false, align = "left", children }) {
    return (_jsx(MDBox, Object.assign({ component: "td", textAlign: align, py: 1.5, px: 3, sx: ({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
            fontSize: size.sm,
            borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        }) }, { children: _jsx(MDBox, Object.assign({ display: "inline-block", width: "max-content", color: "text", sx: { verticalAlign: "middle" } }, { children: children })) })));
}
export default DataTableBodyCell;
