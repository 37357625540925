import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFormPost } from "hooks/useFormPost";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { setTranslation } from "redux-store/TranslationSlice";
export const debounce = (func, waitFor) => {
    let timeout;
    return (...args) => new Promise((resolve) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};
let isCalled = false;
export default function Token({ children }) {
    // check for the existing value in translation list
    const lang = useSelector((state) => state.translation.selectedLang);
    const translations = useSelector((state) => state.translation.translations);
    const { result, execute, loading: getAllResultLoading, } = useFormGet(url("/site/translation/all"));
    const dispatch = useDispatch();
    const [translatedWord, setTranslatedWord] = useState(children);
    const data = [
        {
            lang: lang,
            translations: {
                [children]: children,
            },
        },
    ];
    const { execute: createTokenExecute, result: createdTokenResult } = useFormPost(url("/site/translation/add"));
    const getLangDatas = () => {
        setTranslatedWord(children);
        let founded = false;
        translations.forEach((item) => {
            if (item.lang === lang) {
                if (item.translations[children]) {
                    setTranslatedWord(item.translations[children]);
                    founded = true;
                    return;
                }
            }
        });
        if (!founded) {
            createTokenExecute(JSON.stringify(data));
        }
    };
    useEffect(() => {
        if (translations.length < 1 && !isCalled) {
            execute();
            isCalled = true;
        }
        else {
            let callingMethoad;
            callingMethoad = setTimeout(() => {
                console.log(translations.length);
                if (isCalled && translations.length > 0) {
                    getLangDatas();
                    clearTimeout(callingMethoad);
                }
            }, 300);
        }
    }, [translations]);
    useEffect(() => {
        if (result) {
            dispatch(setTranslation(result));
        }
    }, [result]);
    useEffect(() => {
        let callingMethoad;
        callingMethoad = setTimeout(() => {
            if (translations.length > 0 && isCalled) {
                getLangDatas();
                clearTimeout(callingMethoad);
            }
        }, 300);
    }, [lang]);
    useEffect(() => {
        if (createdTokenResult) {
            dispatch(setTranslation(createdTokenResult));
        }
    }, [createdTokenResult]);
    return _jsx(_Fragment, { children: translatedWord });
}
