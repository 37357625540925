import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
// NewProduct page components
import FormField from "page/ecommerce/products/new-product/components/FormField";
function Pricing() {
    return (_jsxs(MDBox, { children: [_jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Pricing" })), _jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(FormField, { type: "text", label: "Price", placeholder: "99.00" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 4, sx: { mt: 2 } }, { children: _jsx(Autocomplete, { defaultValue: "USD", options: ["BTC", "CNY", "EUR", "GBP", "INR", "USD"], renderInput: (params) => _jsx(MDInput, Object.assign({}, params, { variant: "standard" })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 5 }, { children: _jsx(FormField, { type: "text", label: "SKU", placeholder: "71283476591" }) }))] })) })), _jsx(MDBox, Object.assign({ mt: 1 }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3 }, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(MDBox, Object.assign({ my: 2, display: "inline-block" }, { children: _jsx(MDTypography, Object.assign({ component: "label", variant: "button", fontWeight: "regular", color: "text" }, { children: "Tags" })) })), _jsx(Autocomplete, { multiple: true, defaultValue: ["In Stock", "Out of Stock"], options: ["Black Friday", "Expired", "Out of Stock", "In Stock", "Sale"], renderInput: (params) => _jsx(MDInput, Object.assign({}, params, { variant: "standard" })) })] })) })) }))] }));
}
export default Pricing;
