import { jsx as _jsx } from "react/jsx-runtime";
import FormInputColor from "components/generic/form/FormInputColor";
import { useState } from "react";
export default function Color({ item, formik, }) {
    const [element, setElement] = useState(item.meshToData());
    const prevHander = formik.handleChange;
    formik.handleChange = (e) => {
        element.otherDatas.circleColor = e.target.value;
        item.dataToMesh(element);
        prevHander(e);
        console.log(e);
    };
    return _jsx(FormInputColor, { label: "Circle Color", name: "color", formik: formik });
}
