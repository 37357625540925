import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useFormPost } from 'hooks/useFormPost';
import { url } from "services/ApiEndpoint";
import FormInputText from 'components/generic/form/FormInputText';
import FormSubmitButton from 'components/generic/form/FormSubmitButton';
export default function AddNewUserForm(props) {
    const { execute, result, globalErrorMessage } = useFormPost(url('/user/create-participent-user'));
    const formData = useFormik({
        initialValues: {
            email: props.email,
            firstname: 'test-firstname',
            lastname: 'test-lastname',
            phone: '0123456789',
            adresse: {
                city: 'paris',
                postalcode: '75001',
                adresse: 'Test adresse',
                country: 'france',
                digicode: '123'
            }
        },
        onSubmit: async (submitedValue) => {
            await execute(JSON.stringify(submitedValue));
        }
    });
    useEffect(() => {
        if (result) {
            console.log(result);
            props.updateParticipent({
                _id: result._id,
                email: result.email
            });
            props.setModelOpen(false);
        }
    }, [result]);
    return (_jsx("div", { children: _jsxs(Container, Object.assign({ maxWidth: "xs" }, { children: [globalErrorMessage, _jsxs("form", Object.assign({ onSubmit: formData.handleSubmit, noValidate: true }, { children: [_jsx(FormInputText, { formik: formData, name: 'email', label: 'E-mail', required: true }), _jsx(FormInputText, { formik: formData, name: 'firstname', label: 'Firstname', required: true }), _jsx(FormInputText, { formik: formData, name: 'lastname', label: 'Lastname', required: true }), _jsx(FormInputText, { formik: formData, name: 'phone', label: 'Phone', required: true }), _jsx(FormInputText, { formik: formData, name: 'adresse.city', label: 'City', required: true }), _jsx(FormInputText, { formik: formData, name: 'adresse.postalcode', label: 'Postal Code', required: true }), _jsx(FormInputText, { formik: formData, name: 'adresse.adresse', label: 'Adresse', required: true }), _jsx(FormInputText, { formik: formData, name: 'adresse.country', label: 'Country', required: true }), _jsx(FormInputText, { formik: formData, name: 'adresse.digicode', label: 'Digicode' }), _jsx("div", Object.assign({ style: { display: 'flex', justifyContent: 'center' } }, { children: _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: 'Submit' }) }))] }))] })) }));
}
