import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentSidenav, setWhiteSidenav, setMiniSidenav, setFixedNavbar, setSidenavColor, setDarkMode, } from "contexts";
function Configurator() {
    const [controller, dispatch] = useMaterialUIController();
    const { openConfigurator, miniSidenav, fixedNavbar, sidenavColor, transparentSidenav, whiteSidenav, darkMode, } = controller;
    const [disabled, setDisabled] = useState(false);
    const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
    // Use the useEffect hook to change the button state for the sidenav type based on window size.
    useEffect(() => {
        // A function that sets the disabled state of the buttons for the sidenav type.
        function handleDisabled() {
            return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
        }
        // The event listener that's calling the handleDisabled function when resizing the window.
        window.addEventListener("resize", handleDisabled);
        // Call the handleDisabled function to set the state with the initial value.
        handleDisabled();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleDisabled);
    }, []);
    const handleTransparentSidenav = () => {
        setTransparentSidenav(dispatch, true);
        setWhiteSidenav(dispatch, false);
    };
    const handleWhiteSidenav = () => {
        setWhiteSidenav(dispatch, true);
        setTransparentSidenav(dispatch, false);
    };
    const handleDarkSidenav = () => {
        setWhiteSidenav(dispatch, false);
        setTransparentSidenav(dispatch, false);
    };
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
    const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
    // sidenav type buttons styles
    const sidenavTypeButtonsStyles = ({ functions: { pxToRem }, palette: { white, dark, background }, borders: { borderWidth }, }) => ({
        height: pxToRem(39),
        background: darkMode ? background.sidenav : white.main,
        color: darkMode ? white.main : dark.main,
        border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
        "&:hover, &:focus, &:focus:not(:hover)": {
            background: darkMode ? background.sidenav : white.main,
            color: darkMode ? white.main : dark.main,
            border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
        },
    });
    // sidenav type active button styles
    const sidenavTypeActiveButtonStyles = ({ functions: { pxToRem, linearGradient }, palette: { white, gradients, background }, }) => ({
        height: pxToRem(39),
        background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
        color: darkMode ? background.sidenav : white.main,
        "&:hover, &:focus, &:focus:not(:hover)": {
            background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
            color: darkMode ? background.sidenav : white.main,
        },
    });
    return (
    // <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
    _jsxs(Card, Object.assign({ sx: { height: "100%" } }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "baseline", pt: 4, pb: 0.5, px: 3 }, { children: _jsxs(MDBox, { children: [_jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Material UI Configurator" })), _jsx(MDTypography, Object.assign({ variant: "body2", color: "text" }, { children: "See our dashboard options." }))] }) })), _jsx(Divider, {}), _jsxs(MDBox, Object.assign({ pt: 0.5, pb: 3, px: 3 }, { children: [_jsxs(MDBox, { children: [_jsx(MDTypography, Object.assign({ variant: "h6" }, { children: "Sidenav Colors" })), _jsx(MDBox, Object.assign({ mb: 0.5 }, { children: sidenavColors.map((color) => (_jsx(IconButton, { sx: ({ borders: { borderWidth }, palette: { white, dark, background }, transitions, }) => ({
                                        width: "24px",
                                        height: "24px",
                                        padding: 0,
                                        border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                                        borderColor: () => {
                                            let borderColorValue = sidenavColor === color && dark.main;
                                            if (darkMode && sidenavColor === color) {
                                                borderColorValue = white.main;
                                            }
                                            return borderColorValue;
                                        },
                                        transition: transitions.create("border-color", {
                                            easing: transitions.easing.sharp,
                                            duration: transitions.duration.shorter,
                                        }),
                                        backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) => linearGradient(gradients[color].main, gradients[color].state),
                                        "&:not(:last-child)": {
                                            mr: 1,
                                        },
                                        "&:hover, &:focus, &:active": {
                                            borderColor: darkMode ? white.main : dark.main,
                                        },
                                    }), onClick: () => setSidenavColor(dispatch, color) }, color))) }))] }), _jsxs(MDBox, Object.assign({ mt: 3, lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6" }, { children: "Sidenav Type" })), _jsx(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: "Choose between different sidenav types." })), _jsxs(MDBox, Object.assign({ sx: {
                                    display: "flex",
                                    mt: 2,
                                    mr: 1,
                                } }, { children: [_jsx(MDButton, Object.assign({ color: "dark", variant: "gradient", onClick: handleDarkSidenav, disabled: disabled, fullWidth: true, sx: !transparentSidenav && !whiteSidenav
                                            ? sidenavTypeActiveButtonStyles
                                            : sidenavTypeButtonsStyles }, { children: "Dark" })), _jsx(MDBox, Object.assign({ sx: { mx: 1, width: "8rem", minWidth: "8rem" } }, { children: _jsx(MDButton, Object.assign({ color: "dark", variant: "gradient", onClick: handleTransparentSidenav, disabled: disabled, fullWidth: true, sx: transparentSidenav && !whiteSidenav
                                                ? sidenavTypeActiveButtonStyles
                                                : sidenavTypeButtonsStyles }, { children: "Transparent" })) })), _jsx(MDButton, Object.assign({ color: "dark", variant: "gradient", onClick: handleWhiteSidenav, disabled: disabled, fullWidth: true, sx: whiteSidenav && !transparentSidenav
                                            ? sidenavTypeActiveButtonStyles
                                            : sidenavTypeButtonsStyles }, { children: "White" }))] }))] })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3, lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6" }, { children: "Navbar Fixed" })), _jsx(Switch, { checked: fixedNavbar, onChange: handleFixedNavbar })] })), _jsx(Divider, {}), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6" }, { children: "Sidenav Mini" })), _jsx(Switch, { checked: miniSidenav, onChange: handleMiniSidenav })] })), _jsx(Divider, {}), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6" }, { children: "Light / Dark" })), _jsx(Switch, { checked: darkMode, onChange: handleDarkMode })] }))] }))] }))
    // </ConfiguratorRoot>
    );
}
export default Configurator;
