import { SCENE_LOADED } from "../state/const";
import { EditInteractionService } from "./EditMode/EditInteractionService";
import { EditModel } from "./EditMode/EditModel";
import { SceneModelAction } from "./EditMode/SceneModelAction";
import { AvatarPlayer } from "./PresentationMode/AvatarPlayer";
import { PresentationInteractionService } from "./PresentationMode/PresentationInteractionService";
import { SocketService } from "./PresentationMode/SocketService";
import { url } from "services/ApiEndpoint";
import { MediaHandler } from "./SceneManagement/MediaHandler";
import { KankanAvatar } from "./PresentationMode/KankanAvatar";
import { KankanSocketService } from "./PresentationMode/KankanSocketService";
export class SceneManager {
    constructor(scene, mode, user, _roomRef, _eventId) {
        this.scene = scene;
        this.mode = mode;
        this.user = user;
        this._roomRef = _roomRef;
        this._eventId = _eventId;
    }
    run() {
        return new Promise((resolve) => {
            this.scene.getScene().then(() => {
                const modelPath = url(`/admin/avatar/model/${this.user.avatarModelUrl}`).value;
                if (this.mode === "Template" && this.scene.sceneType === "Native") {
                    new AvatarPlayer(this.scene, modelPath);
                }
                if (this.mode === "Edit") {
                    if (this.scene.sceneType === "Native") {
                        new AvatarPlayer(this.scene, modelPath);
                    }
                    new EditInteractionService(this.scene);
                    const sceneAction = new SceneModelAction(this.scene);
                    new EditModel(this.scene);
                    if (this._roomRef)
                        new MediaHandler(sceneAction.itemGroup, this._roomRef, this.scene);
                }
                if (this.mode === "Presentation") {
                    new PresentationInteractionService(this.scene);
                    if (this._roomRef)
                        new MediaHandler(this.scene.scene, this._roomRef, this.scene);
                    if (this.scene.sceneType === "Native") {
                        const player = new AvatarPlayer(this.scene, modelPath, this.user.firstname);
                        if (this._eventId)
                            new SocketService(this.scene, player, this._eventId);
                    }
                    else if (this.scene.sceneType === "Kankan") {
                        const player = new KankanAvatar(this.scene, this.user.firstname, false);
                        if (this._eventId)
                            new KankanSocketService(this.scene, player, this._eventId);
                    }
                }
                const customEvent = new CustomEvent(SCENE_LOADED, {
                    detail: {
                        domElement: this.scene.renderer.domElement,
                        transformControl: this.scene.transformControl,
                        scene: this.scene,
                    },
                });
                window.dispatchEvent(customEvent);
                resolve(true);
            });
        });
    }
    destroy() {
        console.log("destroy methoad called");
        this.scene.destroy();
        delete this.scene;
        delete this.mode;
        delete this.user;
        delete this._roomRef;
        delete this._eventId;
    }
}
