import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { useShowConfirmation } from "hooks/useShowConfirmation";
import { useEffect } from "react";
import { useSigneOutUser } from "hooks/useSigneOutUser";
function DeleteAccount() {
    const { execute, result, errors, globalErrorMessage, status } = useFormPost(url("/user/delete"));
    const { Message, setShow, setDescription, setTitle, setColor } = useShowConfirmation();
    const { signOut } = useSigneOutUser();
    useEffect(() => {
        if (!status)
            return;
        if (status === 200) {
            setTitle("Account Deleted Successfully");
            setDescription("Votre compte est supprimer avec succeés");
            setShow(true);
            setColor("success");
            setTimeout(() => {
                signOut();
            }, 1000);
        }
        else {
            setTitle("Account Deleted Error");
            setDescription("Il y a une error pour suppression votre compte");
            setShow(true);
            setColor("error");
        }
    }, [status]);
    return (_jsxs(Card, Object.assign({ id: "delete-account" }, { children: [_jsxs(MDBox, Object.assign({ pr: 3, display: "flex", justifyContent: "space-between", alignItems: { xs: "flex-start", sm: "center" }, flexDirection: { xs: "column", sm: "row" } }, { children: [_jsxs(MDBox, Object.assign({ p: 3, lineHeight: 1 }, { children: [_jsx(MDBox, Object.assign({ mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Delete Account" })) })), _jsx(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: "Once you delete your account, there is no going back. Please be certain." }))] })), _jsxs(MDBox, Object.assign({ display: "flex", flexDirection: { xs: "column", sm: "row" } }, { children: [_jsx(MDButton, Object.assign({ variant: "outlined", color: "secondary" }, { children: "deactivate" })), _jsx(MDBox, Object.assign({ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }, { children: _jsx(MDButton, Object.assign({ variant: "gradient", color: "error", sx: { height: "100%" }, onClick: execute }, { children: "delete account" })) }))] }))] })), _jsx(Message, {})] })));
}
export default DeleteAccount;
