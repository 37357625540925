import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { EventRoomItem } from "components/features/event/field/EventRoomItem";
import { url } from "services/ApiEndpoint";
export default function EventRoom(props) {
    const [description, setDescription] = useState("");
    const [thumbnail, setThumbnail] = useState("https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg");
    const { Component, result, previewThumbnail } = EventRoomItem();
    useEffect(() => {
        if (result) {
            result.map((room) => {
                if (room._id === props.formik.values[props.name]) {
                    setDescription(room.description);
                    const imgSrc = room.thumbnail
                        ? url(`/room/${room.thumbnail}`).value
                        : "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg";
                    setThumbnail(imgSrc);
                }
            });
        }
    }, [result]);
    useEffect(() => {
        if (previewThumbnail) {
            setThumbnail(url(`/room/${previewThumbnail}`).value);
        }
    }, [previewThumbnail]);
    return (_jsx(FormControl, Object.assign({ fullWidth: true, style: {
            marginBottom: 15,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ item: true, md: 6, xs: 12 }, { children: [_jsx(Component, Object.assign({}, props)), _jsx(Typography, Object.assign({ variant: "body1", gutterBottom: true }, { children: description }))] })), _jsx(Grid, Object.assign({ item: true, md: 6, xs: 12, maxHeight: 300 }, { children: _jsx("img", { src: thumbnail, style: {
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                        }, title: "", alt: "", crossOrigin: "anonymous" }) }))] })) })));
}
