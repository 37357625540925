import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Input } from "@mui/material";
import { useEffect, useState } from "react";
export default function RoomThumbnail(props) {
    const [uploadedImage, setUploadedImage] = useState(null);
    const changeImage = (event) => {
        const files = event.target.files;
        if (!files)
            return;
        const file = URL.createObjectURL(files[0]);
        setUploadedImage(file);
        props.formik.setFieldValue(props.name, files[0]);
    };
    useEffect(() => {
        if (typeof props.formik.values[props.name] === "string") {
            setUploadedImage(props.formik.values[props.name]);
        }
    }, [props.formik.values[props.name]]);
    return (_jsxs(Grid, Object.assign({ container: true }, { children: [uploadedImage ? (_jsx(Grid, Object.assign({ item: true, height: 150, marginBottom: 2 }, { children: _jsx("img", { src: uploadedImage, style: {
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                    }, alt: "", title: "", crossOrigin: "anonymous" }) }))) : null, _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Input, { type: "file", onChange: changeImage }) }))] })));
}
