/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import borders from "assets/theme/base/borders";
const { borderRadius } = borders;
const avatar = {
    styleOverrides: {
        root: {
            transition: "all 200ms ease-in-out",
        },
        rounded: {
            borderRadius: borderRadius.lg,
        },
        img: {
            height: "auto",
        },
    },
};
export default avatar;
