import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { ETemplateOrigin } from "components/features/template/type/TTemplate";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import MDBox from "components/generic/MDBox";
import TemplateCard from "components/features/template/component/card";
import MDButton from "components/generic/MDButton";
import { Link } from "react-router-dom";
const DeleteTemplate = ({ templateId }) => {
    const { execute: deleteQuery, status } = useFormGet(url("/scene-template/delete/" + templateId));
    useEffect(() => {
        if (status === 200) {
            window.location.reload();
        }
    }, [status]);
    return (_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: _jsx(MDButton, Object.assign({ component: Link, variant: "outlined", size: "small", color: "dark", onClick: deleteQuery }, { children: "Delete" })) })));
};
export default function ListTemplate() {
    const { execute, result } = useFormGet(url("/scene-template/all"));
    useEffect(() => {
        execute();
    }, []);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ mt: 2 }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 6 }, { children: result === null || result === void 0 ? void 0 : result.map((template, index) => {
                        const actions = [
                            {
                                type: "internal",
                                route: `/template/edit/${template._id}`,
                                color: "secondary",
                                label: "Open",
                            },
                        ];
                        if (template.origin === ETemplateOrigin.custom) {
                            actions.push({
                                Component: _jsx(DeleteTemplate, { templateId: template._id }, `delete_${index}`),
                                // cb: () => DeleteTemplate(template._id),
                            });
                        }
                        return (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(TemplateCard, { template: template, actions: actions }) }), index));
                    }) })) }))] }));
}
