import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
export const EventRoomItem = () => {
    const { execute, result } = useFormGet(url("/room/all"));
    const [previewThumbnail, setPreviewThumbnail] = useState(null);
    useEffect(() => {
        execute();
    }, []);
    const Component = (props) => {
        var _a, _b;
        useEffect(() => {
            setPreviewThumbnail(props.formik.values[props.name].thumbnail);
        }, []);
        return (_jsxs(FormControl, Object.assign({ fullWidth: true, style: {
                marginBottom: 15,
                width: "100%",
            } }, { children: [_jsx(InputLabel, Object.assign({ id: props.name, required: props.required }, { children: (_a = props.label) !== null && _a !== void 0 ? _a : props.name })), result ? (_jsx(Select, Object.assign({ labelId: props.name, id: props.name, value: props.formik.values[props.name]._id, label: (_b = props.label) !== null && _b !== void 0 ? _b : props.name, name: props.name, onChange: (e) => {
                        result.map((obj) => {
                            if (obj._id == e.target.value) {
                                setPreviewThumbnail(obj.thumbnail);
                            }
                        });
                        props.formik.handleChange(e);
                    }, defaultValue: props.formik.values[props.name] }, { children: result.map((type, key) => {
                        return (_jsx(MenuItem, Object.assign({ value: type._id }, { children: type.title }), key));
                    }) }))) : null] })));
    };
    return { Component, result, previewThumbnail };
};
