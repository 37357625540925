import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NestedArrayResolveStrategy } from "utils/NestedArrayResolveStrategy";
import MDInput from "components/generic/MDInput";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
export default function FormPassword(props) {
    const value = NestedArrayResolveStrategy(props.formik.values, props.name);
    const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    return (_jsxs(MDBox, Object.assign({ style: {
            marginBottom: 15,
        } }, { children: [_jsx(MDInput, Object.assign({ variant: "outlined", type: "password", autoComplete: "", fullWidth: true, value: value, onChange: props.formik.handleChange, error: error ? true : false }, props)), error ? (_jsx(MDBox, Object.assign({ style: {
                    marginTop: 15,
                } }, { children: _jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "error", fontWeight: "regular" }, { children: error.toString() })) }))) : null] })));
}
