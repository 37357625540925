import { createSlice } from "@reduxjs/toolkit";
const themeInitialState = {
    mode: 'light',
    backgound: '#F7F7F7',
    primary: '#6392EB',
    secondary: '#6392EB'
};
export const themeSlice = createSlice({
    name: 'theme',
    initialState: themeInitialState,
    reducers: {
        setDark: (state) => {
            state = Object.assign(Object.assign({}, state), { mode: 'dark' });
        },
        setLight: (state) => {
            state = Object.assign(Object.assign({}, state), { mode: 'light' });
        }
    }
});
export const { setLight, setDark } = themeSlice.actions;
export default themeSlice.reducer;
