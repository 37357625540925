import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Icon } from "@mui/material";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import { useFormGet } from "hooks/useFormGet";
import CustomerCell from "page/ecommerce/orders/order-list/components/CustomerCell";
import DefaultCell from "page/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "page/ecommerce/orders/order-list/components/StatusCell";
//import dataTableData from "page/ecommerce/orders/order-list/data/dataTableData";
import { useEffect, useState } from "react";
import { url } from "services/ApiEndpoint";
import DataTable from "./Datatable";
export default function User() {
    const count = 3;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const { execute, result, status } = useFormGet(url(`/admin/user/all?offset=${offset}&count=${count}`));
    const [dataTableData, setDataTableData] = useState(null);
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        execute();
    }, [offset]);
    useEffect(() => {
        if (result) {
            const prevData = {
                columns: [
                    // { Header: "id", accessor: "id", Cell: ({ value }: any) => <IdCell id={value} /> },
                    {
                        Header: "Firstname",
                        accessor: "firstname",
                        Cell: ({ value }) => _jsx(DefaultCell, { value: value }),
                    },
                    {
                        Header: "E-mail",
                        accessor: "email",
                        Cell: ({ value }) => _jsx(DefaultCell, { value: value }),
                    },
                    {
                        Header: "Phone",
                        accessor: "phone",
                        Cell: ({ value }) => _jsx(DefaultCell, { value: value }),
                    },
                    {
                        Header: "profile",
                        accessor: "profileImageUrl",
                        Cell: ({ value }) => {
                            const imagePath = url(`/user/media/${value}`).value;
                            return _jsx(CustomerCell, { image: value ? imagePath : "", color: "dark", name: "" });
                        },
                    },
                    {
                        Header: "NewsLetter Subscription",
                        accessor: "newsletterSubscribed",
                        Cell: ({ value }) => (_jsx(StatusCell, { status: value, color: value ? "success" : "error", icon: value ? "done" : "close" })),
                    },
                ],
                rows: result.resultOfUsers,
            };
            setDataTableData(prevData);
        }
    }, [result]);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(MDBox, Object.assign({ my: 3 }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", mb: 2 }, { children: _jsx(MDBox, Object.assign({ display: "flex" }, { children: _jsxs(MDButton, Object.assign({ color: "dark" }, { children: ["filters\u00A0", _jsx(Icon, { children: "keyboard_arrow_down" })] })) })) })), _jsx(Card, { children: dataTableData && result ? (_jsx(DataTable, { entriesPerPage: count, table: dataTableData, totalEntries: result.totalCounts, canSearch: true, goToPageCb: (page) => {
                                setOffset(page * count);
                                setCurrentPage(page);
                            }, currentPage: currentPage })) : null })] }))] }));
}
