/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";
const { transparent, gradients } = colors;
const { borderRadius } = borders;
const { colored } = boxShadows;
const stepper = {
    styleOverrides: {
        root: {
            background: linearGradient(gradients.primary.main, gradients.primary.state),
            padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
            borderRadius: borderRadius.lg,
            boxShadow: colored.primary,
            "&.MuiPaper-root": {
                backgroundColor: transparent.main,
            },
        },
    },
};
export default stepper;
