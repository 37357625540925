import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Paper, Stack } from "@mui/material";
import SceneLoader from "threejs/component/SceneLoader";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
export default function Template({ template }) {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    return userInfo ? (_jsxs(Stack, Object.assign({ direction: "column", style: { display: "flex", flexGrow: 1, overflow: "auto", height: "100%" } }, { children: [_jsx(Paper, Object.assign({ variant: "outlined", square: true, sx: {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                } }, { children: _jsx("div", { id: "scene-container", style: {
                        width: "100%",
                        height: "100%",
                    } }) })), _jsx(SceneLoader, { mode: "Template", containerId: "scene-container", templateInfo: template, userInfo: userInfo })] }))) : null;
}
