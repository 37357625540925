import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
function StatusCell({ icon, color, status }) {
    return (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(MDBox, Object.assign({ mr: 1 }, { children: _jsx(MDButton, Object.assign({ variant: "outlined", color: color, size: "small", iconOnly: true, circular: true }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: icon })) })) })), _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", color: "text", sx: { lineHeight: 0 } }, { children: status }))] })));
}
export default StatusCell;
