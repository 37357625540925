import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import DefaultItem from "components/generic/Items/DefaultItem";
import { Divider } from "@mui/material";
import { url } from "services/ApiEndpoint";
import { useFormGet } from "hooks/useFormGet";
function EventList({ events, title }) {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    const getActions = (event) => {
        return userInfo && event.creator === userInfo._id
            ? [
                {
                    type: "internal",
                    route: `/events/conf/${event._id}`,
                    color: "success",
                    label: "Join",
                },
                {
                    type: "internal",
                    route: `/events/update/${event._id}`,
                    color: "primary",
                    label: "Edit",
                },
            ]
            : [
                {
                    type: "internal",
                    route: `/events/conf/${event._id}`,
                    color: "success",
                    label: "Join",
                },
            ];
    };
    const renderItems = events === null || events === void 0 ? void 0 : events.map((event, index) => (_jsxs(Fragment, { children: [index > 0 ? _jsx(Divider, {}) : null, _jsx(DefaultItem, { title: event.eventType, description: event.title, subTitle: new Date(event.start * 1000).toLocaleDateString("fr-FR") +
                    " at " +
                    new Date(event.start * 1000).toLocaleTimeString("fr-FR"), color: "dark", actions: getActions(event) })] }, index)));
    return (_jsxs(Card, { children: [_jsx(MDBox, Object.assign({ pt: 2, px: 2 }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium", textTransform: "capitalize" }, { children: title })) })), userInfo ? _jsx(MDBox, Object.assign({ p: 2 }, { children: renderItems })) : null] }));
}
export default EventList;
