import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapse, Icon, List, ListItem, ListItemText } from "@mui/material";
import Item from "./Item";
import MDBox from "components/generic/MDBox";
import { useState } from "react";
export default function ItemList(props) {
    const [isOpen, setOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ component: "li" }, { children: _jsxs(MDBox, Object.assign({ style: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }, onClick: () => {
                        setOpen(!isOpen);
                    } }, { children: [_jsx(ListItemText, { primary: "Widget Explorer" }), props.sceneItemList.length && (_jsx(Icon, Object.assign({ component: "i", style: {
                                transform: isOpen ? "rotate(0)" : "rotate(-180deg)",
                                transition: "easeOut",
                            } }, { children: "expand_less" })))] })) })), _jsx(Collapse, Object.assign({ in: isOpen, timeout: "auto", unmountOnExit: true }, { children: _jsx(List, { children: props.sceneItemList.map((element, index) => (_jsx(Item, { item: element, domElement: props.domElement }, index))) }) }))] }));
}
