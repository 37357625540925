var _a, _b;
import { createSlice } from "@reduxjs/toolkit";
import { StaticValues } from 'utils/const/StaticValues';
const userInitialState = {
    isLoggedIn: localStorage.getItem(StaticValues.ConnectionTokenName) ? true : false,
    onLoading: false,
    userInitial: (_a = localStorage.getItem(StaticValues.UserInitial)) !== null && _a !== void 0 ? _a : '',
    userToken: (_b = localStorage.getItem(StaticValues.ConnectionTokenName)) !== null && _b !== void 0 ? _b : ''
};
export const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setToken: (state, action) => {
            const token = action.payload;
            localStorage.setItem(StaticValues.ConnectionTokenName, token);
            state.userToken = action.payload;
        },
        setUsetInitial: (state, action) => {
            const initial = action.payload;
            localStorage.setItem(StaticValues.UserInitial, initial);
            state.userInitial = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        signIn: (state, action) => {
            console.log(action.payload);
            state.isLoggedIn = true;
            state.userToken = action.payload.token;
            state.userInitial = action.payload.initial;
            localStorage.setItem(StaticValues.ConnectionTokenName, action.payload.token);
            localStorage.setItem(StaticValues.UserInitial, action.payload.initial);
        },
        signOut: (state) => {
            state.isLoggedIn = false;
            state.userToken = '';
            state.userInitial = '';
            localStorage.removeItem(StaticValues.ConnectionTokenName);
            localStorage.removeItem(StaticValues.UserInitial);
        }
    }
});
export const { signOut, signIn, setToken } = userSlice.actions;
export default userSlice.reducer;
