/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import { createTheme } from "@mui/material/styles";
// import Fade from "@mui/material/Fade";
// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme-dark/base/colors";
import breakpoints from "assets/theme-dark/base/breakpoints";
import typography from "assets/theme-dark/base/typography";
import boxShadows from "assets/theme-dark/base/boxShadows";
import borders from "assets/theme-dark/base/borders";
import globals from "assets/theme-dark/base/globals";
// Material Dashboard 2 PRO React TS Helper Functions
import boxShadow from "assets/theme-dark/functions/boxShadow";
import hexToRgb from "assets/theme-dark/functions/hexToRgb";
import linearGradient from "assets/theme-dark/functions/linearGradient";
import pxToRem from "assets/theme-dark/functions/pxToRem";
import rgba from "assets/theme-dark/functions/rgba";
// Material Dashboard 2 PRO React TS components base styles for @mui material components
import sidenav from "assets/theme-dark/components/sidenav";
import list from "assets/theme-dark/components/list";
import listItem from "assets/theme-dark/components/list/listItem";
import listItemText from "assets/theme-dark/components/list/listItemText";
import card from "assets/theme-dark/components/card";
import cardMedia from "assets/theme-dark/components/card/cardMedia";
import cardContent from "assets/theme-dark/components/card/cardContent";
import button from "assets/theme-dark/components/button";
import iconButton from "assets/theme-dark/components/iconButton";
import input from "assets/theme-dark/components/form/input";
import inputLabel from "assets/theme-dark/components/form/inputLabel";
import inputOutlined from "assets/theme-dark/components/form/inputOutlined";
import textField from "assets/theme-dark/components/form/textField";
import menu from "assets/theme-dark/components/menu";
import menuItem from "assets/theme-dark/components/menu/menuItem";
import switchButton from "assets/theme-dark/components/form/switchButton";
import divider from "assets/theme-dark/components/divider";
import tableContainer from "assets/theme-dark/components/table/tableContainer";
import tableHead from "assets/theme-dark/components/table/tableHead";
import tableCell from "assets/theme-dark/components/table/tableCell";
import linearProgress from "assets/theme-dark/components/linearProgress";
import breadcrumbs from "assets/theme-dark/components/breadcrumbs";
import slider from "assets/theme-dark/components/slider";
import avatar from "assets/theme-dark/components/avatar";
import tooltip from "assets/theme-dark/components/tooltip";
import appBar from "assets/theme-dark/components/appBar";
import tabs from "assets/theme-dark/components/tabs";
import tab from "assets/theme-dark/components/tabs/tab";
import stepper from "assets/theme-dark/components/stepper";
import step from "assets/theme-dark/components/stepper/step";
import stepConnector from "assets/theme-dark/components/stepper/stepConnector";
import stepLabel from "assets/theme-dark/components/stepper/stepLabel";
import stepIcon from "assets/theme-dark/components/stepper/stepIcon";
import select from "assets/theme-dark/components/form/select";
import formControlLabel from "assets/theme-dark/components/form/formControlLabel";
import formLabel from "assets/theme-dark/components/form/formLabel";
import checkbox from "assets/theme-dark/components/form/checkbox";
import radio from "assets/theme-dark/components/form/radio";
import autocomplete from "assets/theme-dark/components/form/autocomplete";
import flatpickr from "assets/theme-dark/components/flatpickr";
import container from "assets/theme-dark/components/container";
import popover from "assets/theme-dark/components/popover";
import buttonBase from "assets/theme-dark/components/buttonBase";
import icon from "assets/theme-dark/components/icon";
import svgIcon from "assets/theme-dark/components/svgIcon";
import link from "assets/theme-dark/components/link";
import dialog from "assets/theme-dark/components/dialog";
import dialogTitle from "assets/theme-dark/components/dialog/dialogTitle";
import dialogContent from "assets/theme-dark/components/dialog/dialogContent";
import dialogContentText from "assets/theme-dark/components/dialog/dialogContentText";
import dialogActions from "assets/theme-dark/components/dialog/dialogActions";
export default createTheme({
    breakpoints: Object.assign({}, breakpoints),
    palette: Object.assign({}, colors),
    typography: Object.assign({}, typography),
    boxShadows: Object.assign({}, boxShadows),
    borders: Object.assign({}, borders),
    functions: {
        boxShadow,
        hexToRgb,
        linearGradient,
        pxToRem,
        rgba,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: Object.assign(Object.assign(Object.assign({}, globals), flatpickr), container),
        },
        MuiDrawer: Object.assign({}, sidenav),
        MuiList: Object.assign({}, list),
        MuiListItem: Object.assign({}, listItem),
        MuiListItemText: Object.assign({}, listItemText),
        MuiCard: Object.assign({}, card),
        MuiCardMedia: Object.assign({}, cardMedia),
        MuiCardContent: Object.assign({}, cardContent),
        MuiButton: Object.assign({}, button),
        MuiIconButton: Object.assign({}, iconButton),
        MuiInput: Object.assign({}, input),
        MuiInputLabel: Object.assign({}, inputLabel),
        MuiOutlinedInput: Object.assign({}, inputOutlined),
        MuiTextField: Object.assign({}, textField),
        MuiMenu: Object.assign({}, menu),
        MuiMenuItem: Object.assign({}, menuItem),
        MuiSwitch: Object.assign({}, switchButton),
        MuiDivider: Object.assign({}, divider),
        MuiTableContainer: Object.assign({}, tableContainer),
        MuiTableHead: Object.assign({}, tableHead),
        MuiTableCell: Object.assign({}, tableCell),
        MuiLinearProgress: Object.assign({}, linearProgress),
        MuiBreadcrumbs: Object.assign({}, breadcrumbs),
        MuiSlider: Object.assign({}, slider),
        MuiAvatar: Object.assign({}, avatar),
        MuiTooltip: Object.assign({}, tooltip),
        MuiAppBar: Object.assign({}, appBar),
        MuiTabs: Object.assign({}, tabs),
        MuiTab: Object.assign({}, tab),
        MuiStepper: Object.assign({}, stepper),
        MuiStep: Object.assign({}, step),
        MuiStepConnector: Object.assign({}, stepConnector),
        MuiStepLabel: Object.assign({}, stepLabel),
        MuiStepIcon: Object.assign({}, stepIcon),
        MuiSelect: Object.assign({}, select),
        MuiFormControlLabel: Object.assign({}, formControlLabel),
        MuiFormLabel: Object.assign({}, formLabel),
        MuiCheckbox: Object.assign({}, checkbox),
        MuiRadio: Object.assign({}, radio),
        MuiAutocomplete: Object.assign({}, autocomplete),
        MuiPopover: Object.assign({}, popover),
        MuiButtonBase: Object.assign({}, buttonBase),
        MuiIcon: Object.assign({}, icon),
        MuiSvgIcon: Object.assign({}, svgIcon),
        MuiLink: Object.assign({}, link),
        MuiDialog: Object.assign({}, dialog),
        MuiDialogTitle: Object.assign({}, dialogTitle),
        MuiDialogContent: Object.assign({}, dialogContent),
        MuiDialogContentText: Object.assign({}, dialogContentText),
        MuiDialogActions: Object.assign({}, dialogActions),
    },
});
