import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, Fragment } from "react";
// react-router components
import { Link } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import Container from "@mui/material/Container";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
// Material Dashboard 2 PRO React TS examples components
import DefaultNavbarDropdown from "components/generic/Navbars/DefaultNavbar/DefaultNavbarDropdown";
import DefaultNavbarMobile from "components/generic/Navbars/DefaultNavbar/DefaultNavbarMobile";
// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
function NewGrow(props) {
    return _jsx(Grow, Object.assign({}, props));
}
function DefaultNavbar({ routes, brand = "CAPSULE", transparent = false, light = false, action }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [dropdown, setDropdown] = useState("");
    const [dropdownEl, setDropdownEl] = useState("");
    const [dropdownName, setDropdownName] = useState("");
    const [nestedDropdown, setNestedDropdown] = useState("");
    const [nestedDropdownEl, setNestedDropdownEl] = useState("");
    const [nestedDropdownName, setNestedDropdownName] = useState("");
    const [arrowRef, setArrowRef] = useState(null);
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            }
            else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }
        /**
         The event listener that's calling the displayMobileNavbar function when
         resizing the window.
        */
        window.addEventListener("resize", displayMobileNavbar);
        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);
    const renderNavbarItems = routes.map(({ name, icon, href, route, collapse }) => (_jsx(DefaultNavbarDropdown, { name: name, icon: icon, href: href, route: route, collapse: Boolean(collapse), onMouseEnter: ({ currentTarget }) => {
            if (collapse) {
                setDropdown(currentTarget);
                setDropdownEl(currentTarget);
                setDropdownName(name);
            }
        }, onMouseLeave: () => collapse && setDropdown(null), light: light }, name)));
    // Render the routes on the dropdown menu
    const renderRoutes = routes.map(({ name, collapse, columns, rowsPerColumn }) => {
        let template;
        // Render the dropdown menu that should be display as columns
        if (collapse && columns && name === dropdownName) {
            const calculateColumns = collapse.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / rowsPerColumn);
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [];
                }
                resultArray[chunkIndex].push(item);
                return resultArray;
            }, []);
            template = (_jsx(Grid, Object.assign({ container: true, spacing: 3, py: 1, px: 1.5 }, { children: calculateColumns.map((cols, key) => {
                    const gridKey = `grid-${key}`;
                    const dividerKey = `divider-${key}`;
                    return (_jsxs(Grid, Object.assign({ item: true, xs: 12 / columns, sx: { position: "relative" } }, { children: [cols.map((col, index) => (_jsxs(Fragment, { children: [_jsxs(MDBox, Object.assign({ width: "100%", display: "flex", alignItems: "center", py: 1, mt: index !== 0 ? 2 : 0 }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", width: "1.5rem", height: "1.5rem", borderRadius: "md", color: "text", mr: 1, fontSize: "1rem", lineHeight: 1 }, { children: col.icon })), _jsx(MDTypography, Object.assign({ display: "block", variant: "button", fontWeight: "bold", textTransform: "capitalize" }, { children: col.name }))] })), col.collapse.map((item) => (_jsx(MDTypography, Object.assign({ component: item.route ? Link : MuiLink, to: item.route ? item.route : "", href: item.href ? item.href : (e) => e.preventDefault(), target: item.href ? "_blank" : "", rel: item.href ? "noreferrer" : "noreferrer", minWidth: "11.25rem", display: "block", variant: "button", color: "text", textTransform: "capitalize", fontWeight: "regular", py: 0.625, px: 2, sx: ({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                                            borderRadius: borderRadius.md,
                                            cursor: "pointer",
                                            transition: "all 300ms linear",
                                            "&:hover": {
                                                backgroundColor: grey[200],
                                                color: dark.main,
                                            },
                                        }) }, { children: item.name }), item.name)))] }, col.name))), key !== 0 && (_jsx(Divider, { orientation: "vertical", sx: {
                                    position: "absolute",
                                    top: "50%",
                                    left: "-4px",
                                    transform: "translateY(-45%)",
                                    height: "90%",
                                } }, dividerKey))] }), gridKey));
                }) }), name));
            // Render the dropdown menu that should be display as list items
        }
        else if (collapse && name === dropdownName) {
            template = collapse.map((item) => {
                const linkComponent = {
                    component: MuiLink,
                    href: item.href,
                    target: "_blank",
                    rel: "noreferrer",
                };
                const routeComponent = {
                    component: Link,
                    to: item.route,
                };
                return (_jsxs(MDTypography, Object.assign({}, (item.route ? routeComponent : linkComponent), { display: "flex", justifyContent: "space-between", alignItems: "center", variant: "button", textTransform: "capitalize", minWidth: item.description ? "14rem" : "12rem", color: item.description ? "dark" : "text", fontWeight: item.description ? "bold" : "regular", py: item.description ? 1 : 0.625, px: 2, sx: ({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                        borderRadius: borderRadius.md,
                        cursor: "pointer",
                        transition: "all 300ms linear",
                        "&:hover": {
                            backgroundColor: grey[200],
                            color: dark.main,
                            "& *": {
                                color: dark.main,
                            },
                        },
                    }), onMouseEnter: ({ currentTarget }) => {
                        if (item.dropdown) {
                            setNestedDropdown(currentTarget);
                            setNestedDropdownEl(currentTarget);
                            setNestedDropdownName(item.name);
                        }
                    }, onMouseLeave: () => {
                        if (item.dropdown) {
                            setNestedDropdown(null);
                        }
                    } }, { children: [item.description ? (_jsxs(MDBox, Object.assign({ display: "flex", py: 0.25, fontSize: "1rem", color: "text" }, { children: [typeof item.icon === "string" ? (_jsx(Icon, Object.assign({ color: "inherit" }, { children: item.icon }))) : (_jsx(MDBox, Object.assign({ color: "inherit" }, { children: item.icon }))), _jsxs(MDBox, Object.assign({ pl: 1, lineHeight: 0 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", display: "block", fontWeight: "bold", textTransform: "capitalize" }, { children: item.name })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: item.description }))] }))] }))) : (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center", color: "text" }, { children: [_jsx(Icon, Object.assign({ sx: { mr: 1 } }, { children: item.icon })), item.name] }))), item.collapse && (_jsx(Icon, Object.assign({ sx: { fontWeight: "normal", verticalAlign: "middle", mr: -0.5 } }, { children: "keyboard_arrow_right" })))] }), item.name));
            });
        }
        return template;
    });
    // Routes dropdown menu
    const dropdownMenu = (_jsx(Popper, Object.assign({ anchorEl: dropdown, popperRef: null, open: Boolean(dropdown), placement: "top-start", transition: true, style: { zIndex: 10 }, modifiers: [
            {
                name: "arrow",
                enabled: true,
                options: {
                    element: arrowRef,
                },
            },
        ], onMouseEnter: () => setDropdown(dropdownEl), onMouseLeave: () => {
            if (!nestedDropdown) {
                setDropdown(null);
                setDropdownName("");
            }
        } }, { children: ({ TransitionProps }) => (_jsx(NewGrow, Object.assign({}, TransitionProps, { sx: {
                transformOrigin: "left top",
                background: ({ palette: { white } }) => white.main,
            } }, { children: _jsxs(MDBox, Object.assign({ borderRadius: "lg" }, { children: [_jsx(MDTypography, Object.assign({ variant: "h1", color: "white" }, { children: _jsx(Icon, Object.assign({ ref: setArrowRef, sx: { mt: -3 } }, { children: "arrow_drop_up" })) })), _jsx(MDBox, Object.assign({ shadow: "lg", borderRadius: "lg", p: 1.625, mt: 1 }, { children: renderRoutes }))] })) }))) })));
    // Render routes that are nested inside the dropdown menu routes
    const renderNestedRoutes = routes.map(({ collapse, columns }) => collapse && !columns
        ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
            let template;
            if (parentName === nestedDropdownName) {
                template =
                    nestedCollapse &&
                        nestedCollapse.map((item) => {
                            const linkComponent = {
                                component: MuiLink,
                                href: item.href,
                                target: "_blank",
                                rel: "noreferrer",
                            };
                            const routeComponent = {
                                component: Link,
                                to: item.route,
                            };
                            return (_jsxs(MDTypography, Object.assign({}, (item.route ? routeComponent : linkComponent), { display: "flex", justifyContent: "space-between", alignItems: "center", variant: "button", textTransform: "capitalize", minWidth: item.description ? "14rem" : "12rem", color: item.description ? "dark" : "text", fontWeight: item.description ? "bold" : "regular", py: item.description ? 1 : 0.625, px: 2, sx: ({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                                    borderRadius: borderRadius.md,
                                    cursor: "pointer",
                                    transition: "all 300ms linear",
                                    "&:hover": {
                                        backgroundColor: grey[200],
                                        color: dark.main,
                                        "& *": {
                                            color: dark.main,
                                        },
                                    },
                                }) }, { children: [item.description ? (_jsxs(MDBox, { children: [item.name, _jsx(MDTypography, Object.assign({ display: "block", variant: "button", color: "text", fontWeight: "regular", sx: { transition: "all 300ms linear" } }, { children: item.description }))] })) : (item.name), item.collapse && (_jsx(Icon, Object.assign({ fontSize: "small", sx: { fontWeight: "normal", verticalAlign: "middle", mr: -0.5 } }, { children: "keyboard_arrow_right" })))] }), item.name));
                        });
            }
            return template;
        })
        : null);
    // Dropdown menu for the nested dropdowns
    const nestedDropdownMenu = (_jsx(Popper, Object.assign({ anchorEl: nestedDropdown, popperRef: null, open: Boolean(nestedDropdown), placement: "right-start", transition: true, style: { zIndex: 10 }, onMouseEnter: () => {
            setNestedDropdown(nestedDropdownEl);
        }, onMouseLeave: () => {
            setNestedDropdown(null);
            setNestedDropdownName("");
            setDropdown(null);
        } }, { children: ({ TransitionProps }) => (_jsx(NewGrow, Object.assign({}, TransitionProps, { sx: {
                transformOrigin: "left top",
                background: ({ palette: { white } }) => white.main,
            } }, { children: _jsx(MDBox, Object.assign({ ml: 2.5, mt: -2.5, borderRadius: "lg" }, { children: _jsx(MDBox, Object.assign({ shadow: "lg", borderRadius: "lg", py: 1.5, px: 1, mt: 2 }, { children: renderNestedRoutes })) })) }))) })));
    return (_jsxs(Container, { children: [_jsxs(MDBox, Object.assign({ py: 1, px: { xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }, my: 3, mx: 3, width: "calc(100% - 48px)", borderRadius: "lg", shadow: transparent ? "none" : "md", color: light ? "white" : "dark", display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", left: 0, zIndex: 3, sx: ({ palette: { transparent: transparentColor, white, background }, functions: { rgba }, }) => ({
                    backgroundColor: transparent
                        ? transparentColor.main
                        : rgba(darkMode ? background.sidenav : white.main, 0.8),
                    backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
                }) }, { children: [_jsx(MDBox, Object.assign({ component: Link, to: "/", py: transparent ? 1.5 : 0.75, lineHeight: 1, pl: { xs: 0, lg: 1 } }, { children: _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "bold", color: light ? "white" : "white" }, { children: brand })) })), _jsx(MDBox, Object.assign({ color: "inherit", display: { xs: "none", lg: "flex" }, m: 0, p: 0 }, { children: renderNavbarItems })), action &&
                        (action.type === "internal" ? (_jsx(MDBox, Object.assign({ display: { xs: "none", lg: "inline-block" } }, { children: _jsx(MDButton, Object.assign({ component: Link, to: action.route, variant: "gradient", color: action.color ? action.color : "primary", size: "small" }, { children: action.label })) }))) : (_jsx(MDBox, Object.assign({ display: { xs: "none", lg: "inline-block" } }, { children: _jsx(MDButton, Object.assign({ component: "a", href: action.route, target: "_blank", rel: "noreferrer", variant: "gradient", color: action.color ? action.color : "primary", size: "small", sx: { mt: -0.3 } }, { children: action.label })) })))), _jsx(MDBox, Object.assign({ display: { xs: "inline-block", lg: "none" }, lineHeight: 0, py: 1.5, pl: 1.5, color: "inherit", sx: { cursor: "pointer" }, onClick: openMobileNavbar }, { children: mobileView && _jsx(DefaultNavbarMobile, { routes: routes, open: mobileNavbar }) }))] })), dropdownMenu, nestedDropdownMenu] }));
}
export default DefaultNavbar;
