import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Container from "@mui/material/Container";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Material Dashboard 2 PRO React TS Base Styles
import typography from "assets/theme/base/typography";
function Footer({ light = false }) {
    const { size } = typography;
    return (_jsx(MDBox, Object.assign({ position: "absolute", width: "100%", bottom: 0, py: 4 }, { children: _jsx(Container, { children: _jsxs(MDBox, Object.assign({ width: "100%", display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-between", alignItems: "center", px: 1.5 }, { children: [_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", color: light ? "white" : "text", fontSize: size.sm }, { children: ["\u00A9 ", new Date().getFullYear(), ", made by Younivr"] })), _jsx(MDBox, { component: "ul", sx: ({ breakpoints }) => ({
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                            listStyle: "none",
                            mt: 3,
                            mb: 0,
                            p: 0,
                            [breakpoints.up("lg")]: {
                                mt: 0,
                            },
                        }) })] })) }) })));
}
export default Footer;
