import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getIn } from "formik";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import MDTypography from "../MDTypography";
export default function FormInputColor(props) {
    // const value = NestedArrayResolveStrategy(props.formik.values, props.name);
    const value = getIn(props.formik.values, props.name);
    // const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    const error = getIn(props.formik.errors, props.name);
    return (_jsxs(MDBox, Object.assign({ style: {
            marginBottom: 15,
        } }, { children: [_jsx(MDInput, Object.assign({ variant: "outlined", fullWidth: true, value: value, onChange: props.formik.handleChange, error: error ? true : false, type: "color" }, props)), error ? (_jsx(MDBox, Object.assign({ style: {
                    marginTop: 15,
                } }, { children: _jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "error", fontWeight: "regular" }, { children: error.toString() })) }))) : null] })));
}
