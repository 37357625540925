var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Custom styles for the SidenavCollapse
import { collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow, } from "components/generic/Sidenav/styles/sidenavCollapse";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
function SidenavCollapse(_a) {
    var { icon, name, children = false, active = false, noCollapse = false, open = false } = _a, rest = __rest(_a, ["icon", "name", "children", "active", "noCollapse", "open"]);
    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ component: "li" }, { children: _jsxs(MDBox, Object.assign({}, rest, { sx: (theme) => collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode }) }, { children: [_jsx(ListItemIcon, Object.assign({ sx: (theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode }) }, { children: typeof icon === "string" ? (_jsx(Icon, Object.assign({ sx: (theme) => collapseIcon(theme, { active }) }, { children: icon }))) : (icon) })), _jsx(ListItemText, { primary: name, sx: (theme) => collapseText(theme, {
                                miniSidenav,
                                transparentSidenav,
                                whiteSidenav,
                                active,
                            }) }), _jsx(Icon, Object.assign({ sx: (theme) => collapseArrow(theme, {
                                noCollapse,
                                transparentSidenav,
                                whiteSidenav,
                                miniSidenav,
                                open,
                                active,
                                darkMode,
                            }) }, { children: "expand_less" }))] })) })), children && (_jsx(Collapse, Object.assign({ in: Boolean(open), unmountOnExit: true }, { children: children })))] }));
}
export default SidenavCollapse;
