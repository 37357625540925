import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
//CSS
import "./App.css";
import React, { useState } from "react";
import { GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import themeDark from "assets/theme-dark";
import theme from "assets/theme";
import Sidenav from "components/generic/Sidenav";
// react-router components
import { Routes, Route } from "react-router-dom";
// Images
import brandWhite from "assets/images/customer-logo-light.png";
import brandDark from "assets/images/customer-logo-dark.png";
import younivrBrandWhite from "assets/images/logo-short.png";
import younivrBrandDark from "assets/images/logo-short-dark.png";
// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "./contexts";
// Material Dashboard 2 PRO React TS routes
import routes from "./routes/routes";
import SignInBasic from "page/authentication/sign-in";
import SignUpBasic from "page/authentication/sign-up";
import ResetPassword from "page/authentication/reset-password";
import { Provider } from "react-redux";
import { store } from "redux-store/store";
import ProtectedRoute from "./routes/ProtectedRoute";
import RoomEditorV2 from "page/room/RoomEditor";
import EditTemplate from "page/template/EditTemplate";
import PricingPage from "page/pricing-page";
import Home from "page/dashboard/Home";
import Conference from "page/event/Conference";
import UpdateEvent from "page/event/UpdateEvent";
import NewEvent from "page/event/NewEvent";
import UpdatePassword from "page/authentication/update-password";
import AdminEditTemplate from "page/admin/template/edit";
export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode, } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };
    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };
    const getRoutes = (allRoutes) => allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }
        if (route.route) {
            return _jsx(Route, { path: route.route, element: route.component }, route.key);
        }
        return null;
    });
    return (_jsxs(React.Fragment, { children: [_jsx(GlobalStyles, { styles: {
                    "*::-webkit-scrollbar": {
                        width: "0.4em",
                    },
                    "*::-webkit-scrollbar-track": {
                        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0,0,0,.3)",
                    },
                } }), _jsx(Provider, Object.assign({ store: store }, { children: _jsxs(ThemeProvider, Object.assign({ theme: darkMode ? themeDark : theme }, { children: [_jsx(CssBaseline, {}), layout === "dashboard" && (_jsx(_Fragment, { children: _jsx(Sidenav, { color: sidenavColor, brand: (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite, brandName: "Capsule", younivrBrand: (transparentSidenav && !darkMode) || whiteSidenav
                                    ? younivrBrandDark
                                    : younivrBrandWhite, routes: routes, onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave }) })), _jsxs(Routes, { children: [_jsx(Route, { path: "/authentication/sign-in", element: _jsx(SignInBasic, {}) }), _jsx(Route, { path: "/pricings", element: _jsx(PricingPage, {}) }), _jsx(Route, { path: "/authentication/sign-up", element: _jsx(SignUpBasic, {}) }), _jsx(Route, { path: "/authentication/reset-password", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "/authentication/update-password/:uniqueRef", element: _jsx(UpdatePassword, {}) }), _jsx(Route, { path: "*", element: _jsx(Home, {}) }), _jsxs(Route, Object.assign({ element: _jsx(ProtectedRoute, {}) }, { children: [getRoutes(routes), _jsx(Route, { path: "/dashboard/home", element: _jsx(Home, {}) }), _jsx(Route, { path: "events/create", element: _jsx(NewEvent, {}) }), _jsx(Route, { path: "events/update/:eventRef", element: _jsx(UpdateEvent, {}) }), _jsx(Route, { path: "events/conf/:id", element: _jsx(Conference, {}) }), _jsx(Route, { path: "/rooms/edit/:roomRef", element: _jsx(RoomEditorV2, {}) }), _jsx(Route, { path: "/template/edit/:id", element: _jsx(EditTemplate, {}) }), _jsx(Route, { path: "/admin/template/edit/:id", element: _jsx(AdminEditTemplate, {}) })] }))] })] })) }))] }));
}
