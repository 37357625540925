import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
function Breadcrumbs({ icon, title, route, light = false }) {
    const routes = route.slice(0, -1);
    return (_jsxs(MDBox, Object.assign({ mr: { xs: 0, xl: 8 } }, { children: [_jsxs(MuiBreadcrumbs, Object.assign({ sx: {
                    "& .MuiBreadcrumbs-separator": {
                        color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
                    },
                } }, { children: [_jsx(Link, Object.assign({ to: "/" }, { children: _jsx(MDTypography, Object.assign({ component: "span", variant: "body2", color: light ? "white" : "dark", opacity: light ? 0.8 : 0.5, sx: { lineHeight: 0 } }, { children: _jsx(Icon, { children: icon }) })) })), routes.map((el) => (_jsx(Link, Object.assign({ to: `/${el}` }, { children: _jsx(MDTypography, Object.assign({ component: "span", variant: "button", fontWeight: "regular", textTransform: "capitalize", color: light ? "white" : "dark", opacity: light ? 0.8 : 0.5, sx: { lineHeight: 0 } }, { children: el })) }), el))), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", textTransform: "capitalize", color: light ? "white" : "dark", sx: { lineHeight: 0 } }, { children: title.replace("-", " ") }))] })), _jsx(MDTypography, Object.assign({ fontWeight: "bold", textTransform: "capitalize", variant: "h6", color: light ? "white" : "dark", noWrap: true }, { children: title.replace("-", " ") }))] })));
}
export default Breadcrumbs;
