import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import EventType from "components/features/event/field/EventType";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import EventDate from "components/features/event/field/EventDate";
import FormSubmitButton from "components/generic/form/FormSubmitButton";
import EventParticipants from "components/features/event/field/EventParticipants";
import EventRoom from "components/features/event/field/EventRoom";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import FormToggleButton from "components/generic/form/FormToggleButton";
import FormInputNumber from "components/generic/form/FormInputNumber";
export default function EventForm(props) {
    let urlValue;
    if (props._id) {
        urlValue = "/event/update";
    }
    else {
        urlValue = "/event/create";
    }
    const { execute, status, globalErrorMessage } = useFormPost(url(urlValue));
    useEffect(() => {
        if (status === 200) {
            window.location.href = "/events";
        }
    }, [status]);
    const formData = useFormik({
        initialValues: props.formFields,
        onSubmit: async (v) => {
            let data;
            if (props._id) {
                data = Object.assign(Object.assign({}, v), { _id: props._id });
            }
            else {
                data = v;
            }
            await execute(JSON.stringify(data));
            props.onClose();
        },
    });
    return (_jsxs("form", Object.assign({ onSubmit: formData.handleSubmit, noValidate: true }, { children: [globalErrorMessage, _jsxs(Stack, Object.assign({ p: 2, direction: "column", style: { height: "100%" } }, { children: [_jsx(EventType, { name: "eventType", label: "Event Type", formik: formData, required: true }), _jsx(FormInputText, { name: "title", label: "Title", formik: formData, required: true }), _jsxs(Stack, Object.assign({ direction: "row", spacing: 2, style: { height: "100%" } }, { children: [_jsx(EventDate, { name: "start", formik: formData, label: "Date", required: true }), _jsx(EventDate, { name: "end", formik: formData, label: "Date", required: true })] })), _jsx(EventParticipants, { name: "participants", formik: formData, label: "participants" }), _jsx(EventRoom, { name: "room", formik: formData, label: "Room" }), _jsx(FormInputTextArea, { name: "description", label: "Description", formik: formData, rows: 5, required: true }), _jsx(FormInputTextArea, { name: "agenda", label: "Agenda", formik: formData, rows: 5, required: true }), _jsx(FormToggleButton, { name: "isPublic", label: "Public Event", formik: formData, required: true }), _jsx(FormInputNumber, { name: "maxAttendees", label: "Max Attendees", formik: formData, required: true }), _jsx(FormInputNumber, { name: "price", label: "Prix", formik: formData, required: true }), _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: "Submit" })] }))] })));
}
