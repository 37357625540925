import { DoubleSide, Mesh, MeshStandardMaterial, PlaneGeometry, VideoTexture } from "three";
import { SceneItem } from "../model/SceneItem";
export class VideoMesh extends Mesh {
    constructor() {
        super(new PlaneGeometry(1, 1), new MeshStandardMaterial());
        this.type = "VideoMesh";
        this.name = "VideoPlane";
        this.material.side = DoubleSide;
    }
    getVideoElement() {
        return this.videoElement;
    }
    dataToMesh(data) {
        this.clear();
        const { name, rotation, position, scale, otherDatas } = data;
        this.position.copy(position);
        this.rotation.copy(rotation);
        this.scale.copy(scale);
        this.name = name;
        otherDatas.src ? (this.src = otherDatas.src) : (otherDatas.src = "");
        if (!otherDatas.displayName)
            otherDatas.displayName = this.uuid;
        this.userData = otherDatas;
        this.updateSrc();
        this.material.needsUpdate;
    }
    updateSrc() {
        const video = document.createElement("video");
        // TODO add toggle button to switch on / off sound
        video.src = this.src;
        video.autoplay = true;
        video.loop = true;
        video.crossOrigin = "anonymous";
        video.muted = true;
        const texture = new VideoTexture(video);
        this.material.map = texture;
        video.play();
        document.addEventListener("visibilitychange", function () {
            if (!document.hidden) {
                video.play().catch((error) => {
                    console.log("Playback interrupted:", error);
                });
            }
            else {
                console.log("video paused");
                video.pause();
            }
        });
        this.videoElement = video;
    }
    meshToData() {
        const { position, rotation, scale, name, uuid, userData } = this;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = position;
        res.scale = scale;
        res.name = name;
        res.type = this.type;
        res.otherDatas = userData;
        return res;
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
