import { GridRowModes } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTranslation } from 'redux-store/TranslationSlice';
import { useFormGet } from 'hooks/useFormGet';
import { useFormPost } from 'hooks/useFormPost';
import { url } from "services/ApiEndpoint";
export const useGridOperations = (params) => {
    const { execute: updateRowExecute, result: updateTranslationResult } = useFormPost(url('/site/translation/add'));
    const dispatch = useDispatch();
    useEffect(() => {
        if (updateTranslationResult) {
            dispatch(setTranslation(updateTranslationResult));
        }
    }, [updateTranslationResult]);
    return {
        handleEditClick: (id) => () => {
            params.setRowModesModel(Object.assign(Object.assign({}, params.rowModesModel), { [id]: { mode: GridRowModes.Edit } }));
        },
        handleCancelClick: (id) => () => {
            params.setRowModesModel(Object.assign(Object.assign({}, params.rowModesModel), { [id]: { mode: GridRowModes.View, ignoreModifications: true } }));
            let editedRow;
            Array.from(params.ref.current.getRowModels()).map(([GridRowId, GridRowModel]) => {
                if (GridRowId === id) {
                    editedRow = GridRowModel;
                }
            });
            if (editedRow.isNew) {
                //@ts-ignore
                params.setRows(params.rows.filter((row) => row.id !== id));
            }
        },
        handleSaveClick: (id) => () => {
            params.setRowModesModel(Object.assign(Object.assign({}, params.rowModesModel), { [id]: { mode: GridRowModes.View } }));
        },
        handleDeleteClick: (id) => () => {
            const fileterdRow = [];
            let keyToDelete = '';
            Array.from(params.ref.current.getRowModels()).map(([GridRowId, GridRowModel]) => {
                if (GridRowId !== id) {
                    fileterdRow.push(GridRowModel);
                }
                else {
                    keyToDelete = GridRowModel['key'];
                }
            });
            params.ref.current.setRows(fileterdRow);
            useFormGet(url(`/site/translation/delete/${keyToDelete}`)).execute();
        },
        handleRowUpdate: (newRow, oldRow) => {
            const updatedRow = Object.assign(Object.assign({}, oldRow), newRow);
            const fileterdRow = [];
            const fields = [];
            params.ref.current.getAllColumns().map((col) => {
                if (col.field !== 'key' && col.field !== 'action') {
                    fields.push({
                        lang: col.field,
                        translations: {}
                    });
                }
            });
            Array.from(params.ref.current.getRowModels()).map(([GridRowId, GridRowModel]) => {
                let item;
                if (GridRowId === oldRow.id) {
                    item = updatedRow;
                }
                else {
                    item = GridRowModel;
                }
                fields.forEach((element) => {
                    element.translations[item['key']] = item[element.lang];
                });
                fileterdRow.push(item);
            });
            updateRowExecute(JSON.stringify(fields));
            params.ref.current.setRows(fileterdRow);
            return updatedRow;
        }
    };
};
