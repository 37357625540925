/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";
const svgIcon = {
    defaultProps: {
        fontSize: "inherit",
    },
    styleOverrides: {
        fontSizeInherit: {
            fontSize: "inherit !important",
        },
        fontSizeSmall: {
            fontSize: `${pxToRem(20)} !important`,
        },
        fontSizeLarge: {
            fontSize: `${pxToRem(36)} !important`,
        },
    },
};
export default svgIcon;
