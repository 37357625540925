import { createSlice } from "@reduxjs/toolkit";
const translationInitialState = {
    selectedLang: 'fr_FR',
    onProcess: false,
    translations: [],
    allLanguages: []
};
export const translationSlice = createSlice({
    name: 'translation',
    initialState: translationInitialState,
    reducers: {
        setLanguage: (state, action) => {
            state.selectedLang = action.payload;
        },
        setTranslation: (state, action) => {
            state.translations = action.payload;
        },
        setAllLanguages: (state, action) => {
            state.allLanguages = action.payload;
        }
    }
});
export const { setLanguage, setTranslation, setAllLanguages } = translationSlice.actions;
export default translationSlice.reducer;
