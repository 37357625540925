import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
// Material Dashboard 2 PRO React TS Base Styles
import borders from "assets/theme/base/borders";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
function PaymentDetails() {
    const { borderWidth, borderColor } = borders;
    return (_jsxs(_Fragment, { children: [_jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Payment details" })), _jsxs(MDBox, Object.assign({ border: `${borderWidth[1]} solid ${borderColor}`, borderRadius: "lg", display: "flex", justifyContent: "space-between", alignItems: "center", p: 3, mt: 2 }, { children: [_jsx(MDBox, { component: "img", src: masterCardLogo, alt: "master card", width: "10%", mr: 2 }), _jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "****\u00A0\u00A0****\u00A0\u00A0****\u00A0\u00A07852" })), _jsx(MDBox, Object.assign({ ml: "auto", lineHeight: 0 }, { children: _jsx(Tooltip, Object.assign({ title: "We do not store card details", placement: "bottom" }, { children: _jsx(MDButton, Object.assign({ variant: "outlined", color: "secondary", size: "small", iconOnly: true, circular: true }, { children: _jsx(Icon, Object.assign({ sx: { cursor: "pointer" } }, { children: "priority_high" })) })) })) }))] }))] }));
}
export default PaymentDetails;
