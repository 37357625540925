import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography, } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { useEffect, useState } from "react";
import { url } from "services/ApiEndpoint";
import objIcon from "assets/images/icons/files/obj-48.png";
import videoIcon from "assets/images/icons/files/video-48.png";
import imageIcon from "assets/images/icons/files/image-48.png";
import MDTypography from "components/generic/MDTypography";
function getFileType(path) {
    var _a;
    // Extract the file extension from the path
    const extension = (_a = path.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    // Determine the file type based on the extension
    switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
        case "bmp":
        case "tiff":
        case "svg":
            return "image";
        case "glb":
            return "glb";
        case "mp4":
        case "avi":
        case "mov":
        case "wmv":
        case "flv":
            return "video";
        case "pdf":
            return "pdf";
        case "mp3":
        case "wav":
        case "aac":
        case "flac":
        case "ogg":
        case "m4a":
            return "music";
        default:
            return "unknown"; // If the extension is not recognized
    }
}
export default function ListMedia({ roomId }) {
    const { execute, result } = useFormGet(url("/room/media/get/" + roomId));
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        if (!result)
            return;
    }, [result]);
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleClickOpen = (file) => {
        setSelectedFile(file);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [result &&
                result.map((file, index) => (
                // <Grid item  xs={12} sm={6} md={4}>
                _jsxs(Card, Object.assign({ sx: { m: 2, display: "flex", alignItems: "center", height: 140, width: 140 } }, { children: [_jsx(Tooltip, Object.assign({ title: file.displayName, placement: "top", arrow: true }, { children: _jsx(CardMedia, { component: "img", sx: { width: 48, cursor: "pointer" }, image: file.type === "images" ? imageIcon : file.type === "videos" ? videoIcon : objIcon, 
                                // image={objIcon}
                                alt: file.displayName, onClick: () => handleClickOpen(file.src) }) })), _jsx(CardContent, Object.assign({ sx: {
                                flex: "1 0 auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            } }, { children: _jsx(MDTypography, Object.assign({ sx: { textOverflow: "ellipsis", overflow: "hidden", width: "100px" }, variant: "button", color: "text" }, { children: file.displayName })) }))] }), index)
                // </Grid>
                )), selectedFile && (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "file-preview-title", "aria-describedby": "file-preview-description" }, { children: [_jsx(DialogTitle, Object.assign({ id: "file-preview-title" }, { children: selectedFile })), _jsxs(DialogContent, { children: [getFileType(selectedFile) === "image" && (_jsx("img", { src: selectedFile, alt: selectedFile, style: { width: "100%" } })), getFileType(selectedFile) === "video" && (_jsxs("video", Object.assign({ controls: true, style: { width: "100%" } }, { children: [_jsx("source", { src: selectedFile, type: "video/mp4" }), "Your browser does not support the video tag."] }))), getFileType(selectedFile) === "glb" && (_jsx(Typography, Object.assign({ variant: "body1" }, { children: "Preview not available for this file type." })))] }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: handleClose }, { children: "Close" })) })] })))] })));
}
