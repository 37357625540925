import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";
// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// Register the components you need
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// ReportsBarChart configurations
import configs from "./configs";
function ReportsBarChart({ color = "dark", title, description = "", date, chart }) {
    const { data, options } = configs(chart.labels || [], chart.datasets || {});
    return (_jsx(Card, Object.assign({ sx: { height: "100%" } }, { children: _jsxs(MDBox, Object.assign({ padding: "1rem" }, { children: [useMemo(() => (_jsx(MDBox, Object.assign({ variant: "gradient", bgColor: color, borderRadius: "lg", coloredShadow: color, py: 2, pr: 0.5, mt: -5, height: "12.5rem" }, { children: _jsx(Bar, { data: data, options: options }) }))), [chart, color]), _jsxs(MDBox, Object.assign({ pt: 3, pb: 1, px: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6", textTransform: "capitalize" }, { children: title })), _jsx(MDTypography, Object.assign({ component: "div", variant: "button", color: "text", fontWeight: "light" }, { children: description })), _jsx(Divider, {}), _jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", color: "text", lineHeight: 1, sx: { mt: 0.15, mr: 0.5 } }, { children: _jsx(Icon, { children: "schedule" }) })), _jsx(MDTypography, Object.assign({ variant: "button", color: "text", fontWeight: "light" }, { children: date }))] }))] }))] })) })));
}
export default ReportsBarChart;
