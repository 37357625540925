import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import FormImage from "components/generic/form/FormImage";
import { options } from "../../type/TTemplateFormField";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import FormSelect from "components/generic/form/FormSelect";
import FormFileInput from "components/generic/form/FormFileInput";
import { useEffect, useState } from "react";
function TemplateInfo({ formData }) {
    const [opt, setOpt] = useState([]);
    useEffect(() => {
        const o = [];
        options.map((op) => {
            o.push({
                key: op,
                value: op,
            });
        });
        setOpt(o);
    }, []);
    return (_jsxs(MDBox, { children: [_jsx(MDBox, Object.assign({ lineHeight: 0 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: "A room is a template instance" })) })), _jsxs(MDBox, Object.assign({ mt: 1.625 }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: opt ? _jsx(FormSelect, { formik: formData, name: "type", options: opt }) : null })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputText, { label: "title", name: "title", formik: formData }) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputTextArea, { rows: 5, label: "description", name: "description", formik: formData }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(FormImage, { formik: formData, name: "thumbnail", label: "Thumbnail" }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(FormFileInput, { name: "model", label: "Model", formik: formData }) }))] }))] }))] }));
}
export default TemplateInfo;
