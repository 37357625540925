import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
// react-router-dom components
import { useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "contexts";
function PageLayout({ background = "default", children }) {
    const [, dispatch] = useMaterialUIController();
    const { pathname } = useLocation();
    useEffect(() => {
        setLayout(dispatch, "page");
    }, [pathname]);
    return (_jsx(MDBox, Object.assign({ width: "100vw", height: "100%", minHeight: "100vh", bgColor: background, sx: { overflowX: "hidden" } }, { children: children })));
}
export default PageLayout;
