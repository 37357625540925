import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import EditRoomForm from "components/features/room/EditRoomForm";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import RoomMedia from "components/features/room/RoomMedia";
import ModelLoader from "components/features/room/ModelLoader";
export default function RoomEditorV2() {
    const { roomRef } = useParams();
    const [selectedTab, setSelectedTab] = useState(1);
    const getContainerHeight = () => {
        const bodyHeight = window.innerHeight;
        const height = bodyHeight - 160;
        return height;
    };
    const { execute, result } = useFormGet(url(`/room/get/${roomRef}`));
    const [templateUrl, setTemplateUrl] = useState(null);
    const { execute: TemplateExecute, result: TemplateResult } = useFormGet(templateUrl);
    useEffect(() => {
        execute();
    }, []);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    useEffect(() => {
        if (result) {
            setTemplateUrl(url(`/scene-template/get/${result.template}`));
        }
    }, [result]);
    useEffect(() => {
        if (!templateUrl)
            return;
        TemplateExecute();
    }, [templateUrl]);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(Tabs, Object.assign({ variant: "fullWidth", value: selectedTab, onChange: handleTabChange }, { children: [_jsx(Tab, { label: "Settings" }), _jsx(Tab, { label: "Editor" }), _jsx(Tab, { label: "E-Commerce" }), _jsx(Tab, { label: "Traduction" }), _jsx(Tab, { label: "Media" })] })), _jsxs(Stack, Object.assign({ p: 2, style: { height: getContainerHeight() + "px" } }, { children: [_jsx(Typography, Object.assign({ component: "div", hidden: selectedTab !== 0 }, { children: result ? _jsx(EditRoomForm, Object.assign({}, result, { roomId: result._id })) : null })), _jsx(Typography, Object.assign({ component: "div", hidden: selectedTab !== 1, style: { height: "100%", width: "100%" } }, { children: TemplateResult ? _jsx(ModelLoader, { roomId: result._id, template: TemplateResult }) : null })), _jsx(Typography, Object.assign({ component: "div", hidden: selectedTab !== 2 }, { children: _jsx(_Fragment, {}) })), _jsx(Typography, Object.assign({ component: "div", hidden: selectedTab !== 3 }, { children: _jsx(_Fragment, {}) })), _jsx(Typography, Object.assign({ component: "div", hidden: selectedTab !== 4 }, { children: _jsx(RoomMedia, { roomId: roomRef }) }))] }))] }));
}
