var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef } from "react";
// Custom styles for MDButton
import MDButtonRoot from "components/generic/MDButton/MDButtonRoot";
// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "contexts";
const MDButton = forwardRef((_a, ref) => {
    var { color = "white", variant = "contained", size = "medium", circular = false, iconOnly = false, children } = _a, rest = __rest(_a, ["color", "variant", "size", "circular", "iconOnly", "children"]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (_jsx(MDButtonRoot, Object.assign({}, rest, { ref: ref, color: "primary", variant: variant === "gradient" ? "contained" : variant, size: size, ownerState: { color, variant, size, circular, iconOnly, darkMode } }, { children: children })));
});
export default MDButton;
