import { DoubleSide, Mesh, MeshStandardMaterial, Scene } from "three";
import { BaseScene } from "./BaseScene";
import { RigidBodyType } from "@dimforge/rapier3d";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export class NativeScene extends BaseScene {
    get sceneType() {
        return "Native";
    }
    constructor(_filePath, _mode) {
        super(_mode);
        this._filePath = _filePath;
        // private _physics = new Physics();
        this._glbDecoded = false;
        this._scene = new Scene();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        dracoLoader.setDecoderConfig({});
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader).load(this._filePath, this.gltfLoader.bind(this));
    }
    gltfLoader(gltf) {
        const S = gltf.scene;
        this._scene.add(S);
        S.position.set(0, 0, 0);
        this._physics.initPhysics().then(() => {
            S.traverse((child) => {
                if (child instanceof Mesh) {
                    if (child.material instanceof MeshStandardMaterial) {
                        child.receiveShadow = true;
                        child.material.side = DoubleSide;
                    }
                    this._physics.addPhysics(child, RigidBodyType.Fixed, "trimesh");
                }
            });
            this._glbDecoded = true;
        });
    }
    getScene() {
        return new Promise((resolve, _) => {
            const interval = setInterval(() => {
                onLoadModel();
            }, 300);
            const onLoadModel = () => {
                if (this._glbDecoded) {
                    this.addCameraToScene();
                    this.addWebGLRenderer();
                    this.addLightsToScene();
                    this.addOrbitControlToScene();
                    this.addTransformControlToScene();
                    this.addPlayerController();
                    this.render();
                    this.animation();
                    //this.loadPlayer();
                    clearInterval(interval);
                    resolve(this._scene);
                }
            };
        });
    }
    render() {
        if (!this._scene)
            throw new Error("Scene not found");
        if (!this._camera)
            throw new Error("Camera not found");
        if (!this._webGLRenderer)
            throw new Error("render not found");
        this._webGLRenderer.render(this._scene, this._camera);
    }
}
