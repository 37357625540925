import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// NewProduct page components
import FormField from "page/ecommerce/products/new-product/components/FormField";
function Socials() {
    return (_jsxs(MDBox, { children: [_jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "bold" }, { children: "Socials" })), _jsx(MDBox, Object.assign({ mt: 2 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormField, { type: "text", label: "Shoppify Handle" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormField, { type: "text", label: "Facebook Account" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormField, { type: "text", label: "Instagram Account" }) }))] })) }))] }));
}
export default Socials;
