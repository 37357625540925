import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
function ProductCell({ image, name, orders }) {
    return (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center", pr: 2 }, { children: [_jsx(MDBox, Object.assign({ mr: 2 }, { children: _jsx(MDAvatar, { src: image, alt: name }) })), _jsxs(MDBox, Object.assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "medium" }, { children: name })), _jsxs(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "secondary" }, { children: [_jsx(MDTypography, Object.assign({ component: "span", variant: "button", fontWeight: "regular", color: "success" }, { children: orders })), " ", "orders"] }))] }))] })));
}
export default ProductCell;
