import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef } from "react";
// Dropzone components
import Dropzone from "dropzone";
// Dropzone styles
import "dropzone/dist/dropzone.css";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/generic/MDDropzone/MDDropzoneRoot";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
function MDDropzone({ options }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dropzoneRef = useRef(null);
    useEffect(() => {
        Dropzone.autoDiscover = false;
        function createDropzone() {
            return new Dropzone(dropzoneRef.current, Object.assign({}, options));
        }
        function removeDropzone() {
            if (Dropzone.instances.length > 0)
                Dropzone.instances.forEach((dz) => dz.destroy());
        }
        createDropzone();
        return () => removeDropzone();
    }, [options]);
    return (_jsx(MDDropzoneRoot, Object.assign({ action: "/file-upload", ref: dropzoneRef, className: "form-control dropzone", ownerState: { darkMode } }, { children: _jsx(MDBox, Object.assign({ className: "fallback", bgColor: "transparent" }, { children: _jsx("input", { name: "file", type: "file", multiple: true }) })) })));
}
export default MDDropzone;
