import { LEFT_CLICK_IN_SCENE_MODEL, YOUNIVR_OBJECT_CLICKED } from "threejs/state/const";
export class PresentationInteractionService {
    constructor(_baseScene) {
        this._baseScene = _baseScene;
        this._baseScene.renderer.domElement.addEventListener(LEFT_CLICK_IN_SCENE_MODEL, (e) => {
            if (this._baseScene.sceneInteractionDetail.customItem) {
                const event = new CustomEvent(YOUNIVR_OBJECT_CLICKED, {
                    detail: this._baseScene.sceneInteractionDetail.object,
                });
                document.dispatchEvent(event);
            }
        });
    }
}
