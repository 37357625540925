import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import TranslationGrid from "components/features/translation/component/TranslationGrid";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
export default function Translation() {
    const [keys, setKeys] = useState([]);
    const [values, setValues] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const transformData = (data) => {
        let outputString = "key";
        const keys = [{ field: "key", headerName: "Key", width: 180, editable: false }];
        const values = [];
        let maxLengthIndex = 0;
        let maxLength = 0;
        const langs = ["key"];
        data.forEach((items, index) => {
            outputString += "," + items.lang;
            if (Object.keys(items.translations).length > maxLength) {
                maxLengthIndex = index;
                maxLength = Object.keys(items.translations).length;
            }
            keys.push({
                field: items.lang,
                headerName: items.lang,
                editable: true,
                width: 180,
            });
            langs.push(items.lang);
        });
        console.log(maxLengthIndex, maxLength);
        //setKeys(keys)
        setColumns(keys);
        const row = [];
        Object.keys(data[maxLengthIndex].translations).forEach((element, k) => {
            outputString += "\n";
            outputString += element;
            const innerElement = [element];
            data.forEach((iteration) => {
                var _a, _b;
                outputString += "," + ((_a = iteration.translations[element]) !== null && _a !== void 0 ? _a : "");
                innerElement.push((_b = iteration.translations[element]) !== null && _b !== void 0 ? _b : "");
            });
            const item = {};
            langs.map((lang, index) => {
                item[lang] = innerElement[index];
            });
            item["id"] = k;
            row.push(item);
        });
        setValues(values);
        setRows(row);
        return outputString;
    };
    const { result, execute } = useFormGet(url("/site/translation/all"));
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        if (result) {
            transformData(result);
        }
    }, [result]);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), !rows.length ? null : _jsx(TranslationGrid, { columns: columns, rows: rows })] }));
}
