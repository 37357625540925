import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// formik components
import { useFormik } from "formik";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
// NewUser page components
import RoomInfo from "components/features/room/new-room/RoomInfo";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
function getSteps() {
    return ["Room Properties", "Visibilty"];
}
function NewRoomForm() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const { execute, globalErrorMessage, loading, errors, status } = useFormPost(url("/room/create"));
    useEffect(() => {
        if (!status)
            return;
        if (status === 400) {
            ApiFormErrorResolver(formData, errors);
        }
        else if (status === 200) {
            window.location.href = "/environnements/rooms";
        }
    }, [status]);
    const handleBack = () => setActiveStep(activeStep - 1);
    const submitForm = async (values) => {
        const form = new FormData();
        form.append("title", values.title);
        form.append("description", values.description);
        form.append("template", values.template);
        if (values.thumbnail)
            form.append("thumbnail", values.thumbnail);
        await execute(form);
    };
    const initValues = {
        description: "",
        template: "",
        title: "",
        thumbnail: "",
    };
    const formData = useFormik({
        initialValues: initValues,
        onSubmit: async (value) => await submitForm(value),
    });
    return (_jsx(MDBox, Object.assign({ py: 3, mb: 20, height: "65vh" }, { children: _jsx(Grid, Object.assign({ container: true, justifyContent: "center", alignItems: "center", sx: { height: "100%", mt: 8 } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 8 }, { children: _jsxs(Card, Object.assign({ sx: { height: "100%" } }, { children: [_jsx(MDBox, Object.assign({ mx: 2, mt: -3 }, { children: _jsx(Stepper, Object.assign({ activeStep: activeStep, alternativeLabel: true }, { children: steps.map((label) => (_jsx(Step, { children: _jsx(StepLabel, { children: label }) }, label))) })) })), _jsx(MDBox, Object.assign({ p: 3 }, { children: _jsx(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: _jsxs(MDBox, { children: [_jsx(RoomInfo, { formData: formData }), _jsxs(MDBox, Object.assign({ mt: 2, width: "100%", display: "flex", justifyContent: "space-between" }, { children: [activeStep === 0 ? (_jsx(MDBox, {})) : (_jsx(MDButton, Object.assign({ variant: "gradient", color: "light", onClick: handleBack }, { children: "back" }))), globalErrorMessage, _jsx(MDButton, Object.assign({ disabled: loading, type: "submit", variant: "gradient", color: "dark" }, { children: isLastStep ? "create" : "next" }))] }))] }) })) }))] })) })) })) })));
}
export default NewRoomForm;
