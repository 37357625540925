import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useEffect, useState } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDPagination from "components/generic/MDPagination";
// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "components/generic/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/generic/Tables/DataTable/DataTableBodyCell";
function DataTable({ entriesPerPage = 2, canSearch = false, showTotalEntries = true, table, pagination = { variant: "gradient", color: "info" }, isSorted = true, noEndBorder = false, totalEntries, goToPageCb, currentPage = 0, }) {
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 2 }, pageCount: 3 }, useGlobalFilter, useSortBy, usePagination);
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, page, canPreviousPage, canNextPage, gotoPage, nextPage, previousPage, setPageSize, setGlobalFilter, state: { pageIndex, pageSize, globalFilter }, } = tableInstance;
    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(totalEntries), [totalEntries]);
    const pageOptions = Array.from({ length: Math.ceil(totalEntries / entriesPerPage) }, (_, index) => index);
    // Render the paginations
    const renderPagination = pageOptions.map((option) => (_jsx(MDPagination, Object.assign({ item: true, onClick: () => {
            goToPageCb(Number(option));
            gotoPage(Number(option));
        }, active: currentPage === option }, { children: option + 1 }), option)));
    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) => value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));
    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);
    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));
    // Search input value state
    const [search, setSearch] = useState(globalFilter);
    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);
    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;
        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        }
        else if (isSorted) {
            sortedValue = "none";
        }
        else {
            sortedValue = false;
        }
        return sortedValue;
    };
    // Setting the entries starting point
    const entriesStart = currentPage * entriesPerPage + 1;
    // Setting the entries ending point
    let entriesEnd = (currentPage + 1) * entriesPerPage;
    if (entriesEnd > totalEntries) {
        entriesEnd = totalEntries;
    }
    return (_jsxs(TableContainer, Object.assign({ sx: { boxShadow: "none" } }, { children: [entriesPerPage || canSearch ? (_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", p: 3 }, { children: canSearch && (_jsx(MDBox, Object.assign({ width: "12rem", ml: "auto" }, { children: _jsx(MDInput, { placeholder: "Search...", value: search, size: "small", fullWidth: true, onChange: ({ currentTarget }) => {
                            setSearch(search);
                            onSearchChange(currentTarget.value);
                        } }) }))) }))) : null, _jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(MDBox, Object.assign({ component: "thead" }, { children: headerGroups.map((headerGroup, key) => (_jsx(TableRow, Object.assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map((column, key) => (_jsx(DataTableHeadCell, Object.assign({}, column.getHeaderProps(isSorted && column.getSortByToggleProps()), { width: column.width ? column.width : "auto", align: column.align ? column.align : "left", sorted: setSortedValue(column) }, { children: column.render("Header") }), key))) }), key))) })), _jsx(TableBody, Object.assign({}, getTableBodyProps(), { children: page.map((row, key) => {
                            prepareRow(row);
                            return (_jsx(TableRow, Object.assign({}, row.getRowProps(), { children: row.cells.map((cell, key) => (_jsx(DataTableBodyCell, Object.assign({ noBorder: noEndBorder && rows.length - 1 === key, align: cell.column.align ? cell.column.align : "left" }, cell.getCellProps(), { children: cell.render("Cell") }), key))) }), key));
                        }) }))] })), _jsxs(MDBox, Object.assign({ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", alignItems: { xs: "flex-start", sm: "center" }, p: !showTotalEntries && pageOptions.length === 1 ? 0 : 3 }, { children: [showTotalEntries && (_jsx(MDBox, Object.assign({ mb: { xs: 3, sm: 0 } }, { children: _jsxs(MDTypography, Object.assign({ variant: "button", color: "secondary", fontWeight: "regular" }, { children: ["Showing ", entriesStart, " to ", entriesEnd, " of ", totalEntries !== null && totalEntries !== void 0 ? totalEntries : rows.length, " entries"] })) }))), pageOptions.length > 0 && (_jsxs(MDPagination, Object.assign({ variant: pagination.variant ? pagination.variant : "gradient", color: pagination.color ? pagination.color : "info" }, { children: [canPreviousPage && (_jsx(MDPagination, Object.assign({ item: true, onClick: () => previousPage() }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "chevron_left" })) }))), renderPagination.length > 6 ? (_jsx(MDBox, Object.assign({ width: "5rem", mx: 1 }, { children: _jsx(MDInput, { inputProps: { type: "number", min: 1, max: customizedPageOptions.length }, value: customizedPageOptions[pageIndex], onChange: (event) => {
                                        handleInputPagination(event);
                                        handleInputPaginationValue(event);
                                    } }) }))) : (renderPagination), canNextPage && (_jsx(MDPagination, Object.assign({ item: true, onClick: () => nextPage() }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "chevron_right" })) })))] })))] }))] })));
}
export default DataTable;
