import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Features Components
import ProfileImage from "components/features/settings/component/ProfileImage";
function Header({ userInfo }) {
    const [visible, setVisible] = useState(true);
    const handleSetVisible = () => setVisible(!visible);
    return (_jsx(Card, Object.assign({ id: "profile" }, { children: _jsx(MDBox, Object.assign({ p: 2 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3, alignItems: "center" }, { children: [userInfo ? (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(ProfileImage, { profileImage: userInfo.profileImageUrl }) }))) : null, _jsx(Grid, Object.assign({ item: true }, { children: _jsxs(MDBox, Object.assign({ height: "100%", mt: 0.5, lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "medium" }, { children: userInfo.firstname })), _jsx(MDTypography, Object.assign({ variant: "button", color: "text", fontWeight: "medium" }, { children: "CTO / Co-Founder" }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3, sx: { ml: "auto" } }, { children: _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: { md: "flex-end" }, alignItems: "center", lineHeight: 1 }, { children: [_jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "regular" }, { children: ["Switch to ", visible ? "invisible" : "visible"] })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(Switch, { checked: visible, onChange: handleSetVisible }) }))] })) }))] })) })) })));
}
export default Header;
