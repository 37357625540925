import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import { useFormik } from "formik";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { useShowConfirmation } from "hooks/useShowConfirmation";
import FormPassword from "components/generic/form/FormPassword";
import { useEffect } from "react";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
function ChangePassword() {
    const passwordRequirements = [
        "One special characters",
        "Min 6 characters",
        "One number (2 are recommended)",
        "Change it often",
    ];
    const renderPasswordRequirements = passwordRequirements.map((item, key) => {
        const itemKey = `element-${key}`;
        return (_jsx(MDBox, Object.assign({ component: "li", color: "text", fontSize: "1.25rem", lineHeight: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", color: "text", fontWeight: "regular", verticalAlign: "middle" }, { children: item })) }), itemKey));
    });
    const { execute, result, errors, globalErrorMessage, status } = useFormPost(url("/user/update-password"));
    const { Message, setShow, setDescription, setTitle } = useShowConfirmation();
    const formData = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        onSubmit: async (submitedValue) => {
            execute(JSON.stringify(submitedValue));
        },
    });
    useEffect(() => {
        if (!status)
            return;
        if (status === 400) {
            ApiFormErrorResolver(formData, errors);
        }
        else {
            console.log(result);
            setTitle("Password Update");
            setDescription("Votre Mot de passe est bien était modifié");
            setShow(true);
            formData.resetForm();
        }
    }, [status]);
    return (_jsxs(Card, Object.assign({ id: "change-password" }, { children: [_jsx(MDBox, Object.assign({ p: 3 }, { children: _jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Change Password" })) })), _jsxs(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true, pb: 3, px: 3 }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormPassword, { name: "currentPassword", label: "Current Password", formik: formData, required: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormPassword, { name: "newPassword", label: "New Password", formik: formData, required: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormPassword, { name: "confirmPassword", label: "Confirm New Password", formik: formData, required: true }) }))] })), _jsx(MDBox, Object.assign({ mt: 6, mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Password requirements" })) })), _jsx(MDBox, Object.assign({ mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "body2", color: "text" }, { children: "Please follow this guide for a strong password" })) })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "flex-end", flexWrap: "wrap" }, { children: [_jsx(MDBox, Object.assign({ component: "ul", m: 0, pl: 3.25, mb: { xs: 8, sm: 0 } }, { children: renderPasswordRequirements })), _jsx(MDBox, Object.assign({ ml: "auto" }, { children: _jsx(MDButton, Object.assign({ variant: "gradient", color: "dark", size: "small", type: "submit" }, { children: "update password" })) }))] }))] })), _jsx(Message, {})] })));
}
export default ChangePassword;
