import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { useEffect, useState } from "react";
import FormImage from "components/generic/form/FormImage";
import FormSelect from "components/generic/form/FormSelect";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
function RoomInfo({ formData }) {
    const { result, execute } = useFormGet(url("/scene-template/all"));
    const [templateOptions, setTemplateOptions] = useState([]);
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        if (result) {
            const m = [];
            result.map((r) => {
                m.push({
                    key: r._id,
                    value: r.title,
                });
            });
            setTemplateOptions(m);
        }
    }, [result]);
    return (_jsxs(MDBox, { children: [_jsx(MDBox, Object.assign({ lineHeight: 0 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: "A room is a template instance" })) })), _jsxs(MDBox, Object.assign({ mt: 1.625 }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: templateOptions ? (_jsx(FormSelect, { formik: formData, name: "template", options: templateOptions, label: "Template", required: true })) : null })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputText, { formik: formData, name: "title", label: "Title", required: true }) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputTextArea, { rows: 5, formik: formData, name: "description", label: "Description", required: true }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(FormImage, { formik: formData, name: "thumbnail" }) }))] }))] }))] }));
}
export default RoomInfo;
