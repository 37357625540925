import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import MDBox from "../MDBox";
import { getIn } from "formik";
import MDInput from "../MDInput";
import { useDropzone } from "react-dropzone";
import MDTypography from "../MDTypography";
import { url } from "services/ApiEndpoint";
const FormImage = (props) => {
    var _a;
    const [previewImage, setPreviewImage] = useState(null);
    const onDropCallback = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            props.formik.setFieldValue(props.name, file);
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewImage(fileReader.result);
            };
            fileReader.readAsDataURL(file);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropCallback });
    const value = getIn(props.formik.values, props.name);
    // const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    const error = getIn(props.formik.errors, props.name);
    useEffect(() => {
        if (value) {
            setPreviewImage(url(`/scene-data/${value}`).value);
        }
    }, []);
    const styles = {
        dropzone: {
            border: "2px dashed #cacaca",
            padding: "20px",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            width: "100%",
            display: "flex",
        },
        error: {
            color: "red",
            marginTop: "0.5rem",
        },
    };
    return (_jsxs(MDBox, Object.assign({ mt: 0.75 }, getRootProps(), { style: styles.dropzone }, { children: [isDragActive ? (_jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "#cacaca", fontWeight: "regular" }, { children: "Drop the files here ..." }))) : (_jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "#cacaca", fontWeight: "regular" }, { children: (_a = props.label) !== null && _a !== void 0 ? _a : "Drag 'n' drop some files here, or click to select files" }))), _jsx(MDInput, Object.assign({ variant: "outlined", fullWidth: true, 
                // value={value}
                error: error ? true : false, type: "file" }, props, getInputProps())), error ? (_jsx(MDBox, Object.assign({ style: {
                    marginTop: 15,
                } }, { children: _jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "error", fontWeight: "regular" }, { children: error.toString() })) }))) : null, previewImage && (_jsx("div", Object.assign({ style: { marginTop: 10 } }, { children: _jsx("img", { src: previewImage, alt: "Uploaded", style: { maxWidth: "100%", maxHeight: 150, objectFit: "contain" } }) })))] })));
};
export default FormImage;
