import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
export default function RoomTemplate(props) {
    var _a, _b;
    const { result, execute } = useFormGet(url("/scene-template/all"));
    useEffect(() => {
        if (!result)
            execute();
    }, [result]);
    return result ? (_jsxs(FormControl, Object.assign({ fullWidth: true, style: {
            marginBottom: 15,
            width: "100%",
        } }, { children: [_jsx(InputLabel, Object.assign({ id: props.name, required: props.required }, { children: (_a = props.label) !== null && _a !== void 0 ? _a : props.name })), _jsx(Select, Object.assign({ labelId: props.name, id: props.name, value: props.formik.values[props.name], label: (_b = props.label) !== null && _b !== void 0 ? _b : props.name, name: props.name, onChange: props.formik.handleChange, required: props.required }, { children: result.map((template, key) => {
                    return (_jsx(MenuItem, Object.assign({ value: template._id }, { children: template.title }), key));
                }) }))] }))) : null;
}
