import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { Suspense, useEffect, useState } from "react";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import UpComingEvents from "components/features/event/UpComingEvents";
import PastEvents from "components/features/event/PastEvents";
import GLBScroller from "components/generic/Three/GLBScroller/GLBScroller";
import LoadingSpinner from "components/generic/Loading/LoadingSpinner";
export default function Home() {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    const { execute: allAvatarQuery, result: allAvatars } = useFormGet(url("/admin/avatar/all"));
    //TODO to get from query - Add Avatar Document db  and add metadata like id , name , description
    //TODO add IDLE animation
    //TODO Review model path  name same in db and use id instead
    //TODO Add function param to scroller Component -> set avatar API call
    // const modelUrls: any[] = [
    //   // "/uploads/user/avatar/asian_male_animated.glb",
    //   // "/uploads/user/avatar/asian_female_animated.glb",
    // ];
    const [modelUrls, setmodelUrls] = useState([]);
    const [avatarData, setAvatarData] = useState([]);
    useEffect(() => {
        userInfoQuery();
        allAvatarQuery();
    }, []);
    useEffect(() => {
        if (!allAvatars)
            return;
        const v = [];
        allAvatars.forEach((element) => {
            v.push(element.path);
        });
        setmodelUrls(v);
        setAvatarData(allAvatars);
    }, [allAvatars]);
    return userInfo ? (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 8, lg: 8 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(UpComingEvents, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(PastEvents, {}) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4, lg: 4 }, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(LoadingSpinner, { progress: 0 }) }, { children: avatarData.length > 0 ? _jsx(GLBScroller, { avatarDatas: avatarData }) : null })) }))] }))] })) : null;
}
