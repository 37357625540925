import { Group, Matrix3, Mesh, Raycaster, Vector2, Vector3 } from "three";
import { SceneInteractionDetail } from "../../model/SceneInteractionDetail";
import { ObjectMesh } from "../../sceneItem/ObjectMesh";
import { LEFT_CLICK_IN_SCENE_MODEL, RIGHT_CLICK_IN_SCENE_MODEL } from "../../state/const";
import { TagMesh } from "threejs/sceneItem/TagMesh";
import { LiveStreamMesh } from "threejs/sceneItem/LiveStreamMesh";
export class PlayerControllerInput {
    constructor(_baseScene) {
        this._baseScene = _baseScene;
        this.leftClick = 0;
        this.rightClick = 2;
        this._clickDuration = 0;
        // public keysToCheck = [37, 38, 39, 40]
        this.intersectedObject = null; // Track currently hovered object
        this._rayCaster = new Raycaster();
        this.executeAction = (e) => {
            let fastClick = true;
            if (e.type === "mousedown") {
                this._clickDuration = Date.now();
                return;
            }
            const diff = Date.now() - this._clickDuration;
            if (diff > 200)
                fastClick = false;
            if (fastClick) {
                if (e.button === this.leftClick) {
                    this.leftClickAction(e);
                }
                else if (e.button === this.rightClick) {
                    this.rightClickAction(e);
                }
            }
        };
        this.leftClickAction = (e) => {
            const info = this.getClickedInfo(e);
            const clickedItemInfo = this.detectIntersectObjectInfoAlgo(info.intersected3DObjects);
            if (clickedItemInfo.customItem) {
                e.stopPropagation();
            }
            this._baseScene.sceneInteractionDetail = clickedItemInfo;
            const myEvent = new CustomEvent(LEFT_CLICK_IN_SCENE_MODEL, {
                detail: info,
            });
            this._baseScene.renderer.domElement.dispatchEvent(myEvent);
        };
        this.getMouseVector2 = (event) => {
            const mousePointer = new Vector2();
            //@ts-expect-error
            const rect = event.target.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            mousePointer.x = (x / this._baseScene.renderer.domElement.clientWidth) * 2 - 1;
            mousePointer.y = -(y / this._baseScene.renderer.domElement.clientHeight) * 2 + 1;
            return mousePointer;
        };
        this.findIntersectionsFrom2D = (point2D) => {
            const rayCaster = new Raycaster();
            rayCaster.setFromCamera(point2D, this._baseScene.camera);
            const intersections = rayCaster.intersectObjects(this._baseScene.scene.children, true);
            return intersections;
        };
        this.getClickedInfo = (e) => {
            const point2D = this.getMouseVector2(e);
            const intersections = this.findIntersectionsFrom2D(point2D);
            return {
                clicked2D: point2D,
                intersected3DObjects: intersections,
                clickedTarget2D: e.target,
            };
        };
        this.rightClickAction = (e) => {
            const clickedInfo = this.getClickedInfo(e);
            const clickedItemInfo = this.detectIntersectObjectInfoAlgo(clickedInfo.intersected3DObjects);
            this._baseScene.sceneInteractionDetail = clickedItemInfo;
            const myEvent = new CustomEvent(RIGHT_CLICK_IN_SCENE_MODEL);
            this._baseScene.renderer.domElement.dispatchEvent(myEvent);
        };
        this.findParentByType = (object, type) => {
            const isInstanceOfAny = (obj, typeArray) => {
                return typeArray.some((t) => obj instanceof t);
            };
            if (!object.parent || isInstanceOfAny(object, type)) {
                // If there's no parent, or the object itself is of the desired type, return it
                return isInstanceOfAny(object, type) ? object : null;
            }
            else {
                // Otherwise, continue searching up the hierarchy
                return this.findParentByType(object.parent, type);
            }
        };
        this.detectIntersectObjectInfoAlgo = (intersections) => {
            var _a, _b, _c, _d;
            const clickedObject = new SceneInteractionDetail();
            let foundCustomItem = null;
            let foundMeshItem = null;
            for (let index = 0; index < intersections.length; index++) {
                const element = intersections[index];
                if (element.object.type === "ImageMesh" ||
                    element.object.type === "VideoMesh" ||
                    element.object.type === "ObjectMesh" ||
                    element.object.type === "TagMesh" ||
                    element.object.type === "LiveStreamMesh") {
                    foundCustomItem = element;
                    break;
                }
                if (element.object.type === "Mesh") {
                    const foundParent = this.findParentByType(element.object, [
                        ObjectMesh,
                        TagMesh,
                        LiveStreamMesh,
                    ]);
                    if (foundParent) {
                        foundCustomItem = foundParent;
                        break;
                    }
                }
                if (element.object.type === "Mesh" && !foundMeshItem) {
                    foundMeshItem = element;
                }
            }
            if (foundCustomItem) {
                if (foundCustomItem.isGroup) {
                    clickedObject.position = foundCustomItem.position;
                    clickedObject.object = foundCustomItem;
                    const normalMatrix = new Matrix3().getNormalMatrix(foundCustomItem.normalMatrix);
                    const newNormal = new Vector3().clone().applyMatrix3(normalMatrix).normalize();
                    clickedObject.normal = newNormal;
                    if (clickedObject.object instanceof TagMesh) {
                        clickedObject.position = clickedObject.object.circle.position;
                    }
                }
                else {
                    clickedObject.position = foundCustomItem.point;
                    clickedObject.object = foundCustomItem.object;
                    clickedObject.normal = (_b = (_a = foundCustomItem.face) === null || _a === void 0 ? void 0 : _a.normal) !== null && _b !== void 0 ? _b : foundCustomItem.object.up;
                }
                clickedObject.customItem = true;
            }
            else if (foundMeshItem) {
                clickedObject.position = foundMeshItem.point;
                clickedObject.object = foundMeshItem.object;
                clickedObject.normal = (_d = (_c = foundMeshItem.face) === null || _c === void 0 ? void 0 : _c.normal) !== null && _d !== void 0 ? _d : foundCustomItem.object.up;
                clickedObject.customItem = false;
            }
            // if (clickedObject.object) clickedObject.normal = clickedObject.object.up;
            return clickedObject;
        };
        this._Init();
    }
    _Init() {
        this._keys = {
            forward: false,
            backward: false,
            left: false,
            right: false,
            space: false,
            shift: false,
        };
        document.addEventListener("keydown", (e) => this._onKeyDown(e), false);
        document.addEventListener("keyup", (e) => this._onKeyUp(e), false);
        this._baseScene.renderer.domElement.addEventListener("mouseup", (e) => this.executeAction(e), false);
        this._baseScene.renderer.domElement.addEventListener("mousedown", (e) => this.executeAction(e), false);
        this._baseScene.renderer.domElement.addEventListener("mousemove", (e) => this._onMouseHover(e), false);
    }
    _onKeyDown(event) {
        switch (event.keyCode) {
            case 38: // w
                this._keys.forward = true;
                break;
            case 37: // a
                this._keys.left = true;
                break;
            case 40: // s
                this._keys.backward = true;
                break;
            case 39: // d
                this._keys.right = true;
                break;
            case 32: // SPACE
                this._keys.space = true;
                break;
            case 16: // SHIFT
                this._keys.shift = true;
                break;
        }
    }
    _onKeyUp(event) {
        switch (event.keyCode) {
            case 38: // w
                this._keys.forward = false;
                break;
            case 37: // a
                this._keys.left = false;
                break;
            case 40: // s
                this._keys.backward = false;
                break;
            case 39: // d
                this._keys.right = false;
                break;
            case 32: // SPACE
                this._keys.space = false;
                break;
            case 16: // SHIFT
                this._keys.shift = false;
                break;
        }
    }
    keys() {
        return this._keys;
    }
    /// ON MOUSE HOVER
    _onMouseHover(e) {
        var _a;
        const mousePoint = this.getMouseVector2(e);
        this._rayCaster.setFromCamera(mousePoint, this._baseScene.camera);
        const data = this._baseScene.scene.getObjectByName("additionalData");
        if (data) {
            const intersections = this._rayCaster.intersectObjects(data.children);
            if (intersections.length > 0) {
                let foundCustomItem = null;
                for (let index = 0; index < intersections.length; index++) {
                    const element = intersections[index];
                    if (element.object.type === "ImageMesh" ||
                        element.object.type === "VideoMesh" ||
                        element.object.type === "ObjectMesh" ||
                        element.object.type === "TagMesh" ||
                        element.object.type === "LiveStreamMesh") {
                        foundCustomItem = element;
                        break;
                    }
                    if (element.object.type === "Mesh") {
                        const foundParent = this.findParentByType(element.object, [
                            ObjectMesh,
                            TagMesh,
                            LiveStreamMesh,
                        ]);
                        if (foundParent) {
                            foundCustomItem = foundParent;
                            break;
                        }
                    }
                }
                if (foundCustomItem) {
                    const checkObject = (_a = foundCustomItem.object) !== null && _a !== void 0 ? _a : foundCustomItem;
                    if (this.intersectedObject && this.intersectedObject.uuid === checkObject.uuid) {
                        return;
                    }
                    if (checkObject instanceof Mesh) {
                        const material = checkObject.material;
                        material.emissive.setHex(0xffffff); // Reset to no emissive
                        material.emissiveIntensity = 0.2; // Reset to no emissive
                    }
                    else if (checkObject instanceof Group) {
                        checkObject.traverse((object) => {
                            if (object instanceof Mesh && object.material) {
                                object.material.emissive.setHex(0xffffff); // Set emissive color
                                object.material.emissiveIntensity = 0.2;
                            }
                        });
                    }
                    this.intersectedObject = checkObject; // Clear the currently hovered object
                }
            }
            else if (this.intersectedObject) {
                // Reset the emissive color of the previously hovered object when no longer hovered
                if (this.intersectedObject instanceof Mesh) {
                    const material = this.intersectedObject.material;
                    material.emissive.setHex(0x000000); // Reset to no emissive
                    material.emissiveIntensity = 1; // Reset to no emissive
                }
                else if (this.intersectedObject instanceof Group) {
                    this.intersectedObject.traverse((object) => {
                        if (object instanceof Mesh && object.material) {
                            object.material.emissive.setHex(0x000000); // Set emissive color
                            object.material.emissiveIntensity = 1;
                        }
                    });
                }
                this.intersectedObject = null; // Clear the currently hovered object
            }
        }
    }
}
