import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
const style = {
    position: 'absolute',
    marginBottom: '20%',
    left: '35%',
    // transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4
};
export default function AddNewUserModel(props) {
    const handleClose = () => {
        console.log('Model closed');
        props.setModelOpen(false);
    };
    return (_jsx("div", { children: _jsx(Modal, Object.assign({ open: props.modelOpen, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", style: {
                overflow: "auto"
            } }, { children: 
            //@ts-ignore
            _jsx(Box, Object.assign({ sx: style, overflow: "scroll" }, { children: props.children })) })) }));
}
