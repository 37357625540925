import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Link } from "@mui/material";
import { PresentationControls, Stage, useGLTF } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import LoadingSpinner from "components/generic/Loading/LoadingSpinner";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import { useFormGet } from "hooks/useFormGet";
import { Suspense, useEffect } from "react";
import { url } from "services/ApiEndpoint";
const CameraController = () => {
    const { camera } = useThree();
    useEffect(() => {
        camera.position.set(0, 0, 1.6);
        camera.updateProjectionMatrix();
    }, [camera]);
    return null;
};
const AvatarModel = ({ modelUrl }) => {
    const { scene } = useGLTF(url(`/admin/avatar/model/${modelUrl}`).value);
    return (_jsxs(Canvas, Object.assign({ style: { height: "400px" } }, { children: [_jsx(CameraController, {}), _jsx("ambientLight", { intensity: 1.0 }), _jsx("pointLight", { position: [10, 10, 10], intensity: 0.8 }), _jsx("directionalLight", { position: [5, 5, 5], intensity: 1, castShadow: true, "shadow-mapSize-width": 1024, "shadow-mapSize-height": 1024, "shadow-camera-far": 50, "shadow-camera-left": -10, "shadow-camera-right": 10, "shadow-camera-top": 10, "shadow-camera-bottom": -10 }), _jsx(PresentationControls, Object.assign({ speed: 1.5, global: true, zoom: 2, polar: [-0.1, Math.PI / 4] }, { children: _jsx(Stage, Object.assign({ environment: null, adjustCamera: false }, { children: _jsx("primitive", { object: scene }) })) }))] })));
};
const DeleteAvatar = ({ avatarId }) => {
    const { execute: deleteQuery, status } = useFormGet(url("/admin/avatar/delete/" + avatarId));
    useEffect(() => {
        if (status === 200) {
            window.location.reload();
        }
    }, [status]);
    return (_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: _jsx(MDButton, Object.assign({ component: Link, variant: "outlined", size: "small", color: "dark", onClick: deleteQuery }, { children: "Delete" })) })));
};
export default function Avatar() {
    const { execute: allAvatarQuery, result: allAvatars } = useFormGet(url("/admin/avatar/all"));
    useEffect(() => {
        allAvatarQuery();
    }, []);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: allAvatars
                    ? allAvatars.map((avatar) => {
                        return (_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4, lg: 3 }, { children: [_jsx(Suspense, Object.assign({ fallback: _jsx(LoadingSpinner, { progress: 0 }) }, { children: _jsx(AvatarModel, { modelUrl: avatar.path }) })), _jsxs(MDBox, Object.assign({ mt: 0.2, mx: 0.5, padding: 0.2 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h5", color: "text", textTransform: "capitalize" }, { children: avatar.name })), _jsx(MDBox, Object.assign({ mb: 3, lineHeight: 0 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "light", color: "text" }, { children: avatar.description })) }))] })), avatar.name !== "default" ? _jsx(DeleteAvatar, { avatarId: avatar._id }) : null] }), avatar._id));
                    })
                    : null }))] }));
}
