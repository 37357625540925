var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
function DefaultNavbarDropdown(_a) {
    var { name, icon = false, children = false, collapseStatus = false, light = false, href = "", route = "", collapse } = _a, rest = __rest(_a, ["name", "icon", "children", "collapseStatus", "light", "href", "route", "collapse"]);
    const linkComponent = {
        component: "a",
        href,
        target: "_blank",
        rel: "noreferrer",
    };
    const routeComponent = {
        component: Link,
        to: route,
    };
    return (_jsxs(_Fragment, { children: [_jsxs(MDBox, Object.assign({}, rest, { mx: 1, p: 1, display: "flex", alignItems: "baseline", color: light ? "white" : "dark", opacity: light ? 1 : 0.6, sx: { cursor: "pointer", userSelect: "none" } }, (route && routeComponent), (href && linkComponent), { children: [_jsx(MDTypography, Object.assign({ variant: "body2", lineHeight: 1, color: "inherit", sx: { alignSelf: "center", "& *": { verticalAlign: "middle" } } }, { children: icon })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", textTransform: "capitalize", color: light ? "white" : "dark", sx: { fontWeight: "100%", ml: 1, mr: 0.25 } }, { children: name })), _jsx(MDTypography, Object.assign({ variant: "body2", color: light ? "white" : "dark", ml: "auto" }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "normal", verticalAlign: "middle" } }, { children: collapse && "keyboard_arrow_down" })) }))] })), children && (_jsx(Collapse, Object.assign({ in: Boolean(collapseStatus), timeout: 400, unmountOnExit: true }, { children: children })))] }));
}
export default DefaultNavbarDropdown;
