import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import DataTable from "components/generic/Tables/DataTable";
// Data
import dataTableData from "page/ecommerce/orders/order-list/data/dataTableData";
function OrderList() {
    const [menu, setMenu] = useState(null);
    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);
    const renderMenu = (_jsxs(Menu, Object.assign({ anchorEl: menu, anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" }, open: Boolean(menu), onClose: closeMenu, keepMounted: true }, { children: [_jsx(MenuItem, Object.assign({ onClick: closeMenu }, { children: "Status: Paid" })), _jsx(MenuItem, Object.assign({ onClick: closeMenu }, { children: "Status: Refunded" })), _jsx(MenuItem, Object.assign({ onClick: closeMenu }, { children: "Status: Canceled" })), _jsx(Divider, { sx: { margin: "0.5rem 0" } }), _jsx(MenuItem, Object.assign({ onClick: closeMenu }, { children: _jsx(MDTypography, Object.assign({ variant: "button", color: "error", fontWeight: "regular" }, { children: "Remove Filter" })) }))] })));
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(MDBox, Object.assign({ my: 3 }, { children: [_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mb: 2 }, { children: [_jsx(MDButton, Object.assign({ variant: "gradient", color: "info" }, { children: "new order" })), _jsxs(MDBox, Object.assign({ display: "flex" }, { children: [_jsxs(MDButton, Object.assign({ variant: menu ? "contained" : "outlined", color: "dark", onClick: openMenu }, { children: ["filters\u00A0", _jsx(Icon, { children: "keyboard_arrow_down" })] })), renderMenu, _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsxs(MDButton, Object.assign({ variant: "outlined", color: "dark" }, { children: [_jsx(Icon, { children: "description" }), "\u00A0export csv"] })) }))] }))] })), _jsx(Card, { children: _jsx(DataTable, { table: dataTableData, entriesPerPage: false, canSearch: true }) })] }))] }));
}
export default OrderList;
