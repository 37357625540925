import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
function DefaultPricingCard({ color = "white", badge, price, specifications, action, shadow = true, }) {
    const renderSpecifications = specifications.map(({ label, includes }) => (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center", p: 1 }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", width: "1.5rem", height: "1.5rem", mr: 2, mt: -0.125 }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: color === "white" ? "text" : "white", sx: { lineHeight: 0 } }, { children: _jsx(Icon, { children: includes ? "done" : "remove" }) })) })), _jsx(MDTypography, Object.assign({ variant: "body2", color: color === "white" ? "text" : "white", fontWeight: "regular" }, { children: label }))] }), label)));
    return (_jsx(Card, Object.assign({ sx: { boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") } }, { children: _jsxs(MDBox, Object.assign({ bgColor: color, variant: color === "white" ? "contained" : "gradient", borderRadius: "xl" }, { children: [_jsx(MDBox, Object.assign({ bgColor: badge.color, width: "max-content", px: 4, pt: 0, pb: 0.5, mx: "auto", mt: -1.375, borderRadius: "section", lineHeight: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "caption", textTransform: "uppercase", fontWeight: "medium", color: badge.color === "light" ? "dark" : "white" }, { children: badge.label })) })), _jsx(MDBox, Object.assign({ pt: 3, pb: 2, px: 2, textAlign: "center" }, { children: _jsx(MDBox, Object.assign({ my: 1 }, { children: _jsxs(MDTypography, Object.assign({ variant: "h1", color: color === "white" ? "dark" : "white" }, { children: [_jsx(MDTypography, Object.assign({ display: "inline", component: "small", variant: "h5", color: "inherit", verticalAlign: "top" }, { children: price.currency })), price.value, _jsxs(MDTypography, Object.assign({ display: "inline", component: "small", variant: "h5", color: "inherit" }, { children: ["/", price.type] }))] })) })) })), _jsxs(MDBox, Object.assign({ pb: 3, px: 3 }, { children: [renderSpecifications, action.type === "internal" ? (_jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsxs(MDButton, Object.assign({ component: Link, to: action.route, variant: "gradient", color: action.color, fullWidth: true }, { children: [action.label, "\u00A0", _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "arrow_forward" }))] })) }))) : (_jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsxs(MDButton, Object.assign({ component: "a", href: action.route, target: "_blank", rel: "noreferrer", variant: "gradient", color: action.color, fullWidth: true }, { children: [action.label, "\u00A0", _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "arrow_forward" }))] })) })))] }))] })) })));
}
export default DefaultPricingCard;
