import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
function CustomerCell({ image, name, color = "dark" }) {
    return (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(MDBox, Object.assign({ mr: 1 }, { children: _jsx(MDAvatar, Object.assign({ bgColor: color, src: image, alt: name, size: "xs", crossOrigin: "anonymous" }, { children: image && (_jsx("img", { src: image, style: {
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                        }, title: "", alt: "", crossOrigin: "anonymous" })) })) })), _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", color: "text", sx: { lineHeight: 0 } }, { children: name }))] })));
}
export default CustomerCell;
