var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";
// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav, } from "contexts";
function Sidenav(_a) {
    var { color = "primary", brand = "", younivrBrand, brandName, routes } = _a, rest = __rest(_a, ["color", "brand", "younivrBrand", "brandName", "routes"]);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const items = pathname.split("/").slice(1);
    const itemParentName = items[1];
    const itemName = items[items.length - 1];
    let textColor = "white";
    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    }
    else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }
    const closeSidenav = () => setMiniSidenav(dispatch, true);
    useEffect(() => {
        setOpenCollapse(collapseName);
        setOpenNestedCollapse(itemParentName);
    }, []);
    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }
        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
        */
        window.addEventListener("resize", handleMiniSidenav);
        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);
    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse) => {
        const template = collapse.map(({ name, route, key, href }) => href ? (_jsx(Link, Object.assign({ href: href, target: "_blank", rel: "noreferrer", sx: { textDecoration: "none" } }, { children: _jsx(SidenavItem, { name: name, nested: true }) }), key)) : (_jsx(NavLink, Object.assign({ to: route, style: { textDecoration: "none" } }, { children: _jsx(SidenavItem, { name: name, active: route === pathname, nested: true }) }), key)));
        return template;
    };
    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses) => collapses.map(({ name, collapse, route, href, key }) => {
        let returnValue;
        if (collapse) {
            returnValue = (_jsx(SidenavItem, Object.assign({ color: color, name: name, active: key === itemParentName ? "isParent" : false, open: openNestedCollapse === key, onClick: ({ currentTarget }) => openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                    ? setOpenNestedCollapse(false)
                    : setOpenNestedCollapse(key) }, { children: renderNestedCollapse(collapse) }), key));
        }
        else {
            returnValue = href ? (_jsx(Link, Object.assign({ href: href, target: "_blank", rel: "noreferrer", sx: { textDecoration: "none" } }, { children: _jsx(SidenavItem, { color: color, name: name, active: key === itemName }) }), key)) : (_jsx(NavLink, Object.assign({ to: route, style: { textDecoration: "none" } }, { children: _jsx(SidenavItem, { color: color, name: name, active: key === itemName }) }), key));
        }
        return _jsx(SidenavList, { children: returnValue }, key);
    });
    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        if (type === "collapse") {
            if (href) {
                returnValue = (_jsx(Link, Object.assign({ href: href, target: "_blank", rel: "noreferrer", sx: { textDecoration: "none" } }, { children: _jsx(SidenavCollapse, { name: name, icon: icon, active: key === collapseName, noCollapse: noCollapse }) }), key));
            }
            else if (noCollapse && route) {
                returnValue = (_jsx(NavLink, Object.assign({ to: route }, { children: _jsx(SidenavCollapse, Object.assign({ name: name, icon: icon, noCollapse: noCollapse, active: key === collapseName }, { children: collapse ? renderCollapse(collapse) : null })) }), key));
            }
            else {
                returnValue = (_jsx(SidenavCollapse, Object.assign({ name: name, icon: icon, active: key === collapseName, open: openCollapse === key, onClick: () => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)) }, { children: collapse ? renderCollapse(collapse) : null }), key));
            }
        }
        else if (type === "title") {
            returnValue = (_jsx(MDTypography, Object.assign({ color: textColor, display: "block", variant: "caption", fontWeight: "bold", textTransform: "uppercase", pl: 3, mt: 2, mb: 1, ml: 1 }, { children: title }), key));
        }
        else if (type === "divider") {
            returnValue = (_jsx(Divider, { light: (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav) }, key));
        }
        return returnValue;
    });
    return (_jsx(SidenavRoot, Object.assign({}, rest, { variant: "permanent", ownerState: { transparentSidenav, whiteSidenav, miniSidenav, darkMode } }, { children: _jsxs(MDBox, Object.assign({ sx: {
                display: "flex",
                flexDirection: "column",
                height: "100%", // Full viewport height
            } }, { children: [_jsxs(MDBox, Object.assign({ pt: 3, pb: 1, px: 4, textAlign: "center" }, { children: [_jsx(MDBox, Object.assign({ display: { xs: "block", xl: "none" }, position: "absolute", top: 0, right: 0, p: 1.625, onClick: closeSidenav, sx: { cursor: "pointer" } }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", color: "secondary" }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "close" })) })) })), _jsxs(MDBox, Object.assign({ component: NavLink, to: "/", display: "flex", alignItems: "center" }, { children: [brand && _jsx(MDBox, { component: "img", src: brand, alt: "Brand", width: "2rem" }), _jsx(MDBox, Object.assign({ width: !brandName && "100%", sx: (theme) => sidenavLogoLabel(theme, { miniSidenav }) }, { children: _jsx(MDTypography, Object.assign({ component: "h6", variant: "button", fontWeight: "medium", color: textColor }, { children: brandName })) }))] }))] })), _jsx(Divider, { light: (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav) }), _jsx(MDBox, Object.assign({ sx: {
                        flex: "1 1 auto",
                        overflow: "auto",
                    } }, { children: _jsx(List, { children: renderRoutes }) })), _jsx(MDBox, Object.assign({ pt: 3, pb: 1, px: 4, textAlign: "center" }, { children: _jsxs(MDBox, Object.assign({ component: NavLink, to: "/", display: "flex", alignItems: "center" }, { children: [_jsx(MDBox, { component: "img", src: younivrBrand, alt: "Brand", width: "2rem" }), _jsx(MDBox, Object.assign({ width: !brandName && "100%", sx: (theme) => sidenavLogoLabel(theme, { miniSidenav }) }, { children: _jsx(MDTypography, Object.assign({ component: "h6", variant: "button", fontWeight: "medium", color: textColor }, { children: "Younivr" })) }))] })) }))] })) })));
}
export default Sidenav;
