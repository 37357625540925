import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// ProductPage page components
import ProductImages from "./components/ProductImages";
import ProductInfo from "./components/ProductInfo";
function ProductDetails({ _id = "1", title = "TITLE", description = "DESCRIPTION", price = 230, }) {
    return (_jsx(MDBox, Object.assign({ py: 3 }, { children: _jsx(Card, Object.assign({ sx: { overflow: "visible" } }, { children: _jsxs(MDBox, Object.assign({ p: 3 }, { children: [_jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "medium" }, { children: "Product Details" })) })), _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, lg: 6, xl: 5 }, { children: _jsx(ProductImages, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 5, sx: { mx: "auto" } }, { children: _jsx(ProductInfo, { description: description, title: title }) }))] }))] })) })) })));
}
export default ProductDetails;
