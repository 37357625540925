import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Sales dashboard components
import ProductCell from "../components/ProductCell";
import DefaultCell from "../components/DefaultCell";
import RefundsCell from "../components/RefundsCell";
// Images
import SMiole from "assets/images/ecommerce/Dentsply_Sure SMiole.png";
import Waveone from "assets/images/ecommerce/Waveone-Gold-package.png";
import SDRFlow from "assets/images/ecommerce/sdr-flow__3.jpg";
import XSmart from "assets/images/ecommerce/X-SMART-Plus-Image1-HR-1113.jpg";
import ProGlider from "assets/images/ecommerce/proglider-box-3-file-blister-BP.png";
const dataTableData = {
    columns: [
        { Header: "product", accessor: "product", width: "55%" },
        { Header: "value", accessor: "value" },
        { Header: "views", accessor: "views", align: "center" },
        { Header: "refunds", accessor: "refunds", align: "center" },
    ],
    rows: [
        {
            product: _jsx(ProductCell, { image: SMiole, name: "Sure SMiole", orders: 132 }),
            value: _jsx(DefaultCell, { children: "$130.992" }),
            views: _jsx(DefaultCell, { children: "423" }),
            refunds: _jsx(RefundsCell, { value: 0, icon: { color: "success", name: "keyboard_arrow_up" } }),
        },
        {
            product: (_jsx(ProductCell, { image: Waveone, name: "Waveone-Gold-package", orders: 121 })),
            value: _jsx(DefaultCell, { children: "$80.250" }),
            views: _jsx(DefaultCell, { children: "323" }),
            refunds: _jsx(RefundsCell, { value: 0, icon: { color: "error", name: "keyboard_arrow_down" } }),
        },
        {
            product: _jsx(ProductCell, { image: SDRFlow, name: "SDR Flow +", orders: 91 }),
            value: _jsx(DefaultCell, { children: "$40.600" }),
            views: _jsx(DefaultCell, { children: "520" }),
            refunds: _jsx(RefundsCell, { value: 0, icon: { color: "success", name: "keyboard_arrow_up" } }),
        },
        {
            product: _jsx(ProductCell, { image: XSmart, name: "X-Smart plus", orders: 60 }),
            value: _jsx(DefaultCell, { children: "$91.300" }),
            views: _jsx(DefaultCell, { children: "120" }),
            refunds: _jsx(RefundsCell, { value: 1, icon: { color: "error", name: "keyboard_arrow_down" } }),
        },
        {
            product: (_jsx(ProductCell, { image: ProGlider, name: "ProGlider Glide Path Files", orders: 56 })),
            value: _jsx(DefaultCell, { children: "$20.925" }),
            views: _jsx(DefaultCell, { children: "506" }),
            refunds: _jsx(RefundsCell, { value: 0, icon: { color: "success", name: "keyboard_arrow_up" } }),
        },
    ],
};
export default dataTableData;
