import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import Template from "components/features/template/component/Template";
import TemplateForm from "components/features/template/component/TemplateForm";
import { ETemplateOrigin } from "components/features/template/type/TTemplate";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import MDBox from "components/generic/MDBox";
export default function EditTemplate() {
    var _a;
    const { id } = useParams();
    const { execute, result } = useFormGet(url(`/scene-template/get/${id}`));
    const [template, setTemplate] = useState(null);
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        if (result)
            setTemplate(result);
    }, [result]);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ mt: 2, sx: { height: "85vh" } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 6, sx: { height: "100%" } }, { children: template ? (_jsxs(_Fragment, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 3, lg: 3, sx: { flexGrow: 1 } }, { children: template.origin === ETemplateOrigin.custom ? (_jsx(TemplateForm, { value: Object.assign(Object.assign({}, template), { thumbnail: template.thumbnail, model: template.sceneModelPath, type: (_a = template.type) !== null && _a !== void 0 ? _a : "Native" }) })) : null })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 9, lg: 9, sx: { flexGrow: 1 } }, { children: _jsx(Template, { template: template }) }))] })) : null })) }))] }));
}
