import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, Collapse, Grid, Icon, List, ListItem, ListItemText } from "@mui/material";
import MDBox from "components/generic/MDBox";
import MDInput from "components/generic/MDInput";
import { KankanScene } from "threejs/service/SceneManagement/KankanScene";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { useFormPost } from "hooks/useFormPost";
export default function MenuList(props) {
    const [isOpen, setOpen] = useState(true);
    const [menuDisplayName, setMenuDisplayName] = useState("");
    const [menuItems, setMenuItems] = useState([]);
    const [sync, setSync] = useState(false);
    const { execute: getMenuExecute, result: getMenuResult } = useFormGet(url(`/room/menu/get/data/${props.roomId}`));
    const { execute: postMenuExecute, loading: postMenuLoading } = useFormPost(url(`/room/menu/set/data/${props.roomId}`));
    const playerPositionAndRotationInformation = () => {
        if (menuDisplayName === "")
            throw new Error("Display name obligatoire");
        if (props.scene instanceof KankanScene) {
            const m = {
                displayName: menuDisplayName,
                pose: props.scene.getInstance().Camera.getPose(),
            };
            setMenuItems([...menuItems, m]);
        }
        setMenuDisplayName("");
        setSync(true);
    };
    const moveToPlace = (m) => {
        if (props.scene instanceof KankanScene) {
            props.scene.getInstance().Camera.setPose(m.pose);
        }
    };
    const syncMenuWithServer = () => {
        const stringData = JSON.stringify(menuItems);
        postMenuExecute(stringData);
        //console.log(stringData);
    };
    const moveUpItemInTheList = (currentIndex) => {
        if (currentIndex <= 0 || currentIndex >= menuItems.length)
            return; // Invalid index, return original array
        const newList = [...menuItems];
        // Remove the element from the array
        const element = newList.splice(currentIndex, 1)[0];
        // Insert the element one step to the left
        newList.splice(currentIndex - 1, 0, element);
        setMenuItems(newList);
        setSync(true);
    };
    const moveDownItemInTheList = (currentIndex) => {
        if (currentIndex < 0 || currentIndex >= menuItems.length - 1)
            return; // Invalid index, return original array
        const newList = [...menuItems];
        // Remove the element from the array
        const element = newList.splice(currentIndex, 1)[0];
        // Insert the element one step to the right
        newList.splice(currentIndex + 1, 0, element);
        setMenuItems(newList);
        setSync(true);
    };
    const deleteItemFromTheList = (currentIndex) => {
        if (currentIndex < 0 || currentIndex >= menuItems.length - 1)
            return; // Invalid index, return original array
        const newList = [...menuItems];
        // Remove the element from the array
        newList.splice(currentIndex, 1)[0];
        setMenuItems(newList);
        setSync(true);
    };
    useEffect(() => {
        if (sync) {
            syncMenuWithServer();
            setSync(false);
        }
    }, [sync, menuItems]);
    useEffect(() => {
        getMenuExecute();
    }, []);
    useEffect(() => {
        if (getMenuResult) {
            setMenuItems(getMenuResult);
        }
    }, [getMenuResult]);
    return !postMenuLoading ? (_jsxs(MDBox, Object.assign({ marginTop: "10px" }, { children: [menuItems.length ? (_jsxs(_Fragment, { children: [props.scene.sceneMode === "Edit" && (_jsx(ListItem, Object.assign({ component: "li" }, { children: _jsxs(MDBox, Object.assign({ style: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                            }, onClick: () => {
                                setOpen(!isOpen);
                            } }, { children: [_jsx(ListItemText, { primary: _jsx("span", { children: "Menu" }) }), menuItems.length && (_jsx(Icon, Object.assign({ component: "i", style: {
                                        transform: isOpen ? "rotate(0)" : "rotate(-180deg)",
                                        transition: "easeOut",
                                    } }, { children: "expand_less" })))] })) }))), _jsx(Collapse, Object.assign({ in: isOpen, timeout: "auto", unmountOnExit: true }, { children: _jsx(List, Object.assign({ style: {
                                padding: "10px",
                            } }, { children: menuItems.map((element, index) => (_jsxs(MDBox, Object.assign({ style: {
                                    display: "flex",
                                } }, { children: [_jsx("span", Object.assign({ style: {
                                            fontSize: "16px",
                                            fontFamily: "emoji",
                                        }, onClick: () => moveToPlace(element) }, { children: element.displayName })), props.scene.sceneMode === "Edit" ? (_jsxs(_Fragment, { children: [index > 0 ? (_jsx(Button, Object.assign({ onClick: () => moveUpItemInTheList(index) }, { children: "Up" }))) : null, index < menuItems.length - 1 ? (_jsx(Button, Object.assign({ onClick: () => moveDownItemInTheList(index) }, { children: "Down" }))) : null, _jsx(Button, Object.assign({ onClick: () => deleteItemFromTheList(index) }, { children: "Delete" }))] })) : null] }), index))) })) }))] })) : null, props.scene.sceneMode === "Edit" && (_jsx(MDBox, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(MDInput, { variant: "outlined", name: "displayName", placeholder: "Display Name", value: menuDisplayName, onChange: (e) => {
                                setMenuDisplayName(e.target.value);
                            } }), _jsx(Button, Object.assign({ onClick: playerPositionAndRotationInformation }, { children: "Add Menu" }))] })) }))] }))) : null;
}
