import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { LocalizationProvider, DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormControl, Stack } from '@mui/material';
export default function EventDate(props) {
    const [date, setDate] = React.useState(null);
    useEffect(() => {
        if (props.formik.values[props.name]) {
            setDate(dayjs(props.formik.values[props.name] * 1000));
        }
        else {
            setDate(dayjs(Date.now()));
        }
    }, []);
    useEffect(() => {
        if (date)
            props.formik.setFieldValue(props.name, date.unix());
    }, [date]);
    return (_jsx(FormControl, Object.assign({ style: {
            marginBottom: 15
        } }, { children: _jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Stack, Object.assign({ direction: "row", spacing: 2 }, { children: [_jsx(DatePicker, { label: "Date", value: date, format: 'DD/MM/YYYY', onChange: (d) => {
                            setDate(d);
                        } }), _jsx(MobileTimePicker, { label: "Time", value: date, onChange: (d) => { setDate(d); } })] })) })) })));
}
