import { configureStore } from '@reduxjs/toolkit';
import TranslationSlice from 'redux-store/TranslationSlice';
import UserSlice from 'redux-store/UserSlice';
import ThemeSlice from 'redux-store/ThemeSlice';
export const store = configureStore({
    reducer: {
        translation: TranslationSlice,
        user: UserSlice,
        theme: ThemeSlice
    },
});
