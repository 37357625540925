import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
function OrderSummary() {
    return (_jsxs(_Fragment, { children: [_jsx(MDBox, Object.assign({ mb: 2 }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Order Summary" })) })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", mb: 0.5 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: "Product Price:" })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "body2", fontWeight: "medium" }, { children: "$90" })) }))] })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", mb: 0.5 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: "Delivery:" })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "body2", fontWeight: "medium" }, { children: "$14" })) }))] })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", mb: 0.5 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: "Taxes:" })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "body2", fontWeight: "medium" }, { children: "$1.95" })) }))] })), _jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", mt: 3 }, { children: [_jsx(MDTypography, Object.assign({ variant: "body1", fontWeight: "light", color: "text" }, { children: "Total:" })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", fontWeight: "medium" }, { children: "$1.95" })) }))] }))] }));
}
export default OrderSummary;
