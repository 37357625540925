import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import { useFormPost } from "hooks/useFormPost";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { url } from "services/ApiEndpoint";
const styles = {
    dropzone: {
        border: "2px dashed #cacaca",
        padding: "20px",
        borderRadius: "5px",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
        display: "flex",
    },
    error: {
        color: "red",
        marginTop: "0.5rem",
    },
};
export default function UploadMedia({ roomId }) {
    const [files, setFiles] = useState([]);
    const onDropCallback = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);
    const renderView = (f) => {
        const fileType = f.type.split("/")[0];
        const isModel = f.name.endsWith(".glb");
        const fileURL = URL.createObjectURL(f);
        if (isModel) {
            return _jsx(_Fragment, {});
        }
        else {
            switch (fileType) {
                case "image":
                    return _jsx("img", { src: fileURL, alt: "Preview", width: "300" });
                case "video":
                    return (_jsxs("video", Object.assign({ controls: true, width: "300" }, { children: [_jsx("source", { src: fileURL, type: fileType }), "Your browser does not support the video tag."] })));
                case "audio":
                    return (_jsxs("audio", Object.assign({ controls: true }, { children: [_jsx("source", { src: fileURL, type: fileType }), "Your browser does not support the audio tag."] })));
                default:
                    return _jsx("p", { children: "Unsupported file type" });
            }
        }
    };
    const { getRootProps, isDragActive } = useDropzone({ onDrop: onDropCallback });
    const { execute, globalErrorMessage, status, loading, errors } = useFormPost(url("/room/media/upload/" + roomId));
    const upload = () => {
        const formData = new FormData();
        files.map((file) => formData.append("data", file));
        execute(formData);
    };
    useEffect(() => {
        if (status && status === 200) {
            window.location.reload();
        }
    }, [status]);
    return (_jsxs(_Fragment, { children: [_jsx(MDBox, Object.assign({ mt: 0.75 }, getRootProps(), { style: styles.dropzone, accept: "image/*,video/*,audio/*,.glb" }, { children: isDragActive ? (_jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "#cacaca", fontWeight: "regular" }, { children: "Drop the files here ..." }))) : (_jsxs(MDTypography, Object.assign({ component: "div", variant: "caption", color: "#cacaca", fontWeight: "regular" }, { children: ["`", "Drag 'n' drop some files here, or click to select files", "`"] }))) })), files.map((f, key) => {
                return _jsx(MDBox, { children: renderView(f) }, key);
            }), _jsx(MDButton, Object.assign({ type: "submit", variant: "gradient", color: "dark", onClick: upload, loading: loading }, { children: "upload" }))] }));
}
