import { setIn } from "formik";
export const ApiFormErrorResolver = (form, error) => {
    let errors = {};
    error.map((e) => {
        const data = setIn(errors, e.path.join("."), e.message);
        errors = Object.assign(Object.assign({}, errors), data);
    });
    form.setErrors(errors);
    // console.log(form, errors);
};
