import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSigneOutUser } from "hooks/useSigneOutUser";
import { StaticValues } from "utils/const/StaticValues";
export function useFormGet(endpoint) {
    const [globalErrorMessage, setGlobalErrorMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const { signOut } = useSigneOutUser();
    const userToken = useSelector((state) => state.user.userToken);
    //if (!userToken) signOut();
    const abortController = new AbortController();
    const execute = async () => {
        var _a;
        try {
            //console.log(userToken);
            setLoading(true);
            setResult(null);
            setStatus(null);
            const headers = new Headers();
            headers.append("Authorization", "Bearer " + userToken);
            const response = await fetch(endpoint.value, {
                headers,
                signal: abortController.signal,
            });
            setStatus(response.status);
            if (!((_a = response.headers.get("Content-Type")) === null || _a === void 0 ? void 0 : _a.includes("application/json"))) {
                return setLoading(false);
            }
            const res = await response.json();
            if (response.status !== 200) {
                if (response.status === 500 &&
                    typeof res === "string" &&
                    (res === StaticValues.ErrorTokenNotFound || res === StaticValues.ErrorExpired)) {
                    return signOut();
                }
                if (res.errors) {
                }
                else if (res.message) {
                    setGlobalErrorMessage(_jsx("p", Object.assign({ style: { color: "red" } }, { children: res.message })));
                }
            }
            else {
                setResult(res);
            }
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    };
    return { globalErrorMessage, status, result, execute, loading, abortController };
}
