import { Vector3 } from "three";
export class SceneInteractionDetail {
    constructor() {
        this._position = new Vector3();
        this._normal = new Vector3();
    }
    set customItem(value) {
        this._customItem = value;
    }
    get customItem() {
        return this._customItem;
    }
    // position
    set position(vec) {
        this._position = vec;
    }
    get position() {
        return this._position;
    }
    // normal
    set normal(vec) {
        this._normal = vec;
    }
    get normal() {
        return this._normal;
    }
    // object
    set object(obj) {
        this._object = obj;
    }
    get object() {
        return this._object;
    }
}
