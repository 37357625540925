import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import MDAvatar from "components/generic/MDAvatar";
import MDBadge from "components/generic/MDBadge";
// Images
import orderImage from "assets/images/products/product-details-1.jpg";
function OrderInfo() {
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 3, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(MDBox, Object.assign({ mr: 2 }, { children: _jsx(MDAvatar, { size: "xxl", src: orderImage, alt: "Gold Glasses" }) })), _jsxs(MDBox, Object.assign({ lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "SureSmile VPro" })), _jsx(MDBox, Object.assign({ mb: 2 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: "Order was delivered 2 days ago." })) })), _jsx(MDBadge, { variant: "gradient", color: "success", size: "xs", badgeContent: "delivered", container: true })] }))] })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6, sx: { textAlign: "right" } }, { children: [_jsx(MDButton, Object.assign({ variant: "gradient", color: "dark", size: "small" }, { children: "contact us" })), _jsx(MDBox, Object.assign({ mt: 0.5 }, { children: _jsxs(MDTypography, Object.assign({ variant: "button", color: "text" }, { children: ["Do you like the product? Leave us a review", " ", _jsx(MDTypography, Object.assign({ component: "a", href: "#", variant: "button", color: "text", fontWeight: "regular" }, { children: "here" })), "."] })) }))] }))] })));
}
export default OrderInfo;
