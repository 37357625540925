import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinearProgress, Box, Typography } from '@mui/material';
const LoadingSpinner = ({ progress }) => {
    return (
    // <>
    _jsxs(Box, Object.assign({ sx: {
            // position: 'fixed',
            top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            width: '80%',
            height: "100%",
            zIndex: 1000,
            textAlign: 'center',
        } }, { children: [_jsx(LinearProgress, { variant: "determinate", value: progress }), _jsx(Typography, Object.assign({ variant: "h6", sx: { mt: 2 } }, { children: `Loading: ${Math.round(progress)}%` }))] })));
};
export default LoadingSpinner;
