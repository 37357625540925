/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/**
 * The base typography styles for the Material Dashboard 2 PRO React TSUI Dashboard PRO Material.
 * You can add new typography style using this file.
 * You can customized the typography styles for the entire Material Dashboard 2 PRO React TSUI Dashboard PRO Material using thie file.
 */
// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme-dark/base/colors";
// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";
const { white } = colors;
const baseProperties = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLighter: 100,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontSizeXXS: pxToRem(10.4),
    fontSizeXS: pxToRem(12),
    fontSizeSM: pxToRem(14),
    fontSizeMD: pxToRem(16),
    fontSizeLG: pxToRem(18),
    fontSizeXL: pxToRem(20),
    fontSize2XL: pxToRem(24),
    fontSize3XL: pxToRem(30),
};
const baseHeadingProperties = {
    fontFamily: baseProperties.fontFamily,
    color: white.main,
    fontWeight: baseProperties.fontWeightBold,
};
const baseDisplayProperties = {
    fontFamily: baseProperties.fontFamily,
    color: white.main,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.2,
};
const typography = {
    fontFamily: baseProperties.fontFamily,
    fontWeightLighter: baseProperties.fontWeightLighter,
    fontWeightLight: baseProperties.fontWeightLight,
    fontWeightRegular: baseProperties.fontWeightRegular,
    fontWeightMedium: baseProperties.fontWeightMedium,
    fontWeightBold: baseProperties.fontWeightBold,
    h1: Object.assign({ fontSize: pxToRem(48), lineHeight: 1.25 }, baseHeadingProperties),
    h2: Object.assign({ fontSize: pxToRem(36), lineHeight: 1.3 }, baseHeadingProperties),
    h3: Object.assign({ fontSize: pxToRem(30), lineHeight: 1.375 }, baseHeadingProperties),
    h4: Object.assign({ fontSize: pxToRem(24), lineHeight: 1.375 }, baseHeadingProperties),
    h5: Object.assign({ fontSize: pxToRem(20), lineHeight: 1.375 }, baseHeadingProperties),
    h6: Object.assign({ fontSize: pxToRem(16), lineHeight: 1.625 }, baseHeadingProperties),
    subtitle1: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeXL,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.625,
    },
    subtitle2: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.6,
    },
    body1: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeXL,
        fontWeight: baseProperties.fontWeightRegular,
        lineHeight: 1.625,
    },
    body2: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.6,
    },
    button: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeSM,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.5,
        textTransform: "uppercase",
    },
    caption: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeXS,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.25,
    },
    overline: {
        fontFamily: baseProperties.fontFamily,
    },
    d1: Object.assign({ fontSize: pxToRem(80) }, baseDisplayProperties),
    d2: Object.assign({ fontSize: pxToRem(72) }, baseDisplayProperties),
    d3: Object.assign({ fontSize: pxToRem(64) }, baseDisplayProperties),
    d4: Object.assign({ fontSize: pxToRem(56) }, baseDisplayProperties),
    d5: Object.assign({ fontSize: pxToRem(48) }, baseDisplayProperties),
    d6: Object.assign({ fontSize: pxToRem(40) }, baseDisplayProperties),
    size: {
        xxs: baseProperties.fontSizeXXS,
        xs: baseProperties.fontSizeXS,
        sm: baseProperties.fontSizeSM,
        md: baseProperties.fontSizeMD,
        lg: baseProperties.fontSizeLG,
        xl: baseProperties.fontSizeXL,
        "2xl": baseProperties.fontSize2XL,
        "3xl": baseProperties.fontSize3XL,
    },
    lineHeight: {
        sm: 1.25,
        md: 1.5,
        lg: 2,
    },
};
export default typography;
