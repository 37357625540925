import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, IconButton } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { DELETE_ITEM_IN_SCENE_MODEL, RESET_EDIT_ITEM, SAVE_CUSTOM_ITEMS_DETAILS, } from "threejs/state/const";
import { useFormik } from "formik";
import { useEffect } from "react";
import MDBox from "components/generic/MDBox";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import MDButton from "components/generic/MDButton";
import Color from "../MediaItems/Color";
export default function TagMeshForm({ domElement, item, }) {
    const handleClickBack = () => {
        const customEvent2 = new CustomEvent(RESET_EDIT_ITEM);
        domElement.dispatchEvent(customEvent2);
    };
    const formData = useFormik({
        initialValues: {
            displayName: "",
            description: "",
            color: "#ff0000",
        },
        onSubmit: (values) => {
            console.log(values);
            const oldInfo = item.meshToData();
            oldInfo.otherDatas.circleColor = values.color;
            oldInfo.otherDatas.displayName = values.displayName;
            oldInfo.otherDatas.description = values.description;
            item.dataToMesh(oldInfo);
            const customEvent = new CustomEvent(SAVE_CUSTOM_ITEMS_DETAILS);
            domElement.dispatchEvent(customEvent);
        },
    });
    useEffect(() => {
        const selectedItem = item.meshToData();
        formData.setFieldValue("description", selectedItem.otherDatas.description);
        formData.setFieldValue("displayName", selectedItem.otherDatas.displayName);
        if (selectedItem.otherDatas.circleColor)
            formData.setFieldValue("color", selectedItem.otherDatas.circleColor);
    }, [item]);
    return (_jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sx: { display: "flex", justifyContent: "space-between" } }, { children: _jsx(IconButton, Object.assign({ color: "primary", onClick: handleClickBack }, { children: _jsx(MenuOpenIcon, {}) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: _jsx(MDBox, Object.assign({ mt: 2, mx: 1 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormInputText, { label: "Display Name", name: "displayName", formik: formData }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormInputTextArea, { rows: 5, label: "Description", name: "description", formik: formData }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Color, { item: item, formik: formData }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sx: { display: "flex", justifyContent: "space-around" } }, { children: [_jsx(MDButton, Object.assign({ type: "submit", variant: "gradient", color: "primary" }, { children: "save" })), _jsx(MDButton, Object.assign({ variant: "outlined", color: "primary", onClick: () => {
                                                const customEvent = new CustomEvent(DELETE_ITEM_IN_SCENE_MODEL, {
                                                    detail: item,
                                                });
                                                domElement.dispatchEvent(customEvent);
                                                console.log("delete action");
                                            } }, { children: "Delete" }))] }))] })) })) })) }))] })));
}
