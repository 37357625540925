import { Euler, Group, Quaternion, Vector3 } from "three";
import { ADD_NEW_CAMERA_IN_SCENE_MODEL, ADD_NEW_IMAGE_IN_SCENE_MODEL, ADD_NEW_OBJECT_IN_SCENE_MODEL, ADD_NEW_PIN_TAG_IN_SCENE_MODEL, ADD_NEW_VIDEO_IN_SCENE_MODEL, EDIT_SCENE_ITEM, ITEM_ADDED_TO_THE_SCENE_GROUP, ITEM_REMOVED_FROM_THE_SCENE_GROUP, } from "../../state/const";
import { SceneItem } from "../../model/SceneItem";
import { ImageMesh } from "../../sceneItem/ImageMesh";
import { VideoMesh } from "../../sceneItem/VideoMesh";
import { ObjectMesh } from "../../sceneItem/ObjectMesh";
import { LOCAL } from "services/ApiEndpoint";
import { TagMesh } from "threejs/sceneItem/TagMesh";
import { LiveStreamMesh } from "threejs/sceneItem/LiveStreamMesh";
export class SceneModelAction {
    constructor(_baseScene) {
        this._baseScene = _baseScene;
        this.calculateRotation = (normalInput) => {
            const quat = new Quaternion();
            quat.setFromUnitVectors(new Vector3(0, 0, 1), normalInput);
            const rotationEular = new Euler();
            rotationEular.setFromQuaternion(quat);
            return rotationEular;
        };
        this._baseScene.renderer.domElement.addEventListener(ADD_NEW_IMAGE_IN_SCENE_MODEL, () => {
            const sceneItem = this.sceneItemFromSceneInteractionDetail();
            if (sceneItem) {
                sceneItem.name = "ImagePlane";
                sceneItem.otherDatas.src = `${LOCAL}/static/placeholder.svg`;
                this._addNewImage(sceneItem);
            }
        });
        this._baseScene.renderer.domElement.addEventListener(ADD_NEW_VIDEO_IN_SCENE_MODEL, () => {
            const sceneItem = this.sceneItemFromSceneInteractionDetail();
            if (sceneItem) {
                sceneItem.name = "VideoPlane";
                sceneItem.otherDatas.src = `${LOCAL}/static/example.mp4`;
                this._addNewVideo(sceneItem);
            }
        });
        this._baseScene.renderer.domElement.addEventListener(ADD_NEW_OBJECT_IN_SCENE_MODEL, () => {
            const sceneItem = this.sceneItemFromSceneInteractionDetail();
            if (sceneItem) {
                sceneItem.name = "CustomObject";
                sceneItem.otherDatas.src = `${LOCAL}/static/Dental_implant5.glb`;
                this._addNewObject(sceneItem);
            }
        });
        this._baseScene.renderer.domElement.addEventListener(ADD_NEW_PIN_TAG_IN_SCENE_MODEL, () => {
            const sceneItem = this.sceneItemFromSceneInteractionDetail();
            if (sceneItem) {
                sceneItem.name = "YounivrTag";
                this._addNewTag(sceneItem, this._baseScene.sceneInteractionDetail.normal);
            }
        });
        this._baseScene.renderer.domElement.addEventListener(ADD_NEW_CAMERA_IN_SCENE_MODEL, () => {
            const sceneItem = this.sceneItemFromSceneInteractionDetail();
            if (sceneItem) {
                sceneItem.name = "LiveStream";
                this._addNewLiveStream(sceneItem);
            }
        });
    }
    sceneItemFromSceneInteractionDetail() {
        if (!this._baseScene.sceneInteractionDetail)
            return;
        const sceneItem = new SceneItem();
        sceneItem.position = this._baseScene.sceneInteractionDetail.position;
        sceneItem.rotation = this.calculateRotation(this._baseScene.sceneInteractionDetail.normal);
        return sceneItem;
    }
    _getItemGroup() {
        const containerObj = this._baseScene.scene.getObjectByName("additionalData");
        if (containerObj && containerObj.isGroup) {
            return containerObj;
        }
        const g = new Group();
        g.addEventListener("childremoved", (obj) => {
            const customEvent = new CustomEvent(ITEM_REMOVED_FROM_THE_SCENE_GROUP, {
                detail: obj.child,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        });
        g.name = "additionalData";
        this._baseScene.scene.add(g);
        return g;
    }
    _addNewImage(data) {
        const mesh = new ImageMesh();
        mesh.dataToMesh(data);
        this._getItemGroup().add(mesh);
        const customEvent1 = new CustomEvent(ITEM_ADDED_TO_THE_SCENE_GROUP, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent1);
        const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    _addNewVideo(data) {
        const mesh = new VideoMesh();
        mesh.dataToMesh(data);
        this._getItemGroup().add(mesh);
        const customEvent1 = new CustomEvent(ITEM_ADDED_TO_THE_SCENE_GROUP, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent1);
        const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    _addNewObject(data) {
        const mesh = new ObjectMesh();
        mesh.dataToMesh(data);
        this._getItemGroup().add(mesh);
        const customEvent1 = new CustomEvent(ITEM_ADDED_TO_THE_SCENE_GROUP, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent1);
        const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    _addNewTag(data, normal) {
        const tagMesh = new TagMesh();
        data.otherDatas.normal = normal;
        tagMesh.dataToMesh(data);
        this._getItemGroup().add(tagMesh);
        const prevCallBack = this._baseScene._animationCallBack;
        this._baseScene._animationCallBack = () => {
            prevCallBack();
            tagMesh.circle.lookAt(this._baseScene.camera.position);
        };
        const customEvent1 = new CustomEvent(ITEM_ADDED_TO_THE_SCENE_GROUP, {
            detail: tagMesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent1);
        const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
            detail: tagMesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    _addNewLiveStream(data) {
        const mesh = new LiveStreamMesh();
        mesh.dataToMesh(data);
        this._getItemGroup().add(mesh);
        const customEvent1 = new CustomEvent(ITEM_ADDED_TO_THE_SCENE_GROUP, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent1);
        const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
            detail: mesh,
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    get itemGroup() {
        return this._getItemGroup();
    }
}
