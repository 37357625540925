import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import FormFileInput from "components/generic/form/FormFileInput";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { useFormPost } from "hooks/useFormPost";
import { useEffect } from "react";
import { url } from "services/ApiEndpoint";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
export default function CreateAvatar() {
    const { execute, globalErrorMessage, status, loading, errors } = useFormPost(url("/admin/avatar/create"));
    useEffect(() => {
        if (!status)
            return;
        if (status === 400) {
            ApiFormErrorResolver(formData, errors);
        }
        else if (status === 200) {
            window.location.href = "/admin/avatar";
        }
    }, [status]);
    const submitForm = async (values) => {
        const form = new FormData();
        form.append("name", values.name);
        form.append("description", values.description);
        form.append("path", values.path);
        await execute(form);
    };
    const initValues = {
        description: "",
        name: "",
        path: "",
    };
    const formData = useFormik({
        initialValues: initValues,
        onSubmit: async (value) => await submitForm(value),
    });
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ py: 3, mb: 20, height: "65vh" }, { children: _jsx(Grid, Object.assign({ container: true, justifyContent: "center", alignItems: "center", sx: { height: "100%", mt: 8 } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 8 }, { children: _jsxs(Card, Object.assign({ sx: { height: "100%" } }, { children: [_jsx(MDBox, Object.assign({ mx: 2, mt: -3 }, { children: _jsx(Stepper, Object.assign({ alternativeLabel: true }, { children: _jsx(Step, { children: _jsx(StepLabel, { children: "Avatar Properties" }) }) })) })), _jsx(MDBox, Object.assign({ p: 3 }, { children: _jsx(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: _jsxs(MDBox, { children: [_jsxs(MDBox, Object.assign({ mt: 1.625 }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputText, { label: "title", name: "name", formik: formData, required: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormFileInput, { name: "path", label: "Model", formik: formData, required: true }) }))] })), _jsx(Grid, Object.assign({ container: true, spacing: 3 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(FormInputTextArea, { rows: 5, label: "description", name: "description", formik: formData, required: true }) })) }))] })), _jsxs(MDBox, Object.assign({ mt: 2, width: "100%", display: "flex", justifyContent: "space-between" }, { children: [_jsx(MDBox, {}), globalErrorMessage, _jsx(MDButton, Object.assign({ disabled: loading, type: "submit", variant: "gradient", color: "dark" }, { children: "create" }))] }))] }) })) }))] })) })) })) }))] }));
}
