import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Timeline context
import { useTimeline } from "components/generic/Timeline/context";
// Custom styles for the TimelineItem
import timelineItem from "components/generic/Timeline/TimelineItem/styles";
function TimelineItem({ color, icon, title, dateTime, description, lastItem }) {
    const isDark = useTimeline();
    return (_jsxs(MDBox, Object.assign({ position: "relative", mb: 3, sx: (theme) => timelineItem(theme, { lastItem, isDark }) }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", bgColor: color, color: "white", width: "2rem", height: "2rem", borderRadius: "50%", position: "absolute", top: "8%", left: "2px", zIndex: 2, sx: { fontSize: ({ typography: { size } }) => size.sm } }, { children: _jsx(Icon, Object.assign({ fontSize: "inherit" }, { children: icon })) })), _jsxs(MDBox, Object.assign({ ml: 5.75, pt: description ? 0.7 : 0.5, lineHeight: 0, maxWidth: "30rem" }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "medium", color: isDark ? "white" : "dark" }, { children: title })), _jsx(MDBox, Object.assign({ mt: 0.5 }, { children: _jsx(MDTypography, Object.assign({ variant: "caption", color: isDark ? "secondary" : "text" }, { children: dateTime })) })), _jsx(MDBox, Object.assign({ mt: 2, mb: 1.5 }, { children: description ? (_jsx(MDTypography, Object.assign({ variant: "button", color: isDark ? "white" : "dark" }, { children: description }))) : null }))] }))] })));
}
// Declaring default props for TimelineItem
TimelineItem.defaultProps = {
    color: "info",
    lastItem: false,
    description: "",
};
export default TimelineItem;
