import Rapier, { ShapeType } from "@dimforge/rapier3d";
import { BoxHelper, BufferAttribute, BufferGeometry, CapsuleGeometry, LineBasicMaterial, LineSegments, Mesh, MeshBasicMaterial, Quaternion, Vector3, } from "three";
export const GRAVITY = { x: 0, y: -9.81, z: 0 };
export class Physics {
    constructor() {
        this.physicsObjects = [];
        this._debugMesh = null;
    }
    async initPhysics() {
        // ! this way of importing the rapier module has not been documented anywhere
        // ! and it seems like a bug
        const mod = await import("@dimforge/rapier3d");
        const RAPIER = await mod.default;
        this.RAPIER = RAPIER;
        this.world = new RAPIER.World(GRAVITY);
    }
    addPhysics(mesh, rigidBodyType, colliderType, colliderSettings) {
        let rigidBodyDesc;
        switch (rigidBodyType) {
            case Rapier.RigidBodyType.Fixed:
                rigidBodyDesc = Rapier.RigidBodyDesc.fixed();
                break;
            case Rapier.RigidBodyType.Dynamic:
                rigidBodyDesc = Rapier.RigidBodyDesc.dynamic();
                break;
            default:
                rigidBodyDesc = Rapier.RigidBodyDesc.fixed();
                break;
        }
        const meshWorldPosition = new Vector3();
        mesh.getWorldPosition(meshWorldPosition);
        const meshWorldRotation = new Quaternion();
        mesh.getWorldQuaternion(meshWorldRotation);
        const meshWorldScale = new Vector3();
        mesh.getWorldScale(meshWorldScale);
        rigidBodyDesc.userData = {
            meshInfo: mesh.name,
        };
        let colliderDesc;
        switch (colliderType) {
            case "cuboid":
                {
                    const { width, height, depth } = colliderSettings;
                    colliderDesc = Rapier.ColliderDesc.cuboid(width, height, depth);
                }
                break;
            case "ball":
                {
                    const { radius } = colliderSettings;
                    colliderDesc = Rapier.ColliderDesc.ball(radius);
                }
                break;
            case "capsule":
                {
                    const { halfHeight, radius } = colliderSettings;
                    colliderDesc = Rapier.ColliderDesc.capsule(halfHeight, radius);
                }
                break;
            default:
                {
                    if (mesh instanceof Mesh) {
                        const { colliderDesc: desc } = this.createTrimeshRigidBody(mesh);
                        colliderDesc = desc;
                    }
                    else {
                        throw new Error("Invalid collider");
                    }
                }
                break;
        }
        if (!colliderDesc) {
            console.error("Collider Mesh Error: convex mesh creation failed.");
        }
        // * Responsible for collision detection
        if (!colliderDesc) {
            throw new Error("Collider Mesh Error");
        }
        const rigidBody = this.world.createRigidBody(rigidBodyDesc);
        // rigidBody.setEnabled(true);
        const collider = this.world.createCollider(colliderDesc, rigidBody);
        // collider.setEnabled(true);
        collider.setRestitution(0.001);
        collider.setFriction(0.8);
        const physicsObject = { mesh, collider, rigidBody };
        this.physicsObjects.push(physicsObject);
        if (colliderType === "capsule") {
            const { halfHeight, radius } = colliderSettings;
            rigidBody.setTranslation({
                x: meshWorldPosition.x,
                y: meshWorldPosition.y + halfHeight + radius + 0.01,
                z: meshWorldPosition.z,
            }, true);
        }
        else {
            rigidBody.setTranslation({
                x: meshWorldPosition.x,
                y: meshWorldPosition.y,
                z: meshWorldPosition.z,
            }, true);
        }
        rigidBody.setRotation({
            x: meshWorldRotation.x,
            y: meshWorldRotation.y,
            z: meshWorldRotation.z,
            w: meshWorldRotation.w,
        }, true);
        this.world.step();
        return physicsObject;
    }
    createTrimeshRigidBody(mesh) {
        // Extract world transforms
        const worldPosition = new Vector3();
        mesh.getWorldPosition(worldPosition);
        const worldRotation = new Quaternion();
        mesh.getWorldQuaternion(worldRotation);
        const worldScale = new Vector3();
        mesh.getWorldScale(worldScale);
        const geometry = mesh.geometry.index ? mesh.geometry : mesh.geometry.toNonIndexed();
        // Extract vertices and indices from the geometry
        const vertices = geometry.attributes.position.array;
        const indices = geometry.index ? geometry.index.array : null;
        if (!indices) {
            console.log(mesh);
            throw new Error("Trimesh creation failed: Mesh geometry must have an index.");
        }
        // Scale vertices
        const scaledVertices = new Float32Array(vertices.length);
        for (let i = 0; i < vertices.length; i += 3) {
            scaledVertices[i] = vertices[i] * worldScale.x;
            scaledVertices[i + 1] = vertices[i + 1] * worldScale.y;
            scaledVertices[i + 2] = vertices[i + 2] * worldScale.z;
        }
        // Create the RigidBody (assume static for this example)
        const rigidBodyDesc = Rapier.RigidBodyDesc.fixed();
        const colliderDesc = Rapier.ColliderDesc.trimesh(scaledVertices, indices);
        return {
            colliderDesc,
            rigidBodyDesc,
            worldScale,
            worldPosition,
            worldRotation,
        };
    }
    createDebugMesh(obj) {
        let geometry;
        const collider = obj.collider;
        if (collider.shape.type === ShapeType.Capsule) {
            const shape = collider;
            const radius = shape.radius;
            const halfHeight = shape.halfHeight;
            geometry = new CapsuleGeometry(radius, halfHeight * 2, 16, 16);
        }
        else {
            // console.log(collider.translation());
            const shape = collider;
            const vertices = new Float32Array(shape.vertices);
            const indices = new Uint32Array(shape.indices);
            geometry = new BufferGeometry();
            geometry.setAttribute("position", new BufferAttribute(vertices, 3));
            geometry.setIndex(new BufferAttribute(indices, 1));
        }
        const material = new MeshBasicMaterial({
            wireframe: true,
            color: 0x00ff00,
        });
        const mesh = new Mesh(geometry, material);
        // Debugging: Add a bounding box helper to see the bounds of the mesh
        const box = new BoxHelper(mesh, 0xff0000);
        // this.scene.add(box);
        // Debugging: Log the position and scale
        console.log("Debug Mesh Position:", mesh.position);
        console.log("Debug Mesh Scale:", mesh.scale);
        return mesh;
    }
    removePhysicsObject(object) {
        let foundedIndex = -1;
        this.physicsObjects.map((obj, index) => {
            if (obj.collider === object.collider) {
                foundedIndex = index;
                return;
            }
        });
        if (foundedIndex > 0) {
            this.physicsObjects.splice(foundedIndex, 1);
        }
    }
    getPhysicsObjects() {
        return this.physicsObjects;
    }
    physicsAnimation() {
        this.world.step();
    }
    degubRenderer(scene) {
        if (this._debugMesh) {
            this._debugMesh.geometry.dispose();
            scene.remove(this._debugMesh);
        }
        const mesh = new LineSegments(new BufferGeometry(), new LineBasicMaterial({ color: 0xffffff, vertexColors: true }));
        mesh.frustumCulled = false;
        const { vertices, colors } = this.world.debugRender();
        mesh.geometry.setAttribute("position", new BufferAttribute(vertices, 3));
        mesh.geometry.setAttribute("color", new BufferAttribute(colors, 4));
        mesh.visible = true;
        this._debugMesh = mesh;
        scene.add(this._debugMesh);
    }
}
