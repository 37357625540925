import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from "@mui/material";
import { getIn } from "formik";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
export default function FormToggleButton(props) {
    // const value = NestedArrayResolveStrategy(props.formik.values, props.name);
    const value = getIn(props.formik.values, props.name);
    // const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    const error = getIn(props.formik.errors, props.name);
    return (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(MDTypography, Object.assign({ variant: "caption" }, { children: props.label })), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(Switch, Object.assign({ checked: value, onChange: props.formik.handleChange }, props)) }))] })));
}
