import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import { ETemplateStatus } from "components/features/template/type/TTemplate";
import { url } from "services/ApiEndpoint";
function TemplateCard({ template, actions }) {
    const imgSrc = template.thumbnail
        ? url(`/scene-data/${template.thumbnail}`).value
        : "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg";
    return (_jsxs(Card, Object.assign({ sx: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: template.status === ETemplateStatus.active ? "transparent" : "grey",
            boxShadow: "none",
            overflow: "visible",
        } }, { children: [_jsx(MDBox, Object.assign({ position: "relative", width: "100.25%", shadow: "xl", borderRadius: "xl" }, { children: _jsx(CardMedia, { src: imgSrc, component: "img", title: template.title, sx: {
                        maxWidth: "100%",
                        margin: 0,
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: "cover",
                        objectPosition: "center",
                    } }) })), _jsxs(MDBox, Object.assign({ mt: 1, mx: 0.5, padding: 2 }, { children: [_jsx(MDTypography, Object.assign({ variant: "h5", color: "text", textTransform: "capitalize" }, { children: template.title })), _jsx(MDBox, Object.assign({ mb: 3, lineHeight: 0 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "light", color: "text" }, { children: template.description })) })), _jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: actions === null || actions === void 0 ? void 0 : actions.map((action, key) => {
                            var _a;
                            return action.Component ? (action.Component) : action.type === "internal" ? (_jsx(MDButton, Object.assign({ component: Link, onClick: () => (window.location.href = action.route), variant: "outlined", size: "small", color: action.color }, { children: action.label }), key)) : (_jsx(MDButton, Object.assign({ component: "a", href: (_a = action.route) !== null && _a !== void 0 ? _a : false, target: "_blank", rel: "noreferrer", variant: "outlined", size: "small", color: action.color, onClick: action.cb ? action.cb : null }, { children: action.label }), key));
                        }) }))] }))] })));
}
export default TemplateCard;
