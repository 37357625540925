const PROTOCOL = "https://";
const HOSTNAME = "dev.meet.server.younivr.com/api";
const LOCAL = "https://dev.meet.client.younivr.com/";
const SOCKET_HOST = "https://dev.meet.socket.younivr.com";
const url = (endpoint) => {
    return {
        value: `${PROTOCOL}${HOSTNAME}${endpoint}`,
    };
};
export { PROTOCOL, HOSTNAME, url, LOCAL, SOCKET_HOST };
