import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Grid from "@mui/material/Grid";
// generic components
import MDBox from "components/generic/MDBox";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
// feature component
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import Sidenav from "components/features/profile/components/Sidenav";
import Header from "components/features/profile/components/Header";
import BasicInfo from "components/features/profile/components/BasicInfo";
import ChangePassword from "components/features/profile/components/ChangePassword";
import DeleteAccount from "components/features/profile/components/DeleteAccount";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { useEffect } from "react";
export default function Profile() {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    useEffect(() => {
        console.log(userInfo);
    }, [userInfo]);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), userInfo ? (_jsx(MDBox, Object.assign({ mt: 4 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, lg: 3 }, { children: _jsx(Sidenav, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 9 }, { children: _jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Header, { userInfo: userInfo }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(BasicInfo, { userInfo: userInfo }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(ChangePassword, {}) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(DeleteAccount, {}) }))] })) })) }))] })) }))) : null] }));
}
