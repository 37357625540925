import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
function RefundsCell({ value, icon }) {
    return (_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", px: 2 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: value })), _jsx(MDBox, Object.assign({ color: icon.color, lineHeight: 0 }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" }, fontSize: "small" }, { children: icon.name })) }))] })));
}
export default RefundsCell;
