import { AnimationMixer, Box3, Mesh, Quaternion, SkinnedMesh, Vector3, Vector4, } from "three";
import { Nametag } from "./Nametag";
import { RigidBodyType } from "@dimforge/rapier3d";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SkeletonUtils } from "three-stdlib";
import { LEFT_CLICK_IN_SCENE_MODEL } from "threejs/state/const";
export class Avatar {
    constructor(_baseScene, _modelPath, _id) {
        this._baseScene = _baseScene;
        this._modelPath = _modelPath;
        this._id = _id;
        this._playerLoaded = false;
        this._avatar = null;
        this._avatarPhysics = null;
        this._playerModelSize = null;
        this._playerCapsuleMesure = {
            halfHeight: 0,
            radius: 0,
        };
        ////// ANIMATION //////////////////////////////////
        this._currentAction = "Idle";
        this.animationsMap = new Map();
        this.animationMixer = null;
        this.fadeDuration = 0.02;
        this._calculateObjectSize = (object) => {
            object.updateMatrixWorld(true); // Ensure all matrices are updated
            const bbox = new Box3().setFromObject(object); // Calculate bounding box for the entire object
            const size = new Vector3();
            bbox.getSize(size); // Get the size of the bounding box
            this._playerModelSize = size;
            const radius = size.x / 2 + 0.1;
            const halfHeight = size.y / 2 - radius;
            this._playerCapsuleMesure = { halfHeight, radius };
        };
        this.loadCharacter().then((player) => {
            this._avatar = player;
            this._avatar.userData.id = this._id;
            this.setAvatar();
            this.addPhysics();
            this._playerLoaded = true;
        });
        this._baseScene.renderer.domElement.addEventListener(LEFT_CLICK_IN_SCENE_MODEL, this.sceneClickEvent.bind(this));
    }
    sceneClickEvent(e) {
        if (this._baseScene.sceneInteractionDetail.customItem)
            return;
        for (let i = 0; i < e.detail.intersected3DObjects.length; i++) {
            if (e.detail.intersected3DObjects[i].object instanceof Mesh &&
                e.detail.intersected3DObjects[i].object.type !== "TransformControlsPlane" &&
                !["ImageMesh", "VideoMesh", "ObjectMesh"].includes(e.detail.intersected3DObjects[i].object.type)) {
                this.position = e.detail.intersected3DObjects[i].point;
                break;
            }
        }
    }
    addPhysics() {
        if (!this._avatar)
            return;
        if (!this._playerModelSize)
            this._calculateObjectSize(this._avatar);
        this._avatarPhysics = this._baseScene.physics.addPhysics(this._avatar, RigidBodyType.Dynamic, "capsule", this._playerCapsuleMesure);
        this._avatarPhysics.rigidBody.lockRotations(true, true);
        this._baseScene.physics.world.step();
    }
    loadCharacter() {
        return new Promise((resolve) => {
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath("/draco/");
            const gltfLoader = new GLTFLoader();
            gltfLoader.setDRACOLoader(dracoLoader).load(this._modelPath, (gltf) => {
                const { scene, animations } = gltf;
                const player = SkeletonUtils.clone(scene);
                const animationMaps = this._getAnimationsMap(player, animations);
                if (animationMaps) {
                    this.animationMixer = animationMaps.animationMixer;
                    this.animationsMap = animationMaps.animationsMap;
                }
                resolve(player);
            });
        });
    }
    _getAnimationsMap(player, animationClip) {
        const animationMixer = new AnimationMixer(player);
        const animationsMap = new Map();
        animationClip === null || animationClip === void 0 ? void 0 : animationClip.filter((a) => a.name !== "TPose").forEach((a) => {
            animationsMap.set(a.name, animationMixer.clipAction(a.clone()));
        });
        return { animationsMap, animationMixer };
    }
    setAvatar() {
        if (!this._avatar)
            return;
        this._baseScene.scene.add(this._avatar);
    }
    setNameTag(name) {
        const interval = setInterval(() => {
            if (this._playerLoaded) {
                this._playerName = name;
                this._name = new Nametag(this._baseScene.scene);
                this._nameTag = this._name.createNametag(16, 150, name);
                this._baseScene.scene.add(this._nameTag);
                this._updateNameTagPosition();
                clearInterval(interval);
            }
        }, 500);
    }
    updatePlayerPosition(position) {
        if (!this._avatarPhysics || !this._avatar)
            return;
        this._avatarPhysics.rigidBody.setTranslation({
            x: position.x,
            y: this._avatarPhysics.rigidBody.translation().y,
            z: position.z,
        }, true);
        this._avatar.position.set(this._avatarPhysics.rigidBody.translation().x, this._avatarPhysics.rigidBody.translation().y, this._avatarPhysics.rigidBody.translation().z);
        this._avatar.position.setY(this._avatarPhysics.rigidBody.translation().y -
            (this._playerCapsuleMesure.halfHeight + this._playerCapsuleMesure.radius));
        this._updateNameTagPosition();
    }
    _updateNameTagPosition() {
        if (!this._nameTag || !this._avatarPhysics)
            return;
        this._nameTag.position.set(this._avatarPhysics.rigidBody.translation().x, this._avatarPhysics.rigidBody.translation().y +
            this._playerCapsuleMesure.halfHeight +
            this._playerCapsuleMesure.radius +
            0.1, this._avatarPhysics.rigidBody.translation().z);
    }
    updatePlayerRotation(rotation) {
        if (!this._avatarPhysics || !this._avatar)
            return;
        this._avatarPhysics.rigidBody.setRotation({
            w: rotation.w,
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
        }, true);
        this._avatar.rotation.setFromQuaternion(rotation);
    }
    _playAnimation(_desire, delta) {
        if (_desire !== this._currentAction) {
            const toPlay = this.animationsMap.get(_desire);
            if (this._currentAction) {
                const current = this.animationsMap.get(this._currentAction);
                current === null || current === void 0 ? void 0 : current.fadeOut(this.fadeDuration);
            }
            toPlay === null || toPlay === void 0 ? void 0 : toPlay.reset().fadeIn(this.fadeDuration).play();
            this._currentAction = _desire;
        }
        if (this.animationMixer)
            this.animationMixer.update(delta);
    }
    _deleteAvatar() {
        if (this._avatar) {
            this._avatar.traverse((item) => {
                if (item instanceof Mesh || item instanceof SkinnedMesh) {
                    if (item.geometry)
                        item.geometry.dispose();
                    if (Array.isArray(item.material)) {
                        item.material.forEach((mat) => mat.dispose());
                    }
                    else if (item.material) {
                        item.material.dispose();
                    }
                }
            });
            this._baseScene.scene.remove(this._avatar);
            // console.log("player removed", data.socketId);
        }
        if (this._nameTag) {
            this._nameTag.traverse((item) => {
                if (item instanceof Mesh || item instanceof SkinnedMesh) {
                    if (item.geometry)
                        item.geometry.dispose();
                    if (Array.isArray(item.material)) {
                        item.material.forEach((mat) => mat.dispose());
                    }
                    else if (item.material) {
                        item.material.dispose();
                    }
                }
            });
            this._baseScene.scene.remove(this._nameTag);
        }
        if (this.avatarPhysics) {
            this._baseScene.physics.removePhysicsObject(this.avatarPhysics);
            this._baseScene.physics.world.removeRigidBody(this.avatarPhysics.rigidBody);
        }
    }
    get avatarPhysics() {
        return this._avatarPhysics;
    }
    get model() {
        return this._avatar;
    }
    get currentAnimation() {
        return this._currentAction;
    }
    get avatarPath() {
        return this._modelPath;
    }
    get position() {
        if (!this._avatarPhysics)
            return;
        const pos = this._avatarPhysics.rigidBody.translation();
        return new Vector3(pos.x, pos.y, pos.z);
    }
    get rotation() {
        if (!this._avatarPhysics)
            return;
        const rot = this._avatarPhysics.rigidBody.rotation();
        return new Vector4(rot.x, rot.y, rot.z, rot.w);
    }
    get playerName() {
        return this._playerName;
    }
    set position(pos) {
        this.updatePlayerPosition(pos);
    }
    set rotation(quat) {
        const rot = new Quaternion(quat.x, quat.y, quat.z, quat.w);
        this.updatePlayerRotation(rot);
    }
}
