import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { PresentationControls, Stage, useGLTF } from "@react-three/drei";
import { AnimationMixer } from "three";
import { url } from "services/ApiEndpoint";
import { IconButton } from "@mui/material";
import AnimationIcon from "@mui/icons-material/Animation";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { useFormPost } from "hooks/useFormPost";
import { useShowConfirmation } from "hooks/useShowConfirmation";
const CameraController = () => {
    const { camera } = useThree();
    useEffect(() => {
        // camera.rotateOnAxis(new Vector3(1,0,0),Math.PI/6)
        camera.position.set(0, 0, 1.6);
        camera.updateProjectionMatrix();
    }, [camera]);
    return null;
};
const GLBModelContent = ({ modelUrl, animationIndex }) => {
    // const { scene, animations } = useGLTF(url(`/user/media/${modelUrl}`).value);
    const { scene, animations } = useGLTF(url(`/admin/avatar/model/${modelUrl}`).value);
    const mixer = useRef(null);
    //   const [currentAnimationIndex, setCurrentAnimationIndex] = useState(1);
    useEffect(() => {
        if (animations && animations.length > 0) {
            mixer.current = new AnimationMixer(scene);
            const action = mixer.current.clipAction(animations[animationIndex % animations.length]);
            action.play();
            return () => {
                action.stop();
            };
        }
    }, [scene, animations, animationIndex]);
    useFrame((state, delta) => {
        var _a;
        (_a = mixer.current) === null || _a === void 0 ? void 0 : _a.update(delta);
    });
    //   const handleNextAnimation = () => {
    //     setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
    //   };
    return (_jsxs(_Fragment, { children: [_jsx(CameraController, {}), _jsx("ambientLight", { intensity: 1.0 }), _jsx("pointLight", { position: [10, 10, 10], intensity: 0.8 }), _jsx("directionalLight", { position: [5, 5, 5], intensity: 1, castShadow: true, "shadow-mapSize-width": 1024, "shadow-mapSize-height": 1024, "shadow-camera-far": 50, "shadow-camera-left": -10, "shadow-camera-right": 10, "shadow-camera-top": 10, "shadow-camera-bottom": -10 }), _jsx(PresentationControls, Object.assign({ speed: 1.5, global: true, zoom: 2, polar: [-0.1, Math.PI / 4] }, { children: _jsx(Stage, Object.assign({ environment: null, adjustCamera: false }, { children: _jsx("primitive", { object: scene }) })) }))] }));
};
const GLBModel = ({ modelUrl, _id }) => {
    const [currentAnimationIndex, setCurrentAnimationIndex] = useState(1);
    const handleNextAnimation = () => {
        setCurrentAnimationIndex((prevIndex) => prevIndex + 1);
        console.log(currentAnimationIndex);
    };
    const { execute, status } = useFormPost(url(`/user/media/update/avatar`));
    const { Message, setShow, setTitle, setDescription } = useShowConfirmation();
    const updateAvatar = () => {
        execute(JSON.stringify({
            avatar: _id,
        }));
    };
    useEffect(() => {
        if (status === 200) {
            setTitle("Avatar updated");
            setDescription("Avatar updated successfully");
            setShow(true);
        }
    }, [status]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({ onClick: handleNextAnimation, color: "secondary", "aria-label": "Next Model", sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                }, style: {
                    position: "absolute",
                    top: "75%",
                    right: "10px",
                    padding: "10px",
                    fontSize: "20px",
                    zIndex: 999,
                } }, { children: _jsx(AnimationIcon, {}) })), _jsx(IconButton, Object.assign({ onClick: updateAvatar, color: "success", "aria-label": "Next Model", sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                }, style: {
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    padding: "10px",
                    fontSize: "20px",
                    zIndex: 999,
                } }, { children: _jsx(SwitchAccountIcon, {}) })), _jsx(Canvas, Object.assign({ style: { height: "500px" } }, { children: _jsx(GLBModelContent, { modelUrl: modelUrl, animationIndex: currentAnimationIndex }) })), _jsx(Message, {})] }));
};
export default GLBModel;
