import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MDBox from "components/generic/MDBox";
import { useState } from "react";
export default function TransformControlItem({ transformControl, }) {
    const [currentMode, setCurrentMode] = useState(transformControl.mode);
    const handleTransformationMode = (event, newTransformation) => {
        if (newTransformation !== null) {
            transformControl.mode = newTransformation;
            setCurrentMode(newTransformation);
        }
    };
    return (_jsx(MDBox, Object.assign({ mt: 1.625, mx: 1, sx: {
            display: "flex",
            justifyContent: "space-between",
        } }, { children: _jsxs(ToggleButtonGroup, Object.assign({ color: "primary", value: currentMode, exclusive: true, onChange: handleTransformationMode, "aria-label": "text alignment" }, { children: [_jsx(ToggleButton, Object.assign({ value: "translate", "aria-label": "Translate" }, { children: "Translate" })), _jsx(ToggleButton, Object.assign({ value: "scale", "aria-label": "Scale" }, { children: "Scale" })), _jsx(ToggleButton, Object.assign({ value: "rotate", "aria-label": "Rotate" }, { children: "Rotate" }))] })) })));
}
