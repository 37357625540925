/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import borders from "assets/theme-dark/base/borders";
import colors from "assets/theme-dark/base/colors";
// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme-dark/functions/pxToRem";
const { borderRadius } = borders;
const { light } = colors;
const linearProgress = {
    styleOverrides: {
        root: {
            height: pxToRem(6),
            borderRadius: borderRadius.md,
            overflow: "visible",
            position: "relative",
        },
        colorPrimary: {
            backgroundColor: light.main,
        },
        colorSecondary: {
            backgroundColor: light.main,
        },
        bar: {
            height: pxToRem(6),
            borderRadius: borderRadius.sm,
            position: "absolute",
            transform: `translate(0, 0) !important`,
            transition: "width 0.6s ease !important",
        },
    },
};
export default linearProgress;
