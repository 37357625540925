import { Clock, Quaternion, Vector3 } from "three";
import { Avatar } from "./Avatar";
import { SCENE_PLAYER_LOADED } from "threejs/state/const";
export class AvatarPlayer {
    constructor(_baseScene, mainPlayerPath, _userName) {
        this._baseScene = _baseScene;
        this._userName = _userName;
        this._currentPlayer = null;
        this._clock = new Clock();
        this._walkingVelocity = 1.5;
        this._runningVelocity = 3.0;
        this.walkDirection = new Vector3();
        this.rotateAngle = new Vector3(0, 1, 0);
        this._currentPlayer = new Avatar(this._baseScene, mainPlayerPath, "0");
        if (this._userName) {
            this._currentPlayer.setNameTag(this._userName);
        }
        const prevCallBack = this._baseScene._animationCallBack;
        this._baseScene._animationCallBack = () => {
            prevCallBack();
            this.update();
        };
        this.initController();
        let playerSetTimeout;
        playerSetTimeout = setInterval(() => {
            if (this._currentPlayer.model && this._currentPlayer.avatarPhysics) {
                const playerLoadedEvent = new CustomEvent(SCENE_PLAYER_LOADED, {
                    detail: this._currentPlayer,
                });
                window.dispatchEvent(playerLoadedEvent);
                clearInterval(playerSetTimeout);
            }
        }, 300);
    }
    update() {
        //this._currentPlayer.updatePlayerPosition(new Vector3(1, 5, 1));
        if (!this._currentPlayer.model)
            return;
        const elapsedTime = this._clock.getDelta();
        const newPosition = this.calculatedPlayerPosition(elapsedTime);
        const rotation = this.calculatedPlayerRotation();
        if (newPosition) {
            const phyPosition = this._currentPlayer.avatarPhysics.rigidBody.translation();
            const anticipatingPosition = new Vector3(phyPosition.x, phyPosition.y, phyPosition.z);
            anticipatingPosition.add(newPosition);
            const resultPosition = this.checkAnticipatingPositionAndUpdateRigidBody(anticipatingPosition);
            if (resultPosition) {
                this._currentPlayer.updatePlayerPosition(resultPosition);
            }
        }
        if (rotation) {
            this._currentPlayer.updatePlayerRotation(rotation);
        }
        this._baseScene.physics.world.step();
        // this._baseScene.physics.degubRenderer(this._baseScene.scene);
        this.updateCameraTarget();
    }
    checkAnticipatingPositionAndUpdateRigidBody(anticipatingPosition) {
        const playerPhysics = this._currentPlayer.avatarPhysics;
        if (!playerPhysics)
            return;
        const correctedPosition = new Vector3();
        correctedPosition.copy(anticipatingPosition);
        correctedPosition.y = playerPhysics.rigidBody.translation().y;
        playerPhysics.rigidBody.setTranslation({
            x: correctedPosition.x,
            y: correctedPosition.y,
            z: correctedPosition.z,
        }, true);
        this._baseScene.physics.getPhysicsObjects().map((obj) => {
            if (obj.collider !== playerPhysics.collider) {
                const checkContact = playerPhysics.collider.contactCollider(obj.collider, 0.0002);
                if (checkContact) {
                    const getThreeVector = (val) => {
                        return new Vector3(val.x, val.y, val.z);
                    };
                    const deltanormal = getThreeVector(checkContact.normal1);
                    const isVerticalCollision = Math.abs(deltanormal.y) > Math.abs(deltanormal.x) &&
                        Math.abs(deltanormal.y) > Math.abs(deltanormal.z);
                    if (isVerticalCollision)
                        return;
                    deltanormal.multiplyScalar(checkContact.distance);
                    if (deltanormal.y < 0)
                        deltanormal.y = 0;
                    correctedPosition.set(playerPhysics.rigidBody.translation().x + deltanormal.x, playerPhysics.rigidBody.translation().y + deltanormal.y, playerPhysics.rigidBody.translation().z + deltanormal.z);
                }
            }
        });
        return correctedPosition;
    }
    initController() {
        const maxAngle = Math.PI / 2.1;
        const minAngle = Math.PI / 4;
        this._baseScene.orbitController.minDistance = 3.5;
        this._baseScene.orbitController.maxDistance = 1;
        this._baseScene.orbitController.maxPolarAngle = maxAngle;
        this._baseScene.orbitController.minPolarAngle = minAngle;
        this._baseScene.orbitController.screenSpacePanning = false;
    }
    updateCameraTarget() {
        if (!this._currentPlayer.model)
            return;
        const playerPosition = this._currentPlayer.model.position;
        // this._baseScene.camera.position.x = playerPosition.x;
        // this._baseScene.camera.position.y = playerPosition.y + 2;
        // this._baseScene.camera.position.z = playerPosition.z;
        const cameraTarget = new Vector3();
        cameraTarget.copy(playerPosition);
        cameraTarget.y += 1;
        this._baseScene.orbitController.target = cameraTarget;
    }
    directionOffset() {
        let directionOffset = 0; // w
        let keyPressing = false;
        let isRunning = false;
        let isJumping = false;
        let directionKeyPressing = false;
        const keysPressed = this._baseScene.playerController.keys();
        if (keysPressed.forward) {
            if (keysPressed.left) {
                directionOffset = Math.PI / 4; // w+a
            }
            else if (keysPressed.right) {
                directionOffset = -Math.PI / 4; // w+d
            }
            keyPressing = true;
            directionKeyPressing = true;
        }
        else if (keysPressed.backward) {
            if (keysPressed.left) {
                directionOffset = Math.PI / 4 + Math.PI / 2; // s+a
            }
            else if (keysPressed.right) {
                directionOffset = -Math.PI / 4 - Math.PI / 2; // s+d
            }
            else {
                directionOffset = Math.PI; // s
            }
            keyPressing = true;
            directionKeyPressing = true;
        }
        else if (keysPressed.left) {
            directionOffset = Math.PI / 2; // a
            keyPressing = true;
            directionKeyPressing = true;
        }
        else if (keysPressed.right) {
            directionOffset = -Math.PI / 2; // d
            keyPressing = true;
            directionKeyPressing = true;
        }
        if (keysPressed.shift) {
            keyPressing = true;
            isRunning = true;
        }
        if (keysPressed.space) {
            keyPressing = true;
            isJumping = true;
        }
        return {
            directionOffset,
            keyPressing,
            isRunning,
            isJumping,
            directionKeyPressing,
        };
    }
    calculatedPlayerRotation() {
        const { directionOffset, keyPressing } = this.directionOffset();
        if (!keyPressing || !this._currentPlayer.avatarPhysics || !this._currentPlayer.model)
            return;
        const rotateQuatranion = new Quaternion();
        const angleYCameraDirection = Math.atan2(-(this._baseScene.camera.position.x -
            this._currentPlayer.avatarPhysics.rigidBody.translation().x), -(this._baseScene.camera.position.z -
            this._currentPlayer.avatarPhysics.rigidBody.translation().z));
        rotateQuatranion.setFromAxisAngle(this.rotateAngle, angleYCameraDirection + directionOffset);
        const newQuat = new Quaternion();
        newQuat.setFromEuler(this._currentPlayer.model.rotation).rotateTowards(rotateQuatranion, 0.2);
        return newQuat;
    }
    calculatedPlayerPosition(time) {
        const { directionOffset, isRunning, isJumping, directionKeyPressing } = this.directionOffset();
        this._currentPlayer._playAnimation(isRunning && directionKeyPressing
            ? "Running"
            : isJumping
                ? "Jump"
                : directionKeyPressing
                    ? "Walking"
                    : "Idle", time);
        if (!directionKeyPressing)
            return;
        this._baseScene.camera.getWorldDirection(this.walkDirection);
        this.walkDirection.normalize();
        this.walkDirection.applyAxisAngle(this.rotateAngle, directionOffset);
        const moveX = this.walkDirection.x * (isRunning ? this._runningVelocity : this._walkingVelocity) * time;
        const moveZ = this.walkDirection.z * (isRunning ? this._runningVelocity : this._walkingVelocity) * time;
        const moveY = this.walkDirection.y * (isJumping ? 0 : 0) * time; // have to check with gravity
        const newPosition = new Vector3(moveX, moveY, moveZ);
        return newPosition;
    }
    get currentPlayer() {
        return this._currentPlayer;
    }
}
