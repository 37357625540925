import { WebGLRenderer, PerspectiveCamera, AmbientLight, DirectionalLight, Raycaster, } from "three";
import { PlayerControllerInput } from "./PlayerControllerInput";
import { Physics } from "./Physics";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
export class BaseScene {
    constructor(_mode) {
        this._mode = _mode;
        this._player = null;
        this._physics = new Physics();
        this._raycaster = new Raycaster();
        this._animationCallBack = () => { };
        this.destructionCallback = () => { };
        this.animation = () => {
            if (this._scene && this._camera) {
                this._animationCallBack();
                this._orbitControl.update();
                this._webGLRenderer.render(this._scene, this._camera);
            }
            requestAnimationFrame(this.animation);
        };
    }
    // to create container
    createDOM(container) {
        const el = document.createElement("div");
        el.id = "scene";
        el.style.height = "100%";
        el.style.width = "100%";
        container.appendChild(el);
        this._containerDOM = el;
        return el;
    }
    // to create canvas only
    createCanvas(el) {
        const cx = document.createElement("canvas");
        cx.style.height = "100%";
        cx.style.width = "100%";
        el.appendChild(cx);
        this._canvas = cx;
    }
    addWebGLRenderer() {
        if (!this._webGLRenderer) {
            this._webGLRenderer = new WebGLRenderer({
                canvas: this._canvas,
                antialias: true,
            });
            this._webGLRenderer.setSize(this._containerDOM.clientWidth, this._containerDOM.clientHeight);
            const handleResize = () => {
                const { clientWidth, clientHeight } = this._containerDOM;
                if (this.camera instanceof PerspectiveCamera) {
                    //@ts-ignore
                    this._camera.aspect = clientWidth / clientHeight;
                    this.camera.updateProjectionMatrix();
                }
                this._webGLRenderer.setSize(clientWidth, clientHeight);
            };
            window.addEventListener("resize", handleResize);
        }
    }
    addCameraToScene() {
        if (!this._camera) {
            this._camera = new PerspectiveCamera(75, this._containerDOM.clientWidth / this._containerDOM.clientHeight);
            this._camera.position.z = 3;
        }
        this._scene.add(this._camera);
    }
    addOrbitControlToScene() {
        if (!this._orbitControl && this._camera && this._webGLRenderer) {
            this._orbitControl = new OrbitControls(this._camera, this._webGLRenderer.domElement);
        }
        this._orbitControl.update();
    }
    addLightsToScene() {
        if (!this._lights) {
            const ambientLight = new AmbientLight(0xffffff, 1);
            const directionLight = new DirectionalLight();
            this._lights = [ambientLight, directionLight];
        }
        this._lights.map((l) => this._scene.add(l));
    }
    addTransformControlToScene() {
        if (!this._transformControl && this._camera && this._webGLRenderer) {
            this._transformControl = new TransformControls(this._camera, this._webGLRenderer.domElement);
            this._transformControl.addEventListener("dragging-changed", (event) => {
                this._orbitControl.enabled = !event.value;
            });
            this._scene.add(this._transformControl);
        }
        return this._transformControl;
    }
    addPlayerController() {
        this._playerController = new PlayerControllerInput(this);
    }
    get renderer() {
        return this._webGLRenderer;
    }
    get scene() {
        return this._scene;
    }
    get camera() {
        return this._camera;
    }
    get playerController() {
        return this._playerController;
    }
    get transformControl() {
        return this._transformControl;
    }
    set sceneInteractionDetail(detail) {
        this._sceneInteractionDetail = detail;
    }
    get sceneInteractionDetail() {
        return this._sceneInteractionDetail;
    }
    get player() {
        return this._player;
    }
    get physics() {
        return this._physics;
    }
    get orbitController() {
        return this._orbitControl;
    }
    get sceneMode() {
        return this._mode;
    }
    destroy() {
        this._containerDOM.parentElement.removeChild(this._containerDOM); // destroy dom
        delete this._scene;
        delete this._camera;
        delete this._transformControl;
        delete this._canvas;
        delete this._webGLRenderer;
        delete this._canvas;
        delete this._containerDOM;
        delete this._playerController;
        delete this._player;
        delete this._physics;
        delete this._animationCallBack;
        this.destructionCallback();
    }
}
