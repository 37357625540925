import { jsx as _jsx } from "react/jsx-runtime";
// @mui material components
import List from "@mui/material/List";
function SidenavList({ children }) {
    return (_jsx(List, Object.assign({ sx: {
            px: 2,
            my: 0.3,
        } }, { children: children })));
}
export default SidenavList;
