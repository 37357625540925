import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
// NewProduct page components
import ProductInfo from "page/ecommerce/products/new-product/components/ProductInfo";
import Media from "page/ecommerce/products/new-product/components/Media";
import Socials from "page/ecommerce/products/new-product/components/Socials";
import Pricing from "page/ecommerce/products/new-product/components/Pricing";
function getSteps() {
    return ["1. Product Info", "2. Media", "3. Social", "4. Pricing"];
}
function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return _jsx(ProductInfo, {});
        case 1:
            return _jsx(Media, {});
        case 2:
            return _jsx(Socials, {});
        case 3:
            return _jsx(Pricing, {});
        default:
            return null;
    }
}
function NewProduct() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ mt: 5, mb: 9 }, { children: _jsx(Grid, Object.assign({ container: true, justifyContent: "center" }, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, lg: 8 }, { children: [_jsxs(MDBox, Object.assign({ mt: 6, mb: 8, textAlign: "center" }, { children: [_jsx(MDBox, Object.assign({ mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "h3", fontWeight: "bold" }, { children: "Add New Product" })) })), _jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "regular", color: "secondary" }, { children: "This information will describe more about the product." }))] })), _jsxs(Card, { children: [_jsx(MDBox, Object.assign({ mt: -3, mb: 3, mx: 2 }, { children: _jsx(Stepper, Object.assign({ activeStep: activeStep, alternativeLabel: true }, { children: steps.map((label) => (_jsx(Step, { children: _jsx(StepLabel, { children: label }) }, label))) })) })), _jsx(MDBox, Object.assign({ p: 2 }, { children: _jsxs(MDBox, { children: [getStepContent(activeStep), _jsxs(MDBox, Object.assign({ mt: 3, width: "100%", display: "flex", justifyContent: "space-between" }, { children: [activeStep === 0 ? (_jsx(MDBox, {})) : (_jsx(MDButton, Object.assign({ variant: "gradient", color: "light", onClick: handleBack }, { children: "back" }))), _jsx(MDButton, Object.assign({ variant: "gradient", color: "dark", onClick: !isLastStep ? handleNext : undefined }, { children: isLastStep ? "send" : "next" }))] }))] }) }))] })] })) })) }))] }));
}
export default NewProduct;
