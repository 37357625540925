import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "components/generic/Navbars/DefaultNavbar";
// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "routes/page.routes";
// Images
import bgImage from "assets/images/bg-pricing.jpg";
function Header({ tabValue, tabHandler, children }) {
    return (_jsxs(_Fragment, { children: [_jsx(DefaultNavbar, { routes: pageRoutes, action: {
                    type: "external",
                    route: "https://creative-tim.com/product/material-dashboard-2-pro-react-ts",
                    label: "buy now",
                    color: "light",
                }, transparent: true, light: true }), _jsx(MDBox, Object.assign({ position: "relative", minHeight: "50vh", height: "50vh", borderRadius: "xl", m: 2, pt: 2, sx: {
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) => //todo any
                     `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3, justifyContent: "center", sx: { position: "relative", py: 22, textAlign: "center" } }, { children: _jsxs(Grid, Object.assign({ item: true, xs: 11, lg: 5 }, { children: [_jsx(MDBox, Object.assign({ mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "h2", color: "white", fontWeight: "bold" }, { children: "Pick the best plan for you" })) })), _jsx(MDBox, Object.assign({ mb: 2 }, { children: _jsx(MDTypography, Object.assign({ variant: "body2", color: "white", fontWeight: "light" }, { children: "You have Free Unlimited Updates and Premium Support on each package." })) }))] })) })) })), _jsx(Grid, Object.assign({ container: true, sx: { px: 6, my: 8 } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Card, Object.assign({ sx: { mt: -16 } }, { children: [_jsx(MDBox, Object.assign({ minWidth: { xs: "22rem", md: "25rem" }, mx: "auto", mt: 6 }, { children: _jsx(AppBar, Object.assign({ position: "static" }, { children: _jsxs(Tabs, Object.assign({ value: tabValue, onChange: tabHandler }, { children: [_jsx(Tab, { id: "monthly", label: _jsx(MDBox, Object.assign({ py: 0.5, px: 2, color: "inherit" }, { children: "Monthly" })) }), _jsx(Tab, { id: "annual", label: _jsx(MDBox, Object.assign({ py: 0.5, px: 2, color: "inherit" }, { children: "Annual" })) })] })) })) })), children] })) })) }))] }));
}
export default Header;
