import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import { getIn } from "formik";
export default function FormFileInput(props) {
    const value = getIn(props.formik.values, props.name);
    // const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    const error = getIn(props.formik.errors, props.name);
    const handleChange = (event) => {
        const files = event.target.files;
        if (!files)
            return;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = (e) => {
            console.log(files);
            if (typeof reader.result == "string") {
                const f = files[0];
                const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                const newName = removeAccents(f.name).replaceAll(" ", "_");
                const blob = f.slice(0, f.size, f.type);
                const file = new File([blob], newName);
                props.formik.setFieldValue(props.name, file);
            }
        };
    };
    return (_jsxs(MDBox, Object.assign({ mt: 0.75 }, { children: [_jsx(MDInput, Object.assign({ variant: "outlined", fullWidth: true, onChange: handleChange, error: error ? true : false }, props, { type: "file" })), error ? (_jsx(MDBox, Object.assign({ style: {
                    marginTop: 15,
                } }, { children: _jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "error", fontWeight: "regular" }, { children: error.toString() })) }))) : null] })));
}
