import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, IconButton } from "@mui/material";
import SceneLoader from "threejs/component/SceneLoader";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import MDBox from "components/generic/MDBox";
import ModelMediaEditor from "threejs/component/ModelMediaEditor";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { SCENE_LOADED, UPDATE_UI } from "threejs/state/const";
export default function ModelLoader(props) {
    const [isOpen, setIsOpen] = useState(true); // État pour gérer si l'overlay est ouvert ou fermé
    const [sceneItemList, setSceneItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [baseScene, setBaseScene] = useState(null);
    useEffect(() => {
        window.addEventListener(SCENE_LOADED, (e) => {
            setBaseScene(e.detail);
            e.detail.domElement.addEventListener(UPDATE_UI, (e) => {
                setSceneItemList([...e.detail.itemList]);
                setSelectedItem(e.detail.selectedItem);
            });
        });
        return () => {
            window.removeEventListener(SCENE_LOADED, () => { });
        };
    }, []);
    const toggleOverlay = () => {
        setIsOpen(!isOpen);
    };
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    return userInfo ? (_jsx(MDBox, Object.assign({ sx: { height: "100%", width: "100%", position: "relative" } }, { children: _jsxs(Grid, Object.assign({ container: true, sx: { height: "100%", position: "relative" } }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 12, lg: 12, sx: { flexGrow: 1 } }, { children: [_jsx(MDBox, { id: "scene-container", style: { position: "relative", width: "100%", height: "100%" } }), _jsx(SceneLoader, { mode: "Edit", containerId: "scene-container", templateInfo: props.template, roomInfo: {
                                _id: props.roomId,
                            }, userInfo: userInfo })] })), _jsxs(MDBox, Object.assign({ style: { zIndex: 1 } }, { children: [isOpen && baseScene && (_jsx(MDBox, Object.assign({ sx: {
                                position: "absolute",
                                top: 0,
                                right: 0,
                                height: "100%",
                                width: { xs: "100%", md: "33.33%", lg: "25%" },
                                // zIndex: "modal",
                                backgroundColor: "rgba(255, 255, 255, 0.95)",
                                pointerEvents: "auto",
                                boxShadow: "inset 5px 0 0 0 rgba(0, 0, 0, 0.3)", // Shadow properties
                            } }, { children: _jsx(ModelMediaEditor, { roomId: props.roomId, baseScene: baseScene, sceneItemList: sceneItemList, selectedItem: selectedItem }) }))), _jsx(IconButton, Object.assign({ onClick: toggleOverlay, color: "primary", sx: {
                                position: "absolute",
                                top: 10,
                                right: isOpen ? { xs: "100%", md: "33.33%", lg: "25%" } : 0, // Adjust position based on state
                                // zIndex: "tooltip",
                            } }, { children: baseScene ? isOpen ? _jsx(ChevronRight, {}) : _jsx(ChevronLeft, {}) : null }))] }))] })) }))) : null;
}
