import { url } from "services/ApiEndpoint";
import { YounivrObjectLoader } from "./YounivrObjectLoader";
import { SAVE_CUSTOM_ITEMS_DETAILS } from "threejs/state/const";
export class MediaHandler {
    constructor(_groupToAdd, _roomRef, _baseScene) {
        this._groupToAdd = _groupToAdd;
        this._roomRef = _roomRef;
        this._baseScene = _baseScene;
        this.loadMediaFromServer();
        this._baseScene.renderer.domElement.addEventListener(SAVE_CUSTOM_ITEMS_DETAILS, async () => {
            const api = url(`/room/set/data/${this._roomRef._id}`);
            const stringData = JSON.stringify(this._groupToAdd.toJSON());
            const r = await fetch(api.value, {
                method: "POST",
                body: stringData,
                headers: new Headers({ "Content-Type": "application/json" }),
            });
            await r.json();
        });
    }
    async loadMediaFromServer() {
        const api = url(`/room/get/data/${this._roomRef._id}`);
        const r = await fetch(api.value);
        const result = await r.json();
        if (Object.keys(result).length < 1)
            return;
        new YounivrObjectLoader(this._groupToAdd, this._baseScene).parse(result, () => { });
    }
}
