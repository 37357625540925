import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import MDTypography from "components/generic/MDTypography";
export default function RoomDelete(props) {
    const [open, setOpen] = React.useState(false);
    const { execute, status } = useFormGet(url(`/room/delete/${props.id}`));
    const { execute: deleteRoomMediaExecute, status: deleteRoomMediaStatus } = useFormGet(url(`/room/media/delete/${props.id}`));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const processDelete = async () => {
        await execute();
        await deleteRoomMediaExecute();
        setOpen(false);
    };
    useEffect(() => {
        if (status && status === 200) {
            window.location.reload();
        }
    }, [status]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Delete", placement: "bottom" }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: "primary", lineHeight: 1, sx: { cursor: "pointer", mx: 3 } }, { children: _jsx(IconButton, Object.assign({ onClick: handleClickOpen }, { children: _jsx(DeleteIcon, {}) })) })) })), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogTitle, Object.assign({ id: "alert-dialog-title" }, { children: props.title })), _jsx(DialogContent, { children: _jsxs(DialogContentText, Object.assign({ id: "alert-dialog-description" }, { children: ["Voulez-vous vraiment supprimer le room ", props.title] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: handleClose }, { children: "Fermer" })), _jsx(Button, Object.assign({ onClick: processDelete, autoFocus: true }, { children: "Supprimer" }))] })] }))] }));
}
