import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import ProductInfo from "components/features/ecommerce/product/product-details/components/ProductInfo";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { Suspense } from "react";
function ProductDetailPopUpPage({ _id = "1", title = "TITLE", description = "DESCRIPTION", price = 230, type, src, }) {
    return (_jsx(MDBox, Object.assign({ py: 3 }, { children: _jsx(Card, Object.assign({ sx: { overflow: "visible" } }, { children: _jsxs(MDBox, Object.assign({ p: 3 }, { children: [_jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "medium" }, { children: "Product Details" })) })), _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, lg: 6, xl: 5 }, { children: _jsx(MDBox, { children: type === "ImagePlane" ? (_jsx(MDBox, { component: "img", src: src, alt: "Product Image", shadow: "lg", borderRadius: "lg", width: "100%", crossOrigin: "anonymous" })) : type === "VideoPlane" ? (_jsx(MDBox, { component: "video", src: src, alt: "Product Image", shadow: "lg", borderRadius: "lg", width: "100%", crossOrigin: "anonymous", autoPlay: true, loop: true })) : type === "CustomObject" ? (_jsx(MDBox, Object.assign({ component: "div", height: "100%" }, { children: _jsx(LoadCustomObject, { src: src }) }))) : null }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 5, sx: { mx: "auto" } }, { children: _jsx(ProductInfo, { description: description, title: title }) }))] }))] })) })) })));
}
const Model = ({ src }) => {
    const { scene } = useGLTF(src); // `useGLTF` directly used within the component
    return _jsx("primitive", { object: scene });
};
const LoadCustomObject = ({ src }) => {
    return (_jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading" }) }, { children: _jsxs(Canvas, Object.assign({ dpr: [1, 2], shadows: true, camera: { fov: 45, position: [0, 0, 2.5] }, style: { height: "600px", width: "350px" } }, { children: [_jsx("ambientLight", {}), _jsx(Model, { src: src }), " ", _jsx(OrbitControls, { enableZoom: true })] })) })));
};
export default ProductDetailPopUpPage;
