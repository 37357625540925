import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import ProductDetailPopUpPage from "./ProductDetailPopUpPage";
import { YOUNIVR_OBJECT_CLICKED } from "threejs/state/const";
export default function CustomPopup() {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setSelectedItem(null);
        setOpen(false);
    };
    useEffect(() => {
        //@ts-expect-error
        document.addEventListener(YOUNIVR_OBJECT_CLICKED, (e) => {
            const clickedObject = e.detail;
            const item = clickedObject.meshToData();
            setSelectedItem(item);
            handleClickOpen();
        });
    }, []);
    return (_jsx(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", fullWidth: true, maxWidth: "lg" }, { children: _jsx(DialogContent, { children: _jsx(Box, Object.assign({ noValidate: true, component: "form", sx: {
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "fit-content",
                } }, { children: selectedItem ? (_jsx(ProductDetailPopUpPage, { _id: "1", title: selectedItem.otherDatas.displayName, description: selectedItem.otherDatas.description, price: 230, 
                    //@ts-ignore
                    type: selectedItem.name, src: selectedItem.otherDatas.src })) : null })) }) })));
}
