var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
// Material Dashboard 2 PRO React TS components
// Custom styles for the MDSnackbar
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
import MDBox from "../MDBox";
import MDSnackbarIconRoot from "./MDSnackbarIconRoot";
import MDTypography from "../MDTypography";
function MDSnackbar(_a) {
    var { color = "info", icon, title, dateTime, content, close, bgWhite = false } = _a, rest = __rest(_a, ["color", "icon", "title", "dateTime", "content", "close", "bgWhite"]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    let titleColor;
    let dateTimeColor;
    let dividerColor;
    if (bgWhite) {
        titleColor = color;
        dateTimeColor = "dark";
        dividerColor = false;
    }
    else if (color === "light") {
        titleColor = darkMode ? "inherit" : "dark";
        dateTimeColor = darkMode ? "inherit" : "text";
        dividerColor = false;
    }
    else {
        titleColor = "white";
        dateTimeColor = "white";
        dividerColor = true;
    }
    return (_jsx(Snackbar, Object.assign({ TransitionComponent: Fade, autoHideDuration: 5000, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        } }, rest, { action: _jsx(IconButton, Object.assign({ size: "small", "aria-label": "close", color: "inherit", onClick: close }, { children: _jsx(Icon, Object.assign({ fontSize: "small" }, { children: "close" })) })) }, { children: _jsxs(MDBox, Object.assign({ variant: bgWhite ? "contained" : "gradient", bgColor: bgWhite ? "white" : color, minWidth: "21.875rem", maxWidth: "100%", shadow: "md", borderRadius: "md", p: 1, sx: {
                backgroundColor: ({ palette }) => darkMode ? palette.background.card : palette[color] || palette.white.main,
            } }, { children: [_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", color: "dark", p: 1.5 }, { children: [_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center", lineHeight: 0 }, { children: [_jsx(MDSnackbarIconRoot, Object.assign({ fontSize: "small", ownerState: { color, bgWhite } }, { children: icon })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "medium", color: titleColor, textGradient: bgWhite }, { children: title }))] })), _jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center", lineHeight: 0 }, { children: [_jsx(MDTypography, Object.assign({ variant: "caption", color: dateTimeColor }, { children: dateTime })), _jsx(Icon, Object.assign({ sx: {
                                        color: ({ palette: { dark, white } }) => (bgWhite && !darkMode) || color === "light" ? dark.main : white.main,
                                        fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                                        cursor: "pointer",
                                        marginLeft: 2,
                                        transform: "translateY(-1px)",
                                    }, onClick: close }, { children: "close" }))] }))] })), _jsx(Divider, { sx: { margin: 0 }, light: dividerColor }), _jsx(MDBox, Object.assign({ p: 1.5, sx: {
                        fontSize: ({ typography: { size } }) => size.sm,
                        color: ({ palette }) => {
                            let colorValue = bgWhite || color === "light" ? palette.text.main : palette.white.main;
                            if (darkMode) {
                                colorValue = color === "light" ? "inherit" : palette.white.main;
                            }
                            return colorValue;
                        },
                    } }, { children: content }))] })) })));
}
export default MDSnackbar;
