import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// react-router components
import { useLocation } from "react-router-dom";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDBadge from "components/generic/MDBadge";
// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "components/features/Breadcrumbs";
import NotificationItem from "components/generic/Items/NotificationItem";
// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarDesktopMenu, navbarMobileMenu, } from "components/generic/Navbars/DashboardNavbar/styles";
// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator, } from "contexts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signOut } from "redux-store/UserSlice";
function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
    const isConnected = useSelector((state) => state.user.isLoggedIn);
    const userInitial = useSelector((state) => state.user.userInitial);
    const dispatchRoot = useDispatch();
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        }
        else {
            setNavbarType("static");
        }
        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }
        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
        */
        window.addEventListener("scroll", handleTransparentNavbar);
        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();
        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);
    // Render the notifications menu
    const renderMenu = () => (_jsx(Menu, Object.assign({ anchorEl: openMenu, anchorReference: null, anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        }, open: Boolean(openMenu), onClose: handleCloseMenu, sx: { mt: 2 } }, { children: _jsx(NotificationItem, Object.assign({ icon: _jsx(Icon, { children: "logout" }), title: "Logout", description: "Logout", onClick: () => {
                dispatchRoot(signOut());
            } }, { children: "Logout" })) })));
    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba }, }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;
            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }
            return colorValue;
        },
    });
    return (_jsx(AppBar, Object.assign({ position: absolute ? "absolute" : navbarType, color: "inherit", sx: (theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode }) }, { children: _jsxs(Toolbar, Object.assign({ sx: navbarContainer }, { children: [_jsxs(MDBox, Object.assign({ color: "inherit", mb: { xs: 1, md: 0 }, sx: (theme) => navbarRow(theme, { isMini }) }, { children: [_jsx(Breadcrumbs, { icon: "home", title: route[route.length - 1], route: route, light: light }), _jsx(IconButton, Object.assign({ sx: navbarDesktopMenu, onClick: handleMiniSidenav, size: "small", disableRipple: true }, { children: _jsx(Icon, Object.assign({ fontSize: "medium", sx: iconsStyle }, { children: miniSidenav ? "menu_open" : "menu" })) }))] })), isMini ? null : (_jsx(MDBox, Object.assign({ sx: (theme) => navbarRow(theme, { isMini }) }, { children: _jsxs(MDBox, Object.assign({ color: light ? "white" : "inherit" }, { children: [_jsx(IconButton, Object.assign({ size: "small", disableRipple: true, color: "inherit", sx: navbarMobileMenu, onClick: handleMiniSidenav }, { children: _jsx(Icon, Object.assign({ sx: iconsStyle, fontSize: "medium" }, { children: miniSidenav ? "menu_open" : "menu" })) })), _jsx(IconButton, Object.assign({ size: "small", color: "inherit", sx: navbarIconButton }, { children: _jsx(MDBadge, Object.assign({ badgeContent: 0, color: "error", size: "xs", circular: true }, { children: _jsx(Icon, Object.assign({ sx: iconsStyle }, { children: "shopping_cart" })) })) })), _jsx(IconButton, Object.assign({ size: "small", color: "inherit", sx: navbarIconButton, onClick: handleOpenMenu }, { children: _jsx(MDBadge, Object.assign({ badgeContent: 0, color: "error", size: "xs", circular: true }, { children: _jsx(Icon, Object.assign({ sx: iconsStyle }, { children: "settings" })) })) })), renderMenu()] })) })))] })) })));
}
export default DashboardNavbar;
