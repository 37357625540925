import { BaseScene } from "./BaseScene";
export class KankanScene extends BaseScene {
    get sceneType() {
        return "Kankan";
    }
    constructor(model, _mode, _resourcePath) {
        super(_mode);
        this.model = model;
        this._resourcePath = _resourcePath;
        this.resetKankanItemsToBaseScene = () => {
            this._scene = this._kankanInstance.core.__private_1_components.SceneRenderer.scene;
            this._camera = this._kankanInstance.core.__private_1_components.SceneRenderer.camera;
            this._orbitControl =
                this._kankanInstance.core.__private_1_components.CameraControls.activeControl;
            this._webGLRenderer = this._kankanInstance.core.__private_1_components.SceneRenderer.renderer;
            // console.log(this._kankanInstance.core.__private_1_components.Player);
            this._kankanInstance.core.__private_1_components.Player.onMouseDown = (e) => {
                const { target } = e;
                const clonedNativeEvent = new MouseEvent("mousedown", e);
                // const clonedNativeEvent = new MouseEvent('mousedown'); // TO PREVENT KANKAN BEHAVIER
                target.dispatchEvent(clonedNativeEvent);
            };
            // this._kankanInstance.core.__private_1_components.Player.update = () => {}
            this._kankanInstance.core.__private_1_components.Player.onMouseUp = (e) => {
                const { target } = e;
                const clonedNativeEvent = new MouseEvent("mouseup", e);
                // const clonedNativeEvent = new MouseEvent('mouseup'); // TO PREVENT KANKAN BEHAVIER
                target.dispatchEvent(clonedNativeEvent);
            };
            const prevAnimation = this._kankanInstance.core.__private_1_components.SceneRenderer.animate;
            this._kankanInstance.core.__private_1_components.SceneRenderer.animate = () => {
                prevAnimation();
                if (!this._scene || !this._camera)
                    return;
                this._animationCallBack();
            };
            this.addTransformControlToScene();
        };
    }
    createInstance() {
        if (!this._containerDOM) {
            throw new Error("container is required");
        }
        const id = `#${this._containerDOM.getAttribute("id")}`;
        //@ts-expect-error-ignore
        const kankan = window.KanKan;
        // var kankan = new (window as any).KanKan();
        const Scene = new kankan({
            deploy: "local",
            dom: id,
            lang: "en",
            num: this.model,
            resource: this._resourcePath,
            server: "",
            // scene: {
            //   // Point Transparency
            //   markerOpacity: 1,
            //   // Customize Point Icons
            //   markerURL: "https://png.pngtree.com/element_pic/00/16/09/2057e0eecf792fb.jpg",
            // },
            // tag: {
            //   showIn: "panorama,floorplan,dollhouse",
            // },
        });
        this._kankanInstance = Scene;
    }
    getScene() {
        this.createInstance();
        return new Promise((resolve, _) => {
            this.render();
            const interval = () => setTimeout(() => {
                onLoadModel();
            }, 300);
            const onLoadModel = () => {
                const threeObj = this._kankanInstance.core.__private_1_components.Scene;
                if (threeObj.loaded) {
                    this.resetKankanItemsToBaseScene();
                    this.addPlayerController();
                    resolve(this._scene);
                    clearInterval(interval());
                }
                else {
                    // console.log(this._kankanInstance);
                    interval();
                }
            };
            interval();
        });
    }
    render() {
        this._kankanInstance.render();
    }
    getInstance() {
        return this._kankanInstance;
    }
}
