import { jsx as _jsx } from "react/jsx-runtime";
import FormSelect from "components/generic/form/FormSelect";
import { useEffect, useState } from "react";
import { LOCAL, url } from "services/ApiEndpoint";
export default function Image({ item, formData, id, }) {
    const [element, setElement] = useState(item.meshToData());
    const [availableImages, setAvailableImages] = useState([]);
    const [opt, setOpt] = useState([]);
    const prevHander = formData.handleChange;
    useEffect(() => {
        const u = url(`/room/media/get/${id}/images`).value;
        fetch(u).then(async (res) => {
            const r = await res.json();
            setAvailableImages(r);
        });
    }, []);
    formData.handleChange = (e) => {
        updateImage(e);
        prevHander(e);
    };
    const updateImage = (e) => {
        // const src = url(`/room/media/get/${id}${e.target.value}`).value;
        element.otherDatas.src = e.target.value;
        item.dataToMesh(element);
    };
    useEffect(() => {
        console.log(item.meshToData());
        const o = [
            {
                key: `${LOCAL}/static/placeholder.svg`,
                value: `placeholder.svg`,
            },
        ];
        availableImages.map((op) => {
            o.push({
                key: url(`/room/media/get/${id}${op.src}`).value,
                value: op.displayName,
            });
        });
        setOpt(o);
    }, [availableImages]);
    return opt.length > 0 ? _jsx(FormSelect, { formik: formData, name: "mediaValue", options: opt }) : null;
}
