import { CircleGeometry, Group, MeshStandardMaterial, Mesh, LineBasicMaterial, BufferGeometry, Line, Vector3, } from "three";
import { SceneItem } from "threejs/model/SceneItem";
export class TagMesh extends Group {
    constructor() {
        super();
        this._normal = new Vector3();
        this.type = "TagMesh";
        this.name = "YounivrTag";
        this._circleColor = "#FF0000";
    }
    dataToMesh(data) {
        this.clear();
        const { name, position, scale, otherDatas } = data;
        this._position = position;
        this.scale.copy(scale);
        this.name = name;
        if (!otherDatas.displayName)
            otherDatas.displayName = this.uuid;
        if (otherDatas.normal) {
            this._normal = new Vector3(otherDatas.normal.x, otherDatas.normal.y, otherDatas.normal.z);
        }
        if (otherDatas.circleColor)
            this._circleColor = otherDatas.circleColor;
        this.userData = otherDatas;
        console.log(this.userData);
        this.addTag();
    }
    meshToData() {
        const { _position, rotation, scale, name, uuid, userData } = this;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = _position;
        res.scale = scale;
        res.name = name;
        res.type = this.type;
        res.otherDatas = userData;
        return res;
    }
    addTag() {
        const circleGeometry = new CircleGeometry(0.05, 32); // Rayon du cercle
        const circleMaterial = new MeshStandardMaterial({ color: this._circleColor });
        const circle = new Mesh(circleGeometry, circleMaterial);
        circle.position.copy(this._position).add(this._normal.clone().multiplyScalar(0.4));
        const lineMaterial = new LineBasicMaterial({ color: 0x000000, linewidth: 3 });
        const points = [];
        points.push(this._position); // Point de départ : position cliquée
        points.push(circle.position); // Point final : position du cercle
        const lineGeometry = new BufferGeometry().setFromPoints(points);
        const line = new Line(lineGeometry, lineMaterial);
        this._circle = circle;
        this.add(circle);
        this.add(line);
    }
    get circle() {
        return this._circle;
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
