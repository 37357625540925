var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
function Calendar(_a) {
    var { header = {
        title: "",
        date: "",
    } } = _a, rest = __rest(_a, ["header"]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const navigate = useNavigate();
    const { result, execute, abortController } = useFormGet(url("/event/all"));
    useEffect(() => {
        execute();
        return () => {
            abortController.abort();
        };
    }, []);
    const validClassNames = [
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ];
    // const events = rest.events
    //   ? rest.events.map((el: any) => ({
    //       ...el,
    //       className: validClassNames.find((item) => item === el.className)
    //         ? `event-${el.className}`
    //         : "event-info",
    //     }))
    //   : [];
    const convertEventsToFullCalendar = (events) => {
        ///TODO check if on peut pas faire mieux que cet appel
        return events.map((event) => ({
            id: event._id,
            title: event.title,
            start: new Date(event.start * 1000).toISOString(),
            end: new Date(event.end * 1000).toISOString(),
            extendedProps: {
                description: event.description, // Conserver les descriptions sous extendedProps
            },
        }));
    };
    // Get current time in HH:mm:ss format
    // const currentTime = new Date().toISOString().substring(11, 19);
    const currentTime = "23:00:00";
    console.log(currentTime);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(Card, Object.assign({ sx: {} }, { children: [_jsxs(MDBox, Object.assign({ pt: header.title || header.date ? 2 : 0, px: 2, lineHeight: 1 }, { children: [header.title ? (_jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium", textTransform: "capitalize" }, { children: header.title }))) : null, header.date ? (_jsx(MDTypography, Object.assign({ component: "p", variant: "button", color: "text", fontWeight: "regular" }, { children: header.date }))) : null] })), _jsx(CalendarRoot, Object.assign({ p: 2, sx: { overflow: "scroll" }, ownerState: { darkMode } }, { children: _jsx(FullCalendar, Object.assign({}, rest, { headerToolbar: {
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay",
                            }, views: {
                                dayGridMonth: {
                                    buttonText: "Month",
                                },
                                timeGridWeek: {
                                    buttonText: "Week",
                                },
                                timeGridDay: {
                                    buttonText: "Day",
                                },
                            }, nowIndicator: true, scrollTime: currentTime, initialView: "timeGridWeek", selectable: true, eventClick: (eventClickInfo) => {
                                navigate(`/events/update/${eventClickInfo.event.id}`);
                            }, contentHeight: "auto", select: (event) => {
                                console.log(event);
                                const startDate = new Date(event.start).getTime();
                                const endDate = new Date(event.end).getTime();
                                navigate(`/events/create?start=${startDate}&end=${endDate}`);
                            }, plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin], events: result ? convertEventsToFullCalendar(result) : [], height: "100%" })) }))] }))] }));
}
export default Calendar;
