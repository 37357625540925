import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Declaring defualt props for ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
    color: "info",
    percentage: {
        color: "success",
        amount: "",
        label: "",
    },
};
function ComplexStatisticsCard({ color = "info", title, count, percentage = { color: "success", amount: "", label: "" }, icon }) {
    return (_jsxs(Card, { children: [_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", pt: 1, px: 2 }, { children: [_jsx(MDBox, Object.assign({ variant: "gradient", bgColor: color, color: color === "light" ? "dark" : "white", coloredShadow: color, borderRadius: "xl", display: "flex", justifyContent: "center", alignItems: "center", width: "4rem", height: "4rem", mt: -3 }, { children: _jsx(Icon, Object.assign({ fontSize: "medium", color: "inherit" }, { children: icon })) })), _jsxs(MDBox, Object.assign({ textAlign: "right", lineHeight: 1.25 }, { children: [_jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "light", color: "text" }, { children: title })), _jsx(MDTypography, Object.assign({ variant: "h4" }, { children: count }))] }))] })), _jsx(Divider, {}), _jsx(MDBox, Object.assign({ pb: 2, px: 2 }, { children: _jsxs(MDTypography, Object.assign({ component: "p", variant: "button", color: "text", display: "flex" }, { children: [_jsx(MDTypography, Object.assign({ component: "span", variant: "button", fontWeight: "bold", color: percentage.color }, { children: percentage.amount })), "\u00A0", percentage.label] })) }))] }));
}
export default ComplexStatisticsCard;
