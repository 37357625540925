import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Card, FormControl, Grid, Paper, Stack, TextField } from '@mui/material';
import { createContext, useEffect, useState } from 'react';
import { useFormGet } from 'hooks/useFormGet';
import { url } from "services/ApiEndpoint";
import AddNewUserModel from 'components/features/event/AddNewUserModel';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import AddNewUserForm from 'components/features/event/AddNewUserForm';
export const ParticipentContext = createContext({
    updateParticipent: () => { },
    setOpen: () => { },
});
export default function EventParticipants(props) {
    // const userFieldRef = useRef<HTMLInputElement>();
    var _a, _b;
    const [userEnterdValue, setUserEnterdValue] = useState('');
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState([]);
    const [userEmail, setUserEmail] = useState([]);
    const { execute, result, status, loading } = useFormGet(url(`/user/get-by-email/${userEnterdValue}`));
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (typeof props.formik.values[props.name] !== 'string') {
            props.formik.setFieldValue(props.name, [...userId]);
        }
    }, [users]);
    useEffect(() => {
        const data = props.formik.values[props.name];
        if (data.length > 0) {
            data.forEach((item) => {
                setUserId([...userId, item._id]);
                setUserEmail([...userEmail, item._id]);
            });
            setUsers([...data]);
        }
    }, []);
    const updateParticipent = (data) => {
        if (!userId.includes(data._id)) {
            setUsers([...users, {
                    _id: data._id,
                    email: data.email
                }]);
            setUserId([...userId, data._id]);
            setUserEmail([...userEmail, data._id]);
            setUserEnterdValue('');
        }
    };
    useEffect(() => {
        if (status === 204) {
            setOpen(true);
            props.formik.setFieldError(props.name, 'No user found with this email');
        }
        if (status === 208) {
            props.formik.setFieldError(props.name, 'you can\'t add yourself to the event');
        }
    }, [status]);
    useEffect(() => {
        if (result) {
            console.log(result);
            updateParticipent(result);
        }
    }, [result]);
    const AddUserToTheParticipent = async (enteredValue) => {
        if (userEmail.includes(enteredValue)) {
            return;
        }
        // check user with email already exists in database
        // if exist just add that user to participant event list and when we submit the form we check each user is they are linked with that admin who create the event, if not we associate that email in that list of contact
        // if not we display the form to fill up user informations and proceed the previous step
        props.formik.setFieldError(props.name, '');
        await execute();
    };
    useEffect(() => {
        console.log(loading);
    }, [loading]);
    return (_jsxs(_Fragment, { children: [_jsxs(FormControl, Object.assign({ fullWidth: true, style: {
                    marginBottom: 15
                } }, { children: [_jsxs(Stack, Object.assign({ direction: "row", spacing: 2 }, { children: [_jsx(TextField, { fullWidth: true, variant: "outlined", style: {
                                    marginBottom: 15
                                }, onChange: (e) => {
                                    setUserEnterdValue(e.target.value);
                                }, value: userEnterdValue, error: props.formik.errors[props.name] ? true : false, helperText: ((_a = props.formik.errors[props.name]) === null || _a === void 0 ? void 0 : _a.toString()) ? (_b = props.formik.errors[props.name]) === null || _b === void 0 ? void 0 : _b.toString() : '' }), loading ? _jsx(LoadingButton, Object.assign({ loading: true, variant: 'outlined' }, { children: "Add" })) : _jsx(Button, Object.assign({ variant: "outlined", style: {
                                    marginBottom: 15
                                }, onClick: () => {
                                    if (userEnterdValue !== '') {
                                        AddUserToTheParticipent(userEnterdValue);
                                    }
                                } }, { children: "Add" }))] })), users.length > 0 ? (_jsx(Paper, Object.assign({ elevation: 3, style: { padding: 20 } }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: users.map((user, key) => {
                                return (_jsx(Grid, Object.assign({ item: true, md: 4, width: "100%" }, { children: _jsxs(Card, Object.assign({ style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: 10,
                                            width: 'fit-content',
                                        } }, { children: [user.email, " ", _jsx(ClearIcon, { style: { cursor: 'pointer' }, onClick: () => {
                                                    const newUsersList = users.filter((item, index) => index !== key);
                                                    const newUserEmailList = userEmail.filter((item, index) => index !== key);
                                                    const newUserIdList = userId.filter((item, index) => index !== key);
                                                    setUsers([...newUsersList]);
                                                    setUserEmail([...newUserEmailList]);
                                                    setUserId([...newUserIdList]);
                                                } })] })) }), key));
                            }) })) }))) : null] })), _jsx(AddNewUserModel, Object.assign({ modelOpen: open, setModelOpen: setOpen }, { children: _jsx(AddNewUserForm, { email: userEnterdValue, setModelOpen: setOpen, updateParticipent: updateParticipent }) }))] }));
}
