import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// @mui material components
import Stack from "@mui/material/Stack";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Images
import image1 from "assets/images/products/product-details-1.jpg";
import image2 from "assets/images/products/product-details-2.jpg";
import image3 from "assets/images/products/product-details-3.jpg";
import image4 from "assets/images/products/product-details-4.jpg";
import image5 from "assets/images/products/product-details-5.jpg";
function ProductImages() {
    const [currentImage, setCurrentImage] = useState(image1);
    const [imgsViewer, setImgsViewer] = useState(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const handleSetCurrentImage = ({ currentTarget }) => {
        setCurrentImage(currentTarget.src);
        setImgsViewerCurrent(Number(currentTarget.id));
    };
    const openImgsViewer = () => setImgsViewer(true);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
    return (_jsxs(MDBox, { children: [_jsx(MDBox, { component: "img", src: currentImage, alt: "Product Image", shadow: "lg", borderRadius: "lg", width: "100%", onClick: openImgsViewer }), _jsx(MDBox, Object.assign({ mt: 2, pt: 1, style: { overflow: "auto" } }, { children: _jsxs(Stack, Object.assign({ direction: "row", spacing: 3 }, { children: [_jsx(MDBox, { component: "img", id: "0", src: image1, alt: "small image 1", borderRadius: "lg", shadow: "md", width: "100%", height: "5rem", minHeight: "5rem", sx: { cursor: "pointer", objectFit: "cover" }, onClick: handleSetCurrentImage }), _jsx(MDBox, { component: "img", id: "1", src: image2, alt: "small image 2", borderRadius: "lg", shadow: "md", width: "100%", height: "5rem", minHeight: "5rem", sx: { cursor: "pointer", objectFit: "cover" }, onClick: handleSetCurrentImage }), _jsx(MDBox, { component: "img", id: "2", src: image3, alt: "small image 3", borderRadius: "lg", shadow: "md", width: "100%", height: "5rem", minHeight: "5rem", sx: { cursor: "pointer", objectFit: "cover" }, onClick: handleSetCurrentImage }), _jsx(MDBox, { component: "img", id: "3", src: image4, alt: "small image 4", borderRadius: "lg", shadow: "md", width: "100%", height: "5rem", minHeight: "5rem", sx: { cursor: "pointer", objectFit: "cover" }, onClick: handleSetCurrentImage }), _jsx(MDBox, { component: "img", id: "4", src: image5, alt: "small image 5", borderRadius: "lg", shadow: "md", width: "100%", height: "5rem", minHeight: "5rem", sx: { cursor: "pointer", objectFit: "cover" }, onClick: handleSetCurrentImage })] })) }))] }));
}
export default ProductImages;
