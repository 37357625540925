var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "contexts";
function DataTableHeadCell(_a) {
    var { width = "auto", children, sorted = "none", align = "left", key } = _a, rest = __rest(_a, ["width", "children", "sorted", "align", "key"]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (_jsx(MDBox, Object.assign({ component: "th", width: width, py: 1.5, px: 3, sx: ({ palette: { light }, borders: { borderWidth } }) => ({
            borderBottom: `${borderWidth[1]} solid ${light.main}`,
        }) }, { children: _jsxs(MDBox, Object.assign({}, rest, { position: "relative", textAlign: align, color: darkMode ? "white" : "secondary", opacity: 0.7, sx: ({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                cursor: sorted && "pointer",
                userSelect: sorted && "none",
            }) }, { children: [children, sorted && (_jsxs(MDBox, Object.assign({ position: "absolute", top: 0, right: align !== "right" ? "16px" : 0, left: align === "right" ? "-5px" : "unset", sx: ({ typography: { size } }) => ({
                        fontSize: size.lg,
                    }) }, { children: [_jsx(MDBox, Object.assign({ position: "absolute", top: -6, color: sorted === "asce" ? "text" : "secondary", opacity: sorted === "asce" ? 1 : 0.5 }, { children: _jsx(Icon, { children: "arrow_drop_up" }) })), _jsx(MDBox, Object.assign({ position: "absolute", top: 0, color: sorted === "desc" ? "text" : "secondary", opacity: sorted === "desc" ? 1 : 0.5 }, { children: _jsx(Icon, { children: "arrow_drop_down" }) }))] })))] }), key) }), key));
}
export default DataTableHeadCell;
