export var ETemplateStatus;
(function (ETemplateStatus) {
    ETemplateStatus["active"] = "ACTIVE";
    ETemplateStatus["inActive"] = "INACTIVE";
    ETemplateStatus["onProcess"] = "ON_PROCESS";
})(ETemplateStatus || (ETemplateStatus = {}));
export var ETemplateOrigin;
(function (ETemplateOrigin) {
    ETemplateOrigin["custom"] = "CUSTOM";
    ETemplateOrigin["standard"] = "STANDARD";
})(ETemplateOrigin || (ETemplateOrigin = {}));
export const options = ["4DAGE", "Native"];
