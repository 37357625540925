import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
export default function EventType(props) {
    var _a, _b;
    useEffect(() => {
        console.log(props.formik.values);
    }, []);
    const types = [
        {
            '_id': 'Conférence',
            'ref': 'Conférence',
        },
        {
            '_id': 'Webinar',
            'ref': 'Webinar',
        },
        {
            '_id': 'TD',
            'ref': 'TD',
        }
    ];
    return (_jsxs(FormControl, Object.assign({ fullWidth: true, style: {
            marginBottom: 15,
            width: '100%',
        } }, { children: [_jsx(InputLabel, Object.assign({ id: props.name, required: props.required }, { children: (_a = props.label) !== null && _a !== void 0 ? _a : props.name })), _jsx(Select, Object.assign({ labelId: props.name, id: props.name, value: props.formik.values[props.name], label: (_b = props.label) !== null && _b !== void 0 ? _b : props.name, name: props.name, onChange: props.formik.handleChange, required: props.required, defaultValue: "" }, { children: types.map((type, key) => {
                    return _jsx(MenuItem, Object.assign({ value: type._id }, { children: type.ref }), key);
                }) }))] })));
}
