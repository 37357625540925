import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Authentication layout components
import BasicLayout from "components/features/LayoutContainers/BasicLayout";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { useFormik } from "formik";
import FormInputText from "components/generic/form/FormInputText";
import FormSubmitButton from "components/generic/form/FormSubmitButton";
import { useEffect } from "react";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
import { useShowConfirmation } from "hooks/useShowConfirmation";
function ResetPassword() {
    const { globalErrorMessage, execute, result, status, errors } = useFormPost(url("/user/password-reset-request"));
    const { Message, setShow, setDescription, setTitle } = useShowConfirmation();
    const formData = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async (submitedValue) => execute(JSON.stringify(submitedValue)),
    });
    useEffect(() => {
        if (!status)
            return;
        if (status !== 200) {
            ApiFormErrorResolver(formData, errors);
        }
        else {
            console.log(result);
            setTitle("Password Reset");
            setDescription("Vous allez reçoire le lien pour reinitialiser prochainment");
            setShow(true);
        }
    }, [status]);
    return (_jsxs(BasicLayout, Object.assign({ image: bgImage }, { children: [_jsxs(Card, { children: [_jsxs(MDBox, Object.assign({ variant: "gradient", bgColor: "info", borderRadius: "lg", coloredShadow: "success", mx: 2, mt: -3, py: 2, mb: 1, textAlign: "center" }, { children: [_jsx(MDTypography, Object.assign({ variant: "h3", fontWeight: "medium", color: "white", mt: 1 }, { children: "Reset Password" })), _jsx(MDTypography, Object.assign({ display: "block", variant: "button", color: "white", my: 1 }, { children: "You will receive an e-mail in maximum 60 seconds" }))] })), _jsx(MDBox, Object.assign({ pt: 4, pb: 3, px: 3 }, { children: _jsxs(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: [_jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "email", label: "Email", formik: formData }) })), _jsx(MDBox, Object.assign({ mt: 6, mb: 1 }, { children: _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: "reset" }) }))] })) }))] }), _jsx(Message, {})] })));
}
export default ResetPassword;
