/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React Button Styles
import root from "assets/theme-dark/components/button/root";
import contained from "assets/theme-dark/components/button/contained";
import outlined from "assets/theme-dark/components/button/outlined";
import buttonText from "assets/theme-dark/components/button/text";
const button = {
    defaultProps: {
        disableRipple: false,
    },
    styleOverrides: {
        root: Object.assign({}, root),
        contained: Object.assign({}, contained.base),
        containedSizeSmall: Object.assign({}, contained.small),
        containedSizeLarge: Object.assign({}, contained.large),
        containedPrimary: Object.assign({}, contained.primary),
        containedSecondary: Object.assign({}, contained.secondary),
        outlined: Object.assign({}, outlined.base),
        outlinedSizeSmall: Object.assign({}, outlined.small),
        outlinedSizeLarge: Object.assign({}, outlined.large),
        outlinedPrimary: Object.assign({}, outlined.primary),
        outlinedSecondary: Object.assign({}, outlined.secondary),
        text: Object.assign({}, buttonText.base),
        textSizeSmall: Object.assign({}, buttonText.small),
        textSizeLarge: Object.assign({}, buttonText.large),
        textPrimary: Object.assign({}, buttonText.primary),
        textSecondary: Object.assign({}, buttonText.secondary),
    },
};
export default button;
