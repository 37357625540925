import { DoubleSide, Mesh, MeshStandardMaterial, PlaneGeometry, TextureLoader } from "three";
import { SceneItem } from "../model/SceneItem";
export class ImageMesh extends Mesh {
    constructor() {
        super(new PlaneGeometry(1, 1), new MeshStandardMaterial());
        this.type = "ImageMesh";
        this.name = "ImagePlane";
        this.material.side = DoubleSide;
    }
    dataToMesh(data) {
        this.clear();
        const { name, rotation, position, scale, otherDatas } = data;
        this.position.copy(position);
        this.rotation.copy(rotation);
        this.scale.copy(scale);
        this.name = name;
        otherDatas.src ? (this.src = otherDatas.src) : (otherDatas.src = "");
        if (!otherDatas.displayName)
            otherDatas.displayName = this.uuid;
        this.userData = otherDatas;
        this.updateSrc();
        this.material.needsUpdate;
    }
    meshToData() {
        const { position, rotation, scale, name, uuid, userData } = this;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = position;
        res.scale = scale;
        res.name = name;
        res.type = this.type;
        res.otherDatas = userData;
        // console.log(res);
        return res;
    }
    updateSrc() {
        const texture = new TextureLoader().load(this.src);
        this.material.map = texture;
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
