import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, startTransition } from "react";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import GLBModel from "../GLBModel/GLBModel"; // Ensure the path is correct
const GLBScroller = ({ avatarDatas }) => {
    const initialIndex = -1;
    const [currentModelIndex, setCurrentModelIndex] = useState(initialIndex !== -1 ? initialIndex : 0);
    // const [transitionKey, setTransitionKey] = useState(0);
    useEffect(() => {
        if (initialIndex !== -1) {
            setCurrentModelIndex(initialIndex);
        }
    }, [initialIndex]);
    const handleNextModel = () => {
        startTransition(() => {
            setCurrentModelIndex((prevIndex) => (prevIndex + 1) % avatarDatas.length);
            // setTransitionKey((prevKey) => prevKey + 1);
        });
    };
    const handlePreviousModel = () => {
        startTransition(() => {
            setCurrentModelIndex((prevIndex) => (prevIndex - 1 + avatarDatas.length) % avatarDatas.length);
            // setTransitionKey((prevKey) => prevKey + 1);
        });
    };
    return (_jsxs("div", Object.assign({ style: { position: "relative", width: "100%", height: "500px" } }, { children: [_jsx(GLBModel
            // key={transitionKey}
            , { 
                // key={transitionKey}
                modelUrl: avatarDatas[currentModelIndex].path, _id: avatarDatas[currentModelIndex]._id }), _jsx(IconButton, Object.assign({ size: "large", onClick: handlePreviousModel, color: "primary", "aria-label": "Previous Model", sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                }, style: {
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    padding: "10px",
                    fontSize: "20px",
                } }, { children: _jsx(ArrowBackIosNewIcon, {}) })), _jsx(IconButton, Object.assign({ onClick: handleNextModel, color: "primary", "aria-label": "Next Model", sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                }, style: {
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    padding: "10px",
                    fontSize: "20px",
                } }, { children: _jsx(ArrowForwardIosIcon, {}) }))] })));
};
export default GLBScroller;
