import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Images
import easyVirtual from "assets/images/logo-easy-vitual-tours.png";
import dage from "assets/images/logo-4dage.png";
function PricingCards() {
    return (_jsxs(MDBox, Object.assign({ mt: 8 }, { children: [_jsx(MDBox, Object.assign({ textAlign: "center" }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", opacity: 0.5 }, { children: "Our premium partners" })) })), _jsx(MDBox, Object.assign({ mt: 5, ml: { xs: 0, lg: -8 } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4, justifyContent: "space-evenly", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 3, md: 2, lg: 2 }, { children: _jsx(MDBox, { component: "img", src: easyVirtual, alt: "easyVirtual", width: { xs: "100%", xl: "125%" }, opacity: 0.9, mb: 3 }) })), _jsx(Grid, Object.assign({ item: true, xs: 3, md: 2, lg: 2 }, { children: _jsx(MDBox, { component: "img", src: dage, alt: "dage", width: { xs: "100%", xl: "125%" }, opacity: 0.9, mb: 3 }) }))] })) }))] })));
}
export default PricingCards;
