var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef } from "react";
// Custom styles for MDTypography
import MDTypographyRoot from "components/generic/MDTypography/MDTypographyRoot";
// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "contexts";
const MDTypography = forwardRef((_a, ref) => {
    var { color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children } = _a, rest = __rest(_a, ["color", "fontWeight", "textTransform", "verticalAlign", "textGradient", "opacity", "children"]);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (_jsx(MDTypographyRoot, Object.assign({}, rest, { ref: ref, ownerState: {
            color,
            textTransform,
            verticalAlign,
            fontWeight,
            opacity,
            textGradient,
            darkMode,
        } }, { children: children })));
});
// Declaring default props for MDTypography
MDTypography.defaultProps = {
    color: "dark",
    fontWeight: undefined,
    textTransform: "none",
    verticalAlign: "unset",
    textGradient: false,
    opacity: 1,
};
export default MDTypography;
