import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import RoomCard from "components/features/room/card";
import MDBox from "components/generic/MDBox";
export default function RoomList() {
    const { result, execute } = useFormGet(url("/room/all"));
    useEffect(() => {
        execute();
    }, []);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ mt: 2 }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 6 }, { children: result === null || result === void 0 ? void 0 : result.map((room, index) => (_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsx(RoomCard, { room: room }) })) }), index))) })) }))] }));
}
