import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDDropzone from "components/generic/MDDropzone";
function Media() {
    return (_jsxs(MDBox, { children: [_jsx(MDTypography, Object.assign({ variant: "h5" }, { children: "Media" })), _jsxs(MDBox, Object.assign({ mt: 3 }, { children: [_jsx(MDBox, Object.assign({ mb: 1, ml: 0.5, lineHeight: 0, display: "inline-block" }, { children: _jsx(MDTypography, Object.assign({ component: "label", variant: "button", fontWeight: "regular", color: "text" }, { children: "Product Image" })) })), useMemo(() => (_jsx(MDDropzone, { options: { addRemoveLinks: true } })), [])] }))] }));
}
export default Media;
