/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS Base Styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
const { size } = typography;
const { text } = colors;
const dialogContentText = {
    styleOverrides: {
        root: {
            fontSize: size.md,
            color: text.main,
        },
    },
};
export default dialogContentText;
