import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Checkbox from "@mui/material/Checkbox";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
function IdCell({ id, checked }) {
    return (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Checkbox, { defaultChecked: checked }), _jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", color: "text" }, { children: id })) }))] })));
}
// Declaring default props for IdCell
IdCell.defaultProps = {
    checked: false,
};
export default IdCell;
