import { jsx as _jsx } from "react/jsx-runtime";
import ImageMeshForm from "../Form/ImageMeshForm";
import VideoMeshForm from "../Form/VideoMeshForm";
import ObjectMeshForm from "../Form/ObjectMeshForm";
import TagMeshForm from "../Form/TagMeshForm";
import LiveStreamMeshForm from "../Form/LiveStreamMeshForm";
export default function RenderEditForm({ selectedItem, domElement, roomId, transformControl, }) {
    const element = selectedItem.meshToData();
    return element.type === "ImageMesh" ? (_jsx(ImageMeshForm, { domElement: domElement, item: selectedItem, roomId: roomId, transformControl: transformControl })) : element.type === "VideoMesh" ? (_jsx(VideoMeshForm, { domElement: domElement, item: selectedItem, roomId: roomId, transformControl: transformControl })) : element.type === "ObjectMesh" ? (_jsx(ObjectMeshForm, { domElement: domElement, item: selectedItem, roomId: roomId, transformControl: transformControl })) : element.type === "TagMesh" ? (_jsx(TagMeshForm, { domElement: domElement, item: selectedItem, roomId: roomId, transformControl: transformControl })) : element.type === "LiveStreamMesh" ? (_jsx(LiveStreamMeshForm, { domElement: domElement, item: selectedItem, roomId: roomId, transformControl: transformControl })) : null;
}
