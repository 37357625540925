import { jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDTypography from "components/generic/MDTypography";
function DefaultCell({ value, suffix }) {
    return (_jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", color: "text" }, { children: [value, suffix && (_jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", color: "secondary" }, { children: ["\u00A0\u00A0", suffix] })))] })));
}
export default DefaultCell;
