import { useFormGet } from "hooks/useFormGet";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setToken, signIn } from "redux-store/UserSlice";
export const useSignIn = () => {
    const { execute: loginExecute, globalErrorMessage, result: token, } = useFormPost(url("/user/login"));
    const { execute: userInfoExecute, result: userInfo } = useFormGet(url("/user/info"));
    const userToken = useSelector((state) => state.user.userToken);
    const dispatch = useDispatch();
    useEffect(() => {
        if (token) {
            dispatch(setToken(token.AuthToken));
        }
    }, [token]);
    useEffect(() => {
        if (userToken !== "") {
            userInfoExecute();
        }
    }, [userToken]);
    useEffect(() => {
        if (userInfo && token) {
            dispatch(signIn({
                initial: userInfo.firstname[0].toUpperCase(),
                token: token.AuthToken,
            }));
        }
    }, [userInfo]);
    return {
        loginExecute,
        globalErrorMessage,
    };
};
