var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "./styles/sidenavItem";
// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from "contexts";
function SidenavItem(_a) {
    var { color = "info", name, active = false, nested = false, children = false, open = false } = _a, rest = __rest(_a, ["color", "name", "active", "nested", "children", "open"]);
    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    return (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({}, rest, { component: "li", sx: (theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode }) }, { children: _jsxs(MDBox, Object.assign({ sx: (theme) => itemContent(theme, {
                        active,
                        miniSidenav,
                        name,
                        open,
                        nested,
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                    }) }, { children: [_jsx(ListItemText, { primary: name }), children && (_jsx(Icon, Object.assign({ component: "i", sx: (theme) => itemArrow(theme, { open, miniSidenav, transparentSidenav, whiteSidenav, darkMode }) }, { children: "expand_less" })))] })) })), children && (_jsx(Collapse, Object.assign({ in: open, timeout: "auto", unmountOnExit: true }, rest, { children: children })))] }));
}
export default SidenavItem;
