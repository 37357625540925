import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React TS examples components
import TimelineItem from "components/generic/Timeline/TimelineItem";
function OrdersOverview() {
    return (_jsxs(_Fragment, { children: [_jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Track order" })), _jsxs(MDBox, Object.assign({ mt: 2 }, { children: [_jsx(TimelineItem, { color: "secondary", icon: "notifications", title: "Order received", dateTime: "22 DEC 7:20 PM" }), _jsx(TimelineItem, { color: "secondary", icon: "inventory_2", title: "Generate order id #1832412", dateTime: "22 DEC 7:21 AM" }), _jsx(TimelineItem, { color: "secondary", icon: "shopping_cart", title: "Order transmited to courier", dateTime: "22 DEC 8:10 AM" }), _jsx(TimelineItem, { color: "success", icon: "done", title: "Order delivered", dateTime: "22 DEC 4:54 PM", lastItem: true })] }))] }));
}
export default OrdersOverview;
