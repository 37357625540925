import { Clock, Quaternion, Vector3 } from "three";
import { SocketPlayerInfo } from "../../model/SocketPlayerInfo";
import { io } from "socket.io-client";
import { SOCKET_USER_ID } from "../../state/const";
import { Avatar } from "./Avatar";
import { SOCKET_HOST } from "services/ApiEndpoint";
export class SocketService {
    constructor(_baseScene, _mainPlayer, _eventId) {
        this._baseScene = _baseScene;
        this._mainPlayer = _mainPlayer;
        this._eventId = _eventId;
        this._clock = new Clock();
        this._socketPlayers = {};
        this._connection = io(`${SOCKET_HOST}/${this._eventId}`);
        this._connection.on("characters", this.updateCharacters.bind(this));
        this._connection.on("createdUser", this.createdUser.bind(this));
        this._connection.on("removePlayer", this._removePlayer.bind(this));
        setInterval(this._updateSocketPlayerinfo.bind(this), 35);
    }
    createdUser(data) {
        localStorage.setItem(SOCKET_USER_ID, data);
    }
    _removePlayer(data) {
        const player = this._socketPlayers[data.socketId];
        if (player) {
            player._deleteAvatar();
            delete this._socketPlayers[data.socketId];
            console.log("player removed", data.socketId);
        }
    }
    updateCharacters(SocketPlayerInfos) {
        Object.keys(SocketPlayerInfos).map(async (k) => {
            const p = SocketPlayerInfos[k];
            if (!p.active)
                return;
            if (!this._socketPlayers[p.socketId]) {
                this._socketPlayers[p.socketId] = new Avatar(this._baseScene, p.avatar, p.socketId);
                this._socketPlayers[p.socketId].setNameTag(p.name);
            }
            //this._socketPlayers[p.socketId]._visibilityUpdate(true);
            this._socketPlayers[p.socketId].updatePlayerPosition(new Vector3(p.position.x, p.position.y, p.position.z));
            this._socketPlayers[p.socketId].updatePlayerRotation(new Quaternion(p.rotation.x, p.rotation.y, p.rotation.z, p.rotation.w));
            this._socketPlayers[p.socketId]._playAnimation(p.animation, this._clock.getDelta());
        });
    }
    _updateSocketPlayerinfo() {
        var _a;
        if (!this._mainPlayer.currentPlayer.position || !this._mainPlayer.currentPlayer.rotation) {
            return;
        }
        const p = new SocketPlayerInfo();
        p.active = true;
        p.avatar = this._mainPlayer.currentPlayer.avatarPath;
        p.animation = this._mainPlayer.currentPlayer.currentAnimation;
        p.name = this._mainPlayer.currentPlayer.playerName;
        p.socketId = (_a = localStorage.getItem(SOCKET_USER_ID)) !== null && _a !== void 0 ? _a : "";
        p.position = this._mainPlayer.currentPlayer.position;
        p.rotation = this._mainPlayer.currentPlayer.rotation;
        this._connection.emit("updateCharacter", p);
    }
}
