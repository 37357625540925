import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
// OrderDetails page components
import Header from "page/ecommerce/orders/order-details/components/Header";
import OrderInfo from "page/ecommerce/orders/order-details/components/OrderInfo";
import TrackOrder from "page/ecommerce/orders/order-details/components/TrackOrder";
import PaymentDetails from "page/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "page/ecommerce/orders/order-details/components/BillingInformation";
import OrderSummary from "page/ecommerce/orders/order-details/components/OrderSummary";
function OrderDetails() {
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(MDBox, Object.assign({ my: 6 }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3, justifyContent: "center" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 8 }, { children: _jsxs(Card, { children: [_jsx(MDBox, Object.assign({ pt: 2, px: 2 }, { children: _jsx(Header, {}) })), _jsx(Divider, {}), _jsxs(MDBox, Object.assign({ pt: 1, pb: 3, px: 2 }, { children: [_jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsx(OrderInfo, {}) })), _jsx(Divider, {}), _jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(TrackOrder, {}) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 5 }, { children: [_jsx(PaymentDetails, {}), _jsx(MDBox, Object.assign({ mt: 3 }, { children: _jsx(BillingInformation, {}) }))] })), _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 3, sx: { ml: "auto" } }, { children: _jsx(OrderSummary, {}) }))] })) }))] }))] }) })) })) }))] }));
}
export default OrderDetails;
