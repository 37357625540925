var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef } from "react";
// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// custom styles for the NotificationItem
import menuItem from "components/generic/Items/NotificationItem/styles";
//TODO Props -> any
const NotificationItem = forwardRef((_a, ref) => {
    var { icon, title } = _a, rest = __rest(_a, ["icon", "title"]);
    return (_jsx(MenuItem, Object.assign({}, rest, { ref: ref, sx: (theme) => menuItem(theme) }, { children: _jsxs(MDBox, Object.assign({ component: Link, py: 0.5, display: "flex", alignItems: "center", lineHeight: 1 }, { children: [_jsx(MDTypography, Object.assign({ variant: "body1", color: "secondary", lineHeight: 0.75 }, { children: icon })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", sx: { ml: 1 } }, { children: title }))] })) })));
});
export default NotificationItem;
