import { DoubleSide, Group, Mesh, MeshPhysicalMaterial } from "three";
import { SceneItem } from "../model/SceneItem";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export class ObjectMesh extends Group {
    constructor() {
        super(...arguments);
        this.type = "ObjectMesh";
        this.name = "CustomObject";
    }
    dataToMesh(data) {
        this.clear();
        const { name, position, scale, rotation, otherDatas } = data;
        this.position.copy(position);
        this.rotation.copy(rotation);
        this.scale.copy(scale);
        if (otherDatas.src)
            this.src = otherDatas.src;
        this.name = name;
        this.userData = otherDatas;
        if (!otherDatas.displayName)
            otherDatas.displayName = this.uuid;
        this.updateSrc();
        this.matrixWorldNeedsUpdate = true;
        this.updateMatrix();
        this.visible = true;
        this.castShadow = true;
    }
    meshToData() {
        const { position, rotation, scale, name, uuid, userData } = this;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = position;
        res.scale = scale;
        res.name = name;
        res.type = this.type;
        res.otherDatas = Object.assign(Object.assign({}, userData), { src: this.src });
        return res;
    }
    updateSrc() {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        dracoLoader.setDecoderConfig({});
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader).load(this.src, (gltf) => {
            // Traverse the loaded scene and enable raycasting
            gltf.scene.traverse((child) => {
                if (child instanceof Mesh && child.material instanceof MeshPhysicalMaterial) {
                    child.receiveShadow = true;
                    child.castShadow = true;
                    child.material.side = DoubleSide;
                    child.geometry.computeBoundingBox(); // Optional, ensures the geometry's bounding box is up to date
                    child.geometry.computeBoundingSphere(); // Optional, ensures the geometry's bounding sphere is up to date
                    child.material.needsUpdate = true; // Ensure material updates
                    // Ensure texture updates
                    if (child.material.map) {
                        child.material.map.needsUpdate = true;
                    }
                    if (child.material.metalnessMap) {
                        child.material.metalnessMap.needsUpdate = true;
                    }
                    if (child.material.roughnessMap) {
                        child.material.roughnessMap.needsUpdate = true;
                    }
                    if (child.material.normalMap) {
                        child.material.normalMap.needsUpdate = true;
                    }
                    if (child.material.envMap) {
                        child.material.envMap.needsUpdate = true;
                    }
                }
            });
            gltf.scene.children.map((child) => this.add(child));
            // this.add(gltf.scene);
        });
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
