import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getIn } from "formik";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export default function FormSelect(props) {
    var _a;
    const value = getIn(props.formik.values, props.name);
    // const error = NestedArrayResolveStrategy(props.formik.errors, props.name);
    const error = getIn(props.formik.errors, props.name);
    let label = props.label ? `${props.label}` : null;
    if (props.required && label) {
        label = label + " *";
    }
    return (_jsxs(MDBox, Object.assign({ style: {
            marginBottom: 15,
        } }, { children: [_jsxs(FormControl, Object.assign({ style: { width: "100%" } }, { children: [props.label ? _jsx(InputLabel, Object.assign({ id: `id-${props.name}-label` }, { children: label })) : null, _jsx(Select, Object.assign({ variant: "outlined", fullWidth: true, value: value, onChange: props.formik.handleChange, error: error ? true : false, id: `id-${props.name}`, placeholder: props.label, label: label }, props, { style: {
                            height: "45px",
                        } }, { children: (_a = props.options) === null || _a === void 0 ? void 0 : _a.map((option, key) => (_jsx(MenuItem, Object.assign({ value: option.key }, { children: option.value }), key))) }))] })), error ? (_jsx(MDBox, Object.assign({ style: {
                    marginTop: 15,
                } }, { children: _jsx(MDTypography, Object.assign({ component: "div", variant: "caption", color: "error", fontWeight: "regular" }, { children: error.toString() })) }))) : null] })));
}
