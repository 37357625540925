import { Euler, Vector3 } from "three";
export class SceneItem {
    constructor() {
        this.position = new Vector3();
        this.rotation = new Euler();
        this.scale = new Vector3(1, 1, 1);
        this.name = "";
        this.uuid = "";
        this.type = "";
        this.otherDatas = {};
    }
}
