import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import ComplexStatisticsCard from "components/generic/Card/StatisticsCards/ComplexStatisticsCard";
// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import ReportsBarChart from "components/generic/BarCharts/ReportsBarChart";
import ReportsLineChart from "components/generic/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// Data
import reportsBarChartData from "page/dashboard/Analytics/data/reportsBarChartData";
import reportsLineChartData from "page/dashboard/Analytics/data/reportsLineChartData";
import { Card } from "@mui/material";
import DataTable from "components/generic/Tables/DataTable";
import dataTableData from "./data/dataTableData";
function Analytics() {
    const { sales, tasks } = reportsLineChartData;
    // Action buttons for the BookingCard
    const actionButtons = (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Refresh", placement: "bottom" }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: "primary", lineHeight: 1, sx: { cursor: "pointer", mx: 3 } }, { children: _jsx(Icon, Object.assign({ color: "inherit" }, { children: "refresh" })) })) })), _jsx(Tooltip, Object.assign({ title: "Edit", placement: "bottom" }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: "info", lineHeight: 1, sx: { cursor: "pointer", mx: 3 } }, { children: _jsx(Icon, Object.assign({ color: "inherit" }, { children: "edit" })) })) }))] }));
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(MDBox, Object.assign({ py: 3 }, { children: [_jsx(MDBox, Object.assign({ mt: 6 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 4 }, { children: _jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsx(ReportsBarChart, { color: "info", title: "Room views", description: "Last Campaign Performance", date: "campaign sent 2 days ago", chart: reportsBarChartData }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 8 }, { children: _jsx(MDBox, Object.assign({ mb: 3 }, { children: _jsx(ReportsLineChart, { color: "success", title: "daily sales", description: _jsxs(_Fragment, { children: ["(", _jsx("strong", { children: "+15%" }), ") increase in today sales."] }), date: "updated 4 min ago", chart: sales }) })) }))] })) })), _jsx(MDBox, Object.assign({ mt: 1.5 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(MDBox, Object.assign({ mb: 1.5 }, { children: _jsx(ComplexStatisticsCard, { color: "dark", icon: "weekend", title: "Bookings", count: 281, percentage: {
                                                color: "success",
                                                amount: "+55%",
                                                label: "than lask week",
                                            } }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(MDBox, Object.assign({ mb: 1.5 }, { children: _jsx(ComplexStatisticsCard, { icon: "leaderboard", title: "Today's Users", count: "156", percentage: {
                                                color: "success",
                                                amount: "+3%",
                                                label: "than last month",
                                            } }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(MDBox, Object.assign({ mb: 1.5 }, { children: _jsx(ComplexStatisticsCard, { color: "success", icon: "store", title: "Revenue", count: "34k", percentage: {
                                                color: "success",
                                                amount: "+1%",
                                                label: "than yesterday",
                                            } }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, lg: 3 }, { children: _jsx(MDBox, Object.assign({ mb: 1.5 }, { children: _jsx(ComplexStatisticsCard, { color: "primary", icon: "person", title: "Attendees", count: "691", percentage: {
                                                color: "success",
                                                amount: "",
                                                label: "Just updated",
                                            } }) })) }))] })) })), _jsx(MDBox, Object.assign({ mt: 2 }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 3 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsxs(Card, { children: [_jsx(MDBox, Object.assign({ pt: 3, px: 3 }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Top Selling Products" })) })), _jsx(MDBox, Object.assign({ py: 1 }, { children: _jsx(DataTable, { table: dataTableData, entriesPerPage: false, showTotalEntries: false, isSorted: false, noEndBorder: true }) }))] }) })) })) }))] }))] }));
}
export default Analytics;
