import { io } from "socket.io-client";
import { KankanAvatar } from "./KankanAvatar";
import { SOCKET_USER_ID } from "threejs/state/const";
import { SocketPlayerInfo } from "threejs/model/SocketPlayerInfo";
import { Quaternion, Vector3 } from "three";
import { SOCKET_HOST } from "services/ApiEndpoint";
export class KankanSocketService {
    constructor(_baseScene, _mainPlayer, _eventId) {
        this._baseScene = _baseScene;
        this._mainPlayer = _mainPlayer;
        this._eventId = _eventId;
        this._socketPlayers = {};
        this._connection = io(`${SOCKET_HOST}/${this._eventId}`);
        this._connection.on("characters", this.updateCharacters.bind(this));
        this._connection.on("createdUser", this.createdUser.bind(this));
        this._connection.on("removePlayer", this._removePlayer.bind(this));
        setInterval(this._updateSocketPlayerinfo.bind(this), 35);
    }
    _removePlayer(data) {
        const player = this._socketPlayers[data.socketId];
        if (player) {
            player._deletePlayer();
            delete this._socketPlayers[data.socketId];
            console.log("player removed", data.socketId);
        }
    }
    createdUser(data) {
        localStorage.setItem(SOCKET_USER_ID, data);
    }
    updateCharacters(SocketPlayerInfos) {
        Object.keys(SocketPlayerInfos).map(async (k) => {
            const p = SocketPlayerInfos[k];
            if (!p.active)
                return;
            if (!this._socketPlayers[p.socketId]) {
                this._socketPlayers[p.socketId] = new KankanAvatar(this._baseScene, p.name, true);
                this._socketPlayers[p.socketId].addPlayerModel();
            }
            //this._socketPlayers[p.socketId]._visibilityUpdate(true);
            this._socketPlayers[p.socketId].updatePlayerPosition(new Vector3(p.position.x, p.position.y, p.position.z));
        });
    }
    _updateSocketPlayerinfo() {
        var _a, _b;
        if (!this._mainPlayer._currentPlayer) {
            this._mainPlayer.addPlayerModel();
        }
        const p = new SocketPlayerInfo();
        p.active = true;
        p.avatar = "";
        p.animation = "Idle";
        p.name = this._mainPlayer._name;
        p.socketId = (_a = localStorage.getItem(SOCKET_USER_ID)) !== null && _a !== void 0 ? _a : "";
        p.position = (_b = this._mainPlayer._currentPlayer) === null || _b === void 0 ? void 0 : _b.position;
        p.rotation = new Quaternion();
        this._connection.emit("updateCharacter", p);
    }
}
