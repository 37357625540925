import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { EDIT_SCENE_ITEM } from "threejs/state/const";
import videoIcon from "assets/images/icons/files/video-48.png";
import imageIcon from "assets/images/icons/files/image-48.png";
import objIcon from "assets/images/icons/files/obj-48.png";
import Tag from "assets/images/logo-dark.png";
export default function Item({ item, domElement, }) {
    const element = item.meshToData();
    return (_jsxs(ListItemButton, Object.assign({ component: "li", onClick: () => {
            const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
                detail: item,
            });
            domElement.dispatchEvent(customEvent);
        } }, { children: [_jsx(ListItemIcon, { children: _jsxs(IconButton, { children: [element.type === "VideoMesh" && (_jsx("img", { src: videoIcon, alt: "Video Icon", style: { width: 24, height: 24 } })), element.type === "ImageMesh" && (_jsx("img", { src: imageIcon, alt: "Video Icon", style: { width: 24, height: 24 } })), element.type === "ObjectMesh" && (_jsx("img", { src: objIcon, alt: "Video Icon", style: { width: 24, height: 24 } })), element.type === "TagMesh" && (_jsx("img", { src: Tag, alt: "Video Icon", style: { width: 24, height: 24 } }))] }) }), _jsx(ListItemText, { color: "text", primary: element.otherDatas.displayName, primaryTypographyProps: { sx: { cursor: "pointer", fontSize: "0.875rem" } } })] })));
}
