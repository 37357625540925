import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
function Sidenav() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const sidenavItems = [
        { icon: "person", label: "profile", href: "profile" },
        { icon: "receipt_long", label: "basic info", href: "basic-info" },
        { icon: "lock", label: "change password", href: "change-password" },
        { icon: "delete", label: "delete account", href: "delete-account" },
    ];
    const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
        const itemKey = `item-${key}`;
        return (_jsx(MDBox, Object.assign({ component: "li", pt: key === 0 ? 0 : 1 }, { children: _jsxs(MDTypography, Object.assign({ component: "a", href: `#${href}`, variant: "button", fontWeight: "regular", textTransform: "capitalize", sx: ({ borders: { borderRadius }, functions: { pxToRem }, palette: { light }, transitions, }) => ({
                    display: "flex",
                    alignItems: "center",
                    borderRadius: borderRadius.md,
                    padding: `${pxToRem(10)} ${pxToRem(16)}`,
                    transition: transitions.create("background-color", {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.shorter,
                    }),
                    "&:hover": {
                        backgroundColor: light.main,
                    },
                }) }, { children: [_jsx(MDBox, Object.assign({ mr: 1.5, lineHeight: 1, color: darkMode ? "white" : "dark" }, { children: _jsx(Icon, Object.assign({ fontSize: "small" }, { children: icon })) })), label] })) }), itemKey));
    });
    return (_jsx(Card, Object.assign({ sx: {
            borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
            position: "sticky",
            top: "1%",
        } }, { children: _jsx(MDBox, Object.assign({ component: "ul", display: "flex", flexDirection: "column", p: 2, m: 0, sx: { listStyle: "none" } }, { children: renderSidenavItems })) })));
}
export default Sidenav;
