import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Stack, } from "@mui/material";
import EventForm from "components/features/event/EventForm";
import { useSearchParams } from "react-router-dom";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
export default function NewEvent() {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("start");
    const endDate = searchParams.get("end");
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(Stack, Object.assign({ direction: "column", spacing: 2, style: { display: "flex", flexGrow: 1, overflow: "auto" } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", square: true, sx: { height: "100%", overflow: "auto" } }, { children: _jsx(Stack, Object.assign({ direction: "column", p: 4, spacing: 2, paddingBottom: 0 }, { children: _jsx(EventForm, { formFields: {
                                eventType: "",
                                title: "",
                                description: "",
                                agenda: "",
                                start: startDate ? +startDate / 1000 : 0,
                                end: endDate ? +endDate / 1000 : 0,
                                participants: [],
                                room: "",
                                isPublic: false,
                                maxAttendees: 10,
                                price: 0,
                            }, onClose: () => { } }) })) })) }))] }));
}
