import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormik } from "formik";
import { useEffect } from "react";
import RoomThumbnail from "components/features/room/field/RoomThumbnail";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import FormSubmitButton from "components/generic/form/FormSubmitButton";
import RoomTemplate from "./field/RoomTemplate";
export default function RoomForm(props) {
    const URL = props.roomId ? "/room/update" : "/room/create";
    const { execute, globalErrorMessage, result } = useFormPost(url(URL));
    const formData = useFormik({
        initialValues: props.value,
        onSubmit: async (value) => {
            const form = new FormData();
            form.append("title", value.title);
            form.append("description", value.description);
            form.append("template", value.template);
            if (value.thumbnail)
                form.append("thumbnail", value.thumbnail);
            if (props.roomId)
                form.append("_id", props.roomId);
            await execute(form);
        },
    });
    useEffect(() => {
        if (result) {
            window.location.href = "/environnements/rooms";
        }
    }, [result]);
    return (_jsxs(_Fragment, { children: [globalErrorMessage, _jsxs("form", Object.assign({ onSubmit: formData.handleSubmit, noValidate: true }, { children: [_jsx(RoomTemplate, { name: "template", label: "Template", required: true, formik: formData }), _jsx(FormInputText, { name: "title", label: "Title", required: true, formik: formData }), _jsx(FormInputTextArea, { name: "description", label: "Description", rows: 5, required: true, formik: formData }), _jsx(RoomThumbnail, { name: "thumbnail", label: "Thumbnail", formik: formData }), _jsx("div", Object.assign({ style: { display: "flex", justifyContent: "center", marginTop: "30px" } }, { children: _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: "Submit" }) }))] }))] }));
}
