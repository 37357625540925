import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
// Pricing page components
// import Header from "layouts/pages/pricing-page/components/Header";
// import Footer from "layouts/pages/pricing-page/components/Footer";
// import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
// import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
// import Faq from "layouts/pages/pricing-page/components/Faq";
import PricingCards from "./components/PricingCards";
import Header from "./components/Header";
import TrustedBrands from "./components/TrustedBrands";
import PageLayout from "components/features/LayoutContainers/PageLayout";
function PricingPage() {
    const [tabValue, setTabValue] = useState(0);
    const [prices, setPrices] = useState(["59", "89", "99"]);
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        if (event.currentTarget.id === "annual") {
            setPrices(["119", "159", "399"]);
        }
        else {
            setPrices(["59", "89", "99"]);
        }
    };
    return (_jsx(PageLayout, { children: _jsx(Header, Object.assign({ tabValue: tabValue, tabHandler: handleSetTabValue }, { children: _jsxs(Container, { children: [_jsx(PricingCards, { prices: prices }), _jsx(TrustedBrands, {})] }) })) }));
}
export default PricingPage;
