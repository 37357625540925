import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "components/generic/Navbars/DefaultNavbar";
import PageLayout from "components/features/LayoutContainers/PageLayout";
// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "routes/page.routes";
// Authentication pages components
import Footer from "components/features/LayoutContainers/Footer";
function BasicLayout({ image, children }) {
    return (_jsxs(PageLayout, { children: [_jsx(DefaultNavbar, { routes: pageRoutes, action: {
                    type: "external",
                    route: "https://creative-tim.com/product/material-dashboard-2-pro-react-ts",
                    label: "buy now",
                    color: "primary",
                }, transparent: true, light: true }), _jsx(MDBox, { position: "absolute", width: "100%", minHeight: "100vh", sx: {
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients }, }) => image &&
                        // `${linearGradient(
                        //   rgba(gradients.dark.main, 0.6),
                        //   rgba(gradients.dark.state, 0.6)
                        // )}, 
                        `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                } }), _jsx(MDBox, Object.assign({ px: 1, width: "100%", height: "100vh", mx: "auto" }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 1, justifyContent: "center", alignItems: "center", height: "100%" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 11, sm: 9, md: 5, lg: 4, xl: 3 }, { children: children })) })) })), _jsx(Footer, { light: true })] }));
}
export default BasicLayout;
