var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef, createContext, useContext, useMemo } from "react";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
// Custom styles for MDPagination
import MDPaginationItemRoot from "components/generic/MDPagination/MDPaginationItemRoot";
// The Pagination main context
const Context = createContext(null);
const MDPagination = forwardRef((_a, ref) => {
    var { item = false, variant = "gradient", color = "primary", size = "medium", active = false, children } = _a, rest = __rest(_a, ["item", "variant", "color", "size", "active", "children"]);
    const context = useContext(Context);
    const paginationSize = context ? context.size : undefined;
    const providerValue = useMemo(() => ({
        variant,
        color,
        size,
    }), [variant, color, size]);
    return (_jsx(Context.Provider, Object.assign({ value: providerValue }, { children: item ? (_jsx(MDPaginationItemRoot, Object.assign({}, rest, { ref: ref, variant: active ? context.variant : "outlined", color: active ? context.color : "secondary", iconOnly: true, circular: true, ownerState: { variant, active, paginationSize } }, { children: children }))) : (_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "flex-end", alignItems: "center", sx: { listStyle: "none" } }, { children: children }))) })));
});
export default MDPagination;
