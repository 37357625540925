import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Grid } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import ProfileImage from "components/features/settings/component/ProfileImage";
import Form from "components/features/settings/component/Form";
import GLBScroller from "components/generic/Three/GLBScroller/GLBScroller";
export default function Profile() {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    const { execute: allAvatarQuery, result: allAvatars } = useFormGet(url("/admin/avatar/all"));
    const [modelUrls, setmodelUrls] = useState([]);
    const [avatarData, setAvatarData] = useState([]);
    useEffect(() => {
        userInfoQuery();
        allAvatarQuery();
    }, []);
    useEffect(() => {
        if (!allAvatars)
            return;
        const v = [];
        allAvatars.forEach((element) => {
            v.push(element.path);
        });
        setmodelUrls(v);
        setAvatarData(allAvatars);
    }, [allAvatars]);
    return userInfo ? (_jsxs(Container, Object.assign({ style: { width: "100%" } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2, display: "flex", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, width: "50%", style: {
                            position: "relative",
                        } }, { children: _jsx(ProfileImage, { profileImage: userInfo.profileImageUrl }) })), _jsx(Grid, Object.assign({ item: true, xs: 6, width: "50%", style: {
                            position: "relative",
                        } }, { children: _jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: avatarData.length > 0 ? _jsx(GLBScroller, { avatarDatas: avatarData }) : null })) }))] })), _jsx(Grid, Object.assign({ container: true, spacing: 2, display: "flex", alignItems: "center" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, width: "100%", marginTop: 3 }, { children: _jsx(Form, { userInfo: userInfo }) })) }))] }))) : null;
}
