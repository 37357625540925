import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Paper, } from "@mui/material";
import { useEffect } from "react";
import EventForm from "components/features/event/EventForm";
import { useParams } from "react-router-dom";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
export default function UpdateEvent() {
    const { eventRef } = useParams();
    const { execute, result } = useFormGet(url("/event/get/" + eventRef));
    useEffect(() => {
        execute();
    }, []);
    useEffect(() => {
        if (!result)
            return;
    }, [result]);
    return result ? (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(Stack, Object.assign({ direction: "column", spacing: 2, style: { display: "flex", flexGrow: 1, overflow: "auto" } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", square: true, sx: { height: "100%", overflow: "auto" } }, { children: _jsx(Stack, Object.assign({ direction: "column", p: 4, spacing: 2, paddingBottom: 0 }, { children: _jsx(EventForm, { formFields: result, _id: result._id, onClose: () => { } }) })) })) }))] })) : null;
}
