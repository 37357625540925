import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import EventList from "components/features/event/list";
export default function UpComingEvents() {
    const { result, execute, abortController } = useFormGet(url("/event/upcoming"));
    useEffect(() => {
        execute();
        return () => {
            abortController.abort();
        };
    }, []);
    return result ? (_jsx(EventList, { events: result, title: "Upcoming Events" })) : (_jsx(Container, { style: { width: "100%" } }));
}
