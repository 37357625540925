import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "contexts";
function BillingInformation() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (_jsxs(_Fragment, { children: [_jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Billing Information" })), _jsx(MDBox, Object.assign({ component: "li", display: "flex", justifyContent: "space-between", alignItems: "flex-start", bgColor: darkMode ? "transparent" : "grey-100", borderRadius: "lg", p: 3, mt: 2 }, { children: _jsxs(MDBox, Object.assign({ width: "100%", display: "flex", flexDirection: "column", lineHeight: 1 }, { children: [_jsx(MDBox, Object.assign({ mb: 2 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "medium", textTransform: "capitalize" }, { children: "Oliver Liam" })) })), _jsx(MDBox, Object.assign({ mb: 1, lineHeight: 0 }, { children: _jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "regular", color: "text" }, { children: ["Company Name:\u00A0\u00A0\u00A0", _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium", textTransform: "capitalize" }, { children: "Viking Burrito" }))] })) })), _jsx(MDBox, Object.assign({ mb: 1, lineHeight: 0 }, { children: _jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "regular", color: "text" }, { children: ["Email Address:\u00A0\u00A0\u00A0", _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium" }, { children: "oliver@burrito.com" }))] })) })), _jsxs(MDTypography, Object.assign({ variant: "caption", fontWeight: "regular", color: "text" }, { children: ["VAT Number:\u00A0\u00A0\u00A0", _jsx(MDTypography, Object.assign({ variant: "caption", fontWeight: "medium" }, { children: "FRB1235476" }))] }))] })) }))] }));
}
export default BillingInformation;
