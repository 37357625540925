import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
function Header() {
    return (_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(MDBox, { children: [_jsx(MDBox, Object.assign({ mb: 1 }, { children: _jsx(MDTypography, Object.assign({ variant: "h6", fontWeight: "medium" }, { children: "Order Details" })) })), _jsxs(MDTypography, Object.assign({ component: "p", variant: "button", color: "text" }, { children: ["Order no. ", _jsx("b", { children: "241342" }), " from", _jsx("b", { children: "23.02.2021" })] })), _jsxs(MDTypography, Object.assign({ component: "p", variant: "button", fontWeight: "regular", color: "text" }, { children: ["Code: ", _jsx("b", { children: "KF332" })] }))] }), _jsx(MDButton, Object.assign({ variant: "gradient", color: "dark" }, { children: "invoice" }))] })));
}
export default Header;
