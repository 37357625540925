import { jsx as _jsx } from "react/jsx-runtime";
import RoomForm from "components/features/room/RoomForm";
import { HOSTNAME, url } from "services/ApiEndpoint";
export default function EditRoomForm(props) {
    return (_jsx(RoomForm, { value: {
            description: props.description,
            template: props.template,
            title: props.title,
            thumbnail: props.thumbnail
                ? props.thumbnail.includes(HOSTNAME)
                    ? props.thumbnail
                    : url(`/room/${props.thumbnail}`).value
                : "",
        }, roomId: props.roomId }));
}
