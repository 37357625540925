import { ObjectLoader, } from "three";
import { SceneItem } from "threejs/model/SceneItem";
import { ImageMesh } from "threejs/sceneItem/ImageMesh";
import { ObjectMesh } from "threejs/sceneItem/ObjectMesh";
import { VideoMesh } from "threejs/sceneItem/VideoMesh";
import { LOAD_JSON_ITEMS } from "threejs/state/const";
import { TagMesh } from "threejs/sceneItem/TagMesh";
import { LiveStreamMesh } from "threejs/sceneItem/LiveStreamMesh";
export class YounivrObjectLoader extends ObjectLoader {
    constructor(_groupToAdd, _baseScene) {
        super();
        this._groupToAdd = _groupToAdd;
        this._baseScene = _baseScene;
    }
    parseObject(data, geometries, materials, animations) {
        const itemToAdd = new SceneItem();
        const { name, uuid, position, scale, rotation, otherDatas } = data;
        if (position)
            itemToAdd.position = position;
        if (scale)
            itemToAdd.scale = scale;
        if (rotation)
            itemToAdd.rotation = rotation;
        if (uuid)
            itemToAdd.uuid = uuid;
        if (name)
            itemToAdd.name = name;
        itemToAdd.otherDatas = otherDatas;
        if (data.name === "ImagePlane") {
            const mesh = new ImageMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "VideoPlane") {
            const mesh = new VideoMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "CustomObject") {
            const mesh = new ObjectMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "LiveStream") {
            const mesh = new LiveStreamMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "YounivrTag") {
            const mesh = new TagMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const prevCallBack = this._baseScene._animationCallBack;
            this._baseScene._animationCallBack = () => {
                prevCallBack();
                mesh.circle.lookAt(this._baseScene.camera.position);
            };
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        return super.parseObject(data, geometries, materials, animations);
    }
}
