import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Stack } from "@mui/material";
import React from "react";
import Token from "components/features/translation/component/Token";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Profile from "components/features/settings/component/Profile";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import Configurator from "components/features/settings/component/Configurator";
export default function Settings() {
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsx(Stack, Object.assign({ direction: "column", style: { display: "flex", flexGrow: 1, overflow: "auto" } }, { children: _jsx(Paper, Object.assign({ variant: "outlined", square: true, sx: { height: "100%", display: "flex", flexDirection: "column", overflow: "auto" } }, { children: _jsx(Stack, Object.assign({ direction: "column", p: 4, spacing: 2, paddingBottom: 0 }, { children: _jsxs(TabContext, Object.assign({ value: value }, { children: [_jsxs(TabList, Object.assign({ onChange: handleChange, "aria-label": "lab API tabs example" }, { children: [_jsx(Tab, { label: _jsx(Token, { children: "Profile" }), value: "1" }), _jsx(Tab, { label: "Wallet", value: "2" }), _jsx(Tab, { label: "Notification", value: "3" }), _jsx(Tab, { label: "Theme", value: "4" })] })), _jsx(TabPanel, Object.assign({ value: "1" }, { children: _jsx(Profile, {}) })), _jsx(TabPanel, Object.assign({ value: "2" }, { children: "WALLET" })), _jsx(TabPanel, Object.assign({ value: "3" }, { children: "NOTIFICATION" })), _jsx(TabPanel, Object.assign({ value: "4" }, { children: _jsx(Configurator, {}) }))] })) })) })) }))] }));
}
