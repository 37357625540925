import { jsx as _jsx } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/**
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
// @mui material components
import Icon from "@mui/material/Icon";
const pageRoutes = [
    {
        name: "Product",
        columns: 2,
        rowsPerColumn: 2,
        collapse: [
            {
                name: "VR Templates",
                icon: _jsx(Icon, { children: "dashboard" }),
                collapse: [
                    {
                        name: "virtual",
                        route: "/product/Vtemplate",
                    },
                    {
                        name: "Digital twin",
                        route: "/product/Dtemplate",
                    },
                ],
            },
            {
                name: "Create your space",
                icon: _jsx(Icon, { children: "people" }),
                collapse: [
                    {
                        name: "overview",
                        route: "/product/buildSpace",
                    },
                ],
            },
            {
                name: "Visioconference",
                icon: _jsx(Icon, { children: "queue_play_next" }),
                collapse: [
                    {
                        name: "overview",
                        route: "/product/visioconference",
                    }
                ],
            },
            {
                name: "Ecommerce",
                icon: _jsx(Icon, { children: "shopping_cart" }),
                collapse: [
                    {
                        name: "overview",
                        route: "/product/ecommerce",
                    },
                ],
            }
        ],
    },
    {
        name: "authenticaton",
        collapse: [
            {
                name: "sign in",
                route: "/authentication/sign-in",
                icon: _jsx(Icon, { children: "login" }),
            },
            {
                name: "sign up",
                route: "/authentication/sign-up",
                icon: _jsx(Icon, { children: "assignment" }),
            },
            {
                name: "reset password",
                route: "/authentication/reset-password",
                icon: _jsx(Icon, { children: "restart_alt" }),
            },
        ],
    },
    {
        name: "About us",
        collapse: [
            {
                name: "What we do",
                route: "/aboutus/whatwedo",
                icon: "widgets",
            },
            {
                name: "The Team",
                route: "/aboutus/team",
                icon: "import_contacts",
            }
        ],
    },
    {
        name: "docs",
        collapse: [
            {
                name: "getting started",
                href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
                description: "All about overview, quick start, license and contents",
                icon: _jsx(Icon, { children: "article" }),
            }
        ],
    },
    {
        name: "Pricing",
        route: "/pricings",
        description: "All about overview, quick start, license and contents",
        icon: _jsx(Icon, { children: "article" }),
    }
];
export default pageRoutes;
