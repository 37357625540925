import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useEffect, useState } from "react";
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDInput from "components/generic/MDInput";
import MDPagination from "components/generic/MDPagination";
// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "components/generic/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/generic/Tables/DataTable/DataTableBodyCell";
function DataTable({ entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] }, canSearch = false, showTotalEntries = true, table, pagination = { variant: "gradient", color: "info" }, isSorted = true, noEndBorder = false, }) {
    let defaultValue;
    let entries;
    if (entriesPerPage) {
        defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "10";
        entries = entriesPerPage.entries ? entriesPerPage.entries : ["10", "25", "50", "100"];
    }
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);
    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy, usePagination);
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, page, pageOptions, canPreviousPage, canNextPage, gotoPage, nextPage, previousPage, setPageSize, setGlobalFilter, state: { pageIndex, pageSize, globalFilter }, } = tableInstance;
    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
    // Set the entries per page value based on the select value
    const setEntriesPerPage = (value) => setPageSize(value);
    // Render the paginations
    const renderPagination = pageOptions.map((option) => (_jsx(MDPagination, Object.assign({ item: true, onClick: () => gotoPage(Number(option)), active: pageIndex === option }, { children: option + 1 }), option)));
    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) => value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));
    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);
    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));
    // Search input value state
    const [search, setSearch] = useState(globalFilter);
    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);
    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;
        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        }
        else if (isSorted) {
            sortedValue = "none";
        }
        else {
            sortedValue = false;
        }
        return sortedValue;
    };
    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
    // Setting the entries ending point
    let entriesEnd;
    if (pageIndex === 0) {
        entriesEnd = pageSize;
    }
    else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    }
    else {
        entriesEnd = pageSize * (pageIndex + 1);
    }
    return (_jsxs(TableContainer, Object.assign({ sx: { boxShadow: "none" } }, { children: [entriesPerPage || canSearch ? (_jsxs(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", p: 3 }, { children: [entriesPerPage && (_jsxs(MDBox, Object.assign({ display: "flex", alignItems: "center" }, { children: [_jsx(Autocomplete, { disableClearable: true, value: pageSize.toString(), options: entries, onChange: (event, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }, size: "small", sx: { width: "5rem" }, renderInput: (params) => _jsx(MDInput, Object.assign({}, params)) }), _jsx(MDTypography, Object.assign({ variant: "caption", color: "secondary" }, { children: "\u00A0\u00A0entries per page" }))] }))), canSearch && (_jsx(MDBox, Object.assign({ width: "12rem", ml: "auto" }, { children: _jsx(MDInput, { placeholder: "Search...", value: search, size: "small", fullWidth: true, onChange: ({ currentTarget }) => {
                                setSearch(search);
                                onSearchChange(currentTarget.value);
                            } }) })))] }))) : null, _jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(MDBox, Object.assign({ component: "thead" }, { children: headerGroups.map((headerGroup, key) => (_jsx(TableRow, Object.assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map((column, key) => (_jsx(DataTableHeadCell, Object.assign({}, column.getHeaderProps(isSorted && column.getSortByToggleProps()), { width: column.width ? column.width : "auto", align: column.align ? column.align : "left", sorted: setSortedValue(column) }, { children: column.render("Header") }), key))) }), key))) })), _jsx(TableBody, Object.assign({}, getTableBodyProps(), { children: page.map((row, key) => {
                            prepareRow(row);
                            return (_jsx(TableRow, Object.assign({}, row.getRowProps(), { children: row.cells.map((cell, key) => (_jsx(DataTableBodyCell, Object.assign({ noBorder: noEndBorder && rows.length - 1 === key, align: cell.column.align ? cell.column.align : "left" }, cell.getCellProps(), { children: cell.render("Cell") }), key))) }), key));
                        }) }))] })), _jsxs(MDBox, Object.assign({ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", alignItems: { xs: "flex-start", sm: "center" }, p: !showTotalEntries && pageOptions.length === 1 ? 0 : 3 }, { children: [showTotalEntries && (_jsx(MDBox, Object.assign({ mb: { xs: 3, sm: 0 } }, { children: _jsxs(MDTypography, Object.assign({ variant: "button", color: "secondary", fontWeight: "regular" }, { children: ["Showing ", entriesStart, " to ", entriesEnd, " of ", rows.length, " entries"] })) }))), pageOptions.length > 1 && (_jsxs(MDPagination, Object.assign({ variant: pagination.variant ? pagination.variant : "gradient", color: pagination.color ? pagination.color : "info" }, { children: [canPreviousPage && (_jsx(MDPagination, Object.assign({ item: true, onClick: () => previousPage() }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "chevron_left" })) }))), renderPagination.length > 6 ? (_jsx(MDBox, Object.assign({ width: "5rem", mx: 1 }, { children: _jsx(MDInput, { inputProps: { type: "number", min: 1, max: customizedPageOptions.length }, value: customizedPageOptions[pageIndex], onChange: (event) => {
                                        handleInputPagination(event);
                                        handleInputPaginationValue(event);
                                    } }) }))) : (renderPagination), canNextPage && (_jsx(MDPagination, Object.assign({ item: true, onClick: () => nextPage() }, { children: _jsx(Icon, Object.assign({ sx: { fontWeight: "bold" } }, { children: "chevron_right" })) })))] })))] }))] })));
}
export default DataTable;
