import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, Grid, Input, IconButton, } from "@mui/material";
import { PresentationControls, Stage, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import EditIcon from "@mui/icons-material/Edit";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
export default function Avatar3D({ avatar }) {
    const [model, setModel] = useState(useGLTF(url(`/admin/avatar/model/${avatar}`).value));
    const [updatedModel, setUpdatedModel] = useState(useGLTF(url(`/admin/avatar/model/${avatar}`).value));
    const [updatedModelValue, setUpdatedModelValue] = useState(null);
    const { execute, result, loading, status } = useFormPost(url(`/user/media/update-avatar-model`));
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const changeModel = (event) => {
        const files = event.target.files;
        if (!files)
            return;
        const file = URL.createObjectURL(files[0]);
        new GLTFLoader().load(file, (gltf) => {
            setUpdatedModel(gltf);
        });
        setUpdatedModelValue(files[0]);
    };
    const uploadProfile = () => {
        const formData = new FormData();
        formData.append("update-avatar-model", updatedModelValue);
        execute(formData);
    };
    useEffect(() => {
        console.log(status);
        if (status && status === 200) {
            setModel(updatedModel);
            handleClose();
        }
    }, [status]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({ onClick: handleClickOpen, color: "secondary", "aria-label": "Edit", style: {
                    position: "absolute",
                    right: "10px",
                    zIndex: 999,
                } }, { children: _jsx(EditIcon, {}) })), _jsxs(Canvas, Object.assign({ dpr: [1, 2], shadows: true, camera: { fov: 45, position: [0, 0, 2.5] }, style: { height: "100%" } }, { children: [_jsx("ambientLight", {}), _jsx("pointLight", { position: [10, 10, 10] }), _jsx(PresentationControls, Object.assign({ speed: 1.5, global: true, zoom: 0.5, polar: [-0.1, Math.PI / 4] }, { children: _jsx(Stage, Object.assign({ environment: null, adjustCamera: false }, { children: _jsx("primitive", { object: model.scene, position: [0, -1, 0] }) })) }))] })), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true }, { children: [model ? (_jsx(Grid, Object.assign({ item: true, height: 150, marginBottom: 2 }, { children: _jsxs(Canvas, Object.assign({ dpr: [1, 2], shadows: true, camera: { fov: 45 }, style: { height: "500px" } }, { children: [_jsx("color", { attach: "background", args: ["#010101"] }), _jsx(PresentationControls, Object.assign({ speed: 1.5, global: true, zoom: 0, polar: [-0.1, Math.PI / 4] }, { children: _jsx(Stage, Object.assign({ environment: null }, { children: _jsx("primitive", { object: updatedModel.scene }) })) }))] })) }))) : null, _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Input, { type: "file", onChange: changeModel }) }))] })) }), _jsx(DialogActions, { children: loading ? (_jsx(LoadingButton, Object.assign({ loading: true, variant: "outlined" }, { children: "Update" }))) : (_jsxs(Button, Object.assign({ type: "submit", variant: "outlined", onClick: uploadProfile }, { children: [" ", "Update", " "] }))) })] }))] }));
}
