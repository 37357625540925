import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
// react-router components
import { Link } from "react-router-dom";
// @mui material components
import Collapse from "@mui/material/Collapse";
import MuiLink from "@mui/material/Link";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// Material Dashboard 2 PRO React TS exampless
import DefaultNavbarDropdown from "components/generic/Navbars/DefaultNavbar/DefaultNavbarDropdown";
function DefaultNavbarMobile({ routes, open }) {
    const [collapse, setCollapse] = useState("");
    const handleSetCollapse = (name) => collapse === name ? setCollapse(false) : setCollapse(name);
    const renderNavbarItems = routes.map(({ name, icon, collapse: routeCollapses, href, route, collapse: navCollapse }) => (_jsx(DefaultNavbarDropdown, Object.assign({ name: name, icon: icon, collapseStatus: name === collapse, onClick: () => handleSetCollapse(name), href: href, route: route, collapse: Boolean(navCollapse) }, { children: _jsx(MDBox, Object.assign({ sx: { height: "15rem", maxHeight: "15rem", overflowY: "scroll" } }, { children: routeCollapses &&
                routeCollapses.map((item) => (_jsx(MDBox, Object.assign({ px: 2 }, { children: item.collapse ? (_jsxs(_Fragment, { children: [_jsx(MDTypography, Object.assign({ display: "block", variant: "button", fontWeight: "bold", textTransform: "capitalize", py: 1, px: 0.5 }, { children: item.name })), item.collapse.map((el) => (_jsx(MDTypography, Object.assign({ component: el.route ? Link : MuiLink, to: el.route ? el.route : "", href: el.href ? el.href : "", target: el.href ? "_blank" : "", rel: el.href ? "noreferrer" : "noreferrer", minWidth: "11.25rem", display: "block", variant: "button", color: "text", textTransform: "capitalize", fontWeight: "regular", py: 0.625, px: 2, sx: ({ palette: { grey, dark }, borders: { borderRadius }, }) => ({
                                    // ADD THEME TYPE
                                    borderRadius: borderRadius.md,
                                    cursor: "pointer",
                                    transition: "all 300ms linear",
                                    "&:hover": {
                                        backgroundColor: grey[200],
                                        color: dark.main,
                                    },
                                }) }, { children: el.name }), el.name)))] })) : (_jsxs(MDBox, Object.assign({ display: "block", component: item.route ? Link : MuiLink, to: item.route ? item.route : "", href: item.href ? item.href : "", target: item.href ? "_blank" : "", rel: item.href ? "noreferrer" : "noreferrer", sx: ({ palette: { grey, dark }, borders: { borderRadius }, }) => ({
                            borderRadius: borderRadius.md,
                            cursor: "pointer",
                            transition: "all 300ms linear",
                            py: 1,
                            px: 1.625,
                            "&:hover": {
                                backgroundColor: grey[200],
                                color: dark.main,
                                "& *": {
                                    color: dark.main,
                                },
                            },
                        }) }, { children: [_jsx(MDTypography, Object.assign({ display: "block", variant: "button", fontWeight: "bold", textTransform: "capitalize" }, { children: item.name })), _jsx(MDTypography, Object.assign({ display: "block", variant: "button", color: "text", fontWeight: "regular", sx: { transition: "all 300ms linear" } }, { children: item.description }))] }), item.key)) }), item.name))) })) }), name)));
    return (_jsx(Collapse, Object.assign({ in: Boolean(open), timeout: "auto", unmountOnExit: true }, { children: _jsx(MDBox, Object.assign({ width: "calc(100% + 1.625rem)", my: 2, ml: -2 }, { children: renderNavbarItems })) })));
}
export default DefaultNavbarMobile;
