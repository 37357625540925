import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useGridOperations } from 'components/features/translation/service/useGridOperations';
import { useFormPost } from 'hooks/useFormPost';
import { url, HOSTNAME, PROTOCOL } from 'services/ApiEndpoint';
export default function TranslationGrid(props) {
    const [rowModesModel, setRowModesModel] = useState({});
    const [columns, setColumns] = useState([]);
    const apiRef = useGridApiRef();
    const operations = useGridOperations({
        rowModesModel,
        setRowModesModel,
        ref: apiRef
    });
    useEffect(() => {
        setColumns([
            ...props.columns,
            {
                field: 'action',
                headerName: 'Action',
                type: 'actions',
                getActions: (params) => {
                    var _a;
                    const isInEditMode = ((_a = rowModesModel[params.id]) === null || _a === void 0 ? void 0 : _a.mode) === GridRowModes.Edit;
                    if (isInEditMode) {
                        return [
                            _jsx(GridActionsCellItem, { icon: _jsx(SaveIcon, {}), label: "Save", sx: { color: 'primary.main' }, onClick: operations.handleSaveClick(params.id) }),
                            _jsx(GridActionsCellItem, { icon: _jsx(CancelIcon, {}), label: "Cancel", className: "textPrimary", color: "inherit", onClick: operations.handleCancelClick(params.id) }),
                        ];
                    }
                    return [
                        _jsx(GridActionsCellItem, { icon: _jsx(EditIcon, {}), label: "Edit", className: "textPrimary", color: "inherit", onClick: operations.handleEditClick(params.id) })
                    ];
                }
            }
        ]);
    }, [props.columns, rowModesModel]);
    const UploadFile = () => {
        const { execute } = useFormPost(url('/site/translation/upload-translation'));
        const [uploadedFile, setUploadedFile] = useState();
        useEffect(() => {
            if (uploadedFile) {
                if (uploadedFile.type != "text/csv") {
                    window.alert('Not valid Document upload');
                }
                else {
                    const data = new FormData();
                    data.append("upload", uploadedFile);
                    console.log(data.get("upload"));
                    execute(data);
                    // TODO @Ronald Le PK ca fonctionne pas 
                    //   API_POST_CALL('/site/translation/upload-translation', data).then(response => {
                    //     console.log(response)
                    //   })
                }
            }
        }, [uploadedFile]);
        const URL = `${PROTOCOL}${HOSTNAME}/site/translation/export-translation`;
        return (_jsxs(GridToolbarContainer, { children: [_jsxs(Button, Object.assign({ color: "primary", component: "label", variant: "contained" }, { children: ["Upload File", _jsx("input", { type: "file", hidden: true, onChange: (e) => e.target.files ? e.target.files[0] ? setUploadedFile(e.target.files[0]) : '' : '' })] })), _jsx("a", Object.assign({ href: URL }, { children: _jsx(Button, Object.assign({ variant: "contained", component: "label" }, { children: "Download File" })) }))] }));
    };
    return (_jsx(DataGrid, { sx: { borderStyle: "none" }, columns: columns, rows: props.rows, rowModesModel: rowModesModel, apiRef: apiRef, editMode: 'row', processRowUpdate: operations.handleRowUpdate, slots: { toolbar: UploadFile }, onProcessRowUpdateError: (e) => { console.log(e); } }));
}
