import { jsx as _jsx } from "react/jsx-runtime";
import MDSnackbar from "components/generic/MDSnackbar";
import { useState } from "react";
export const useShowConfirmation = () => {
    const [show, updateShow] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [color, setColor] = useState("success");
    const toggleSnackbar = () => setShow(!show);
    const setShow = (value) => {
        updateShow(value);
        window.setTimeout(() => {
            updateShow(!value);
        }, 1500);
    };
    const Message = () => {
        return (_jsx(MDSnackbar, { color: color, icon: "notifications", title: title, content: description, open: show, close: toggleSnackbar, dateTime: dateTime, autoHideDuration: 3 }));
    };
    return { setShow, setDescription, setTitle, Message, setColor };
};
