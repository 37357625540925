export class SocketPlayerInfo {
    constructor() {
        this.position = { x: 0, y: 0, z: 0 };
        this.rotation = { x: 0, y: 0, z: 0, w: 0 };
        this.socketId = null;
        this.animation = null;
        this.avatar = null;
        this.name = null;
        this.active = true;
        this.updatedTime = Date.now();
    }
}
