import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useFormik } from "formik";
import { Paper } from "@mui/material";
import { url } from "services/ApiEndpoint";
import { useFormPost } from "hooks/useFormPost";
import MDBox from "components/generic/MDBox";
import FormInputText from "components/generic/form/FormInputText";
import FormSubmitButton from "components/generic/form/FormSubmitButton";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
import { useShowConfirmation } from "hooks/useShowConfirmation";
export default function Form(info) {
    const { execute, result, errors, globalErrorMessage, status } = useFormPost(url("/user/update"));
    const { Message, setShow, setDescription, setTitle } = useShowConfirmation();
    const formData = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            phone: "",
            adresse: {
                city: "",
                postalcode: "",
                adresse: "",
                country: "",
                digicode: "",
            },
        },
        onSubmit: async (submitedValue) => {
            execute(JSON.stringify(Object.assign(Object.assign({}, submitedValue), { _id: info.userInfo._id })));
        },
    });
    useEffect(() => {
        if (!status)
            return;
        if (status !== 200) {
            ApiFormErrorResolver(formData, errors);
        }
        else {
            console.log(result);
            setTitle("User Info Update");
            setDescription("Votre Modification est bien pris en compte");
            setShow(true);
        }
    }, [status]);
    useEffect(() => {
        if (!info.userInfo)
            return;
        formData.setValues({
            firstname: info.userInfo.firstname,
            lastname: info.userInfo.lastname,
            phone: info.userInfo.phone,
            adresse: {
                city: info.userInfo.adresse.city,
                postalcode: info.userInfo.adresse.postalcode,
                adresse: info.userInfo.adresse.adresse,
                country: info.userInfo.adresse.country,
                digicode: info.userInfo.adresse.digicode,
            },
        });
    }, [info.userInfo]);
    return info.userInfo ? (_jsxs(Paper, Object.assign({ variant: "outlined", style: {
            padding: "10px",
        } }, { children: [_jsx(MDBox, Object.assign({ pt: 4, pb: 3, px: 3 }, { children: _jsxs(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: [_jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "firstname", label: "Firstname", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "lastname", label: "Lastname", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "phone", label: "Phone", formik: formData }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "adresse.city", label: "City", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "adresse.postalcode", label: "Code Postal", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "adresse.adresse", label: "Adresse", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "adresse.country", label: "Pays", formik: formData, required: true }) })), _jsx(MDBox, Object.assign({ mb: 4 }, { children: _jsx(FormInputText, { name: "adresse.digicode", label: "DigiCode", formik: formData }) })), _jsx(MDBox, Object.assign({ mt: 6, mb: 1 }, { children: _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: "reset" }) }))] })) })), _jsx(Message, {})] }))) : null;
}
